

import React, { } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {AppConstant,API_END_POINTS } from '../../constants/constants';
import { initiatePayment } from '../../../jsx/actions/auth';
import { connect } from "react-redux";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

class PaymentPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            is_refresh: false,
            message_text: '',
            html: `<html><head><title>Redirecting to Bank</title>
            <style>
                .bodytxt4 {font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 12px;font-weight: bold;color: #666666;}
                .bodytxt {font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 13px;font-weight: normal;color: #000000;}
                .bullet1 {list-style-type:square;list-style-position: inside;list-style-image: none;font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 10px;font-weight: bold;color: #FF9900;}
                .bodytxt2 {font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 8pt;font-weight: normal;color: #333333;}A.sac2 {COLOR: #000000;font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 10px;font-weight: bold;text-decoration: none;}A.sac2:visited {COLOR: #314D5A; TEXT-DECORATION: none}A.sac2:hover {COLOR: #FF9900; TEXT-DECORATION: underline}
            </style>
        </head>
        <table width="100%" border="0" cellspacing="0" cellpadding="0">  
            <tr>
                <td align="left" valign="top">
                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                        <tr>
                           <td align="center" valign="middle">
                               <table width="100%" border="0" cellspacing="0" cellpadding="0">   
                                    <tr>
                                        <td  align="center"></td>  
                                    </tr>  
                                    <tr>
                                        <td height="85" align="center"><br>
                                            <table width="80%" border="0" cellpadding="0" cellspacing="1" bgcolor="#CCCCCC">
                                                <tr>  
                                                    <td bgcolor="#CCCCCC"><table width="100%" border="0" cellpadding="6" cellspacing="0" bgcolor="#FFFFFF">  
                                                        <tr> 
                                                            <td colspan="2" align="left" valign="bottom"><span class="bodytxt4">Your payment request is being processed...</span></td>  
                                                        </tr>  
                                                        <tr valign="top"> 
                                                            <td colspan="2" align="left">
                                                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                    <tr>   
                                                                        <td width="87%" bgcolor="#cccccc" height="1" align="center"></td>
                                                                    </tr>
                                                                </table>
                                                            </td>  
                                                        </tr>  
                                                        <tr>
                                                            <td width="60%" align="left" valign="bottom"><table width="95%" border="0" cellpadding="1" cellspacing="0" bgcolor="#FFFFFF">
                                                                <tr>   
                                                                    <td align="right" valign="top"></td>  
                                                                    <td class="bodytxt">&nbsp;</td>
                                                                </tr>
                                                            <tr>   
                                                                <td height="19"  align="right" valign="top">
                                                                    <li class="bullet1"></li>
                                                                </td>  
                                                                <td class="bodytxt2">This is a secure payment gateway using 128 bit SSL encryption.</td>
                                                            </tr>
                                                            <tr>   
                                                                <td align="right" valign="top"> <li class="bullet1"></li></td>  
                                                                <td class="bodytxt2" >When you submit the transaction, the server will take about 1 to 5 seconds to process, but it may take longer at certain times. </td>
                                                            </tr>
                                                            <tr>   
                                                                <td align="right" valign="top"><li class="bullet1"></li></td>  
                                                                <td class="bodytxt2" >Please do not press "Submit" button once again or the "Back" or "Refresh" buttons. </td>
                                                            </tr>  
                                            </table>
                                        </td>
                                        <td align="right" valign="bottom">
                                            <table width="80%" border="0" cellpadding="1" cellspacing="0" bgcolor="#FFFFFF">
                                                <tr bgcolor="#FFFCF8">   
                                                    <td align="right" bgcolor="#FFFFFF"></td>
                                                </tr>
                                                <tr bgcolor="#FFFCF8">   
                                                    <td align="right" valign="middle" bgcolor="#FFFFFF" class="bodytxt2">&nbsp;</td>
                                                </tr>
                                                <tr bgcolor="#FFFCF8">
                                                    <td align="right" bgcolor="#FFFFFF" class="bodytxt2" >&nbsp;</td>
                                                </tr>  
                                            </table>
                                        </td>  
                                    </tr>
                                </table>
                            </td>
                        </tr>  
                    </table>  
                </td>  
            </tr>
        </table>   
        
        </td></tr></table></td>  </tr>  </table>`,
            is_redirect: false,
            route_parameters: null,
            arr_input: [ ],
            input_html: ``,
            actionUrl: '',
            ClientCode: '',
            MerchantCode: '',
            TxnAmount: '',
            TxnScAmount: '',
            MerchantRefNo: '',
            SuccessStaticFlag: '',
            FailureStaticFlag: '',
            Date: '',
            ClientAccNum: '',
            CheckSum: '',
            TxnCurrency: ''
        };
        this.form = null
    }

    async componentDidMount() {

        if(this.props.location.state) {

            let selected_order = this.props.location.state.selected_order;
            console.log(selected_order);

            let body_parameters = {   
                "order_no": selected_order['mf_order_number'] || '',
                "order_registration_no": selected_order['mf_order_registration_no'],
                "order_type": selected_order['mf_order_type'],
                "order_start_date": selected_order['order_start_date'],
                "order_amount": selected_order['mf_order_pay_amount'] || 
                      selected_order['mf_order_amount'], //['order_information']['mf_order_request']['OrderVal'],
                "payment_mode": selected_order['selected_pay_bank_mode'] || 'DIRECT',
                "upi_id": selected_order['upi_id'] || ''            
            };

            console.log(body_parameters);

            window.history.replaceState(null, '');

            this.props.initiatePayment(body_parameters).then(async (response) => {

                if(this.props.payment_initiate_result['code'] !== '000') {
                    //window.history.replaceState(null, '');
                    this.setState({ 
                        is_refresh : true, message_text: this.props.payment_initiate_result['messageText'] 
                    });
                }
                else {

                    this.setState({ 
                        is_refresh: body_parameters['payment_mode'] === 'UPI',
                        actionUrl: this.props.payment_initiate_result['actionUrl'],
                        is_redirect: this.props.payment_initiate_result['is_redirect'],
                        input_html: this.props.payment_initiate_result['input_html'],
                        message_text: body_parameters['payment_mode'] === 'UPI' ? 
                            this.props.payment_initiate_result['input_html'] : ''
                    });

                    // window.history.replaceState(null, '');
                    
                    await this.sleep(2000);
                    if(this.props.payment_initiate_result['is_redirect']) {
                        console.log(this.form);

                        localStorage.setItem('recent_payment_order_number', 
                            this.props.payment_initiate_result['order_number']);
                        // this.props.history.replace('', null);
                        this.form.submit();
                    }
                    else
                        localStorage.removeItem('recent_payment_order_number');
                }

            })
            .catch(err => {
            alert(err)
            });

        }

    }

    gotoCheckOutPage(request_mode, selected_order) {

        this.setState({ isloading: true });
    
        if (
          selected_order["order_list"] &&
          selected_order["order_list"].length > 0
        ) {
          // selected_order['order_list'] = selected_order['order_list'][0]['order_list'];
          selected_order["order_information"] =
            selected_order["order_list"][selected_order["order_list"].length - 1];
        }
    
        selected_order["bank_information"] = this.state.bank_information;
        selected_order["request_mode"] = request_mode;
        this.setState({ isloading: false });
    
        this.props.history.push({
          pathname: "/orderEditCheckout/",
          state: {
            request_mode: request_mode,
            selected_order: selected_order,
          },
        });

    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    createDefaultMarkup(text) {
        return {
            __html: this.state.html,
      }
    };

    createInput(text) {
        return {
            __html: this.props.payment_initiate_result['input_html'] || this.state.input_html
        }
    }
    
    render() 
    {
        return (            
            
           <div>

               {(!this.props.location.state || this.state.is_refresh) &&
                    <div className="row">
                        <div className="col-lg-10" style={{ margin: 40 }}>
                            <Card sx={{ backgroundColor: '#edf3f0', p: 2, borderRadius: 2 }}>
                                <CardContent  >
                                    <Stack direction="row">
                                        <Typography flex={1} whiteSpace="pre-line" >
                                            { (this.state.message_text || '').trim().length < 4 ? 
                                                `Sorry the information you are looking for could not be found` 
                                                :
                                                this.state.message_text
                                            }
                                            <p></p>
                                            <Link className="page-back text-muted" to={'/orderList'}><span><i
                                                className="fa fa-angle-left"></i></span> Back</Link>
                                            { '  '}Go to Order List
                                        </Typography>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                }

                { this.props.location.state && !this.state.is_refresh && 
                    !((this.props.payment_initiate_result && this.props.payment_initiate_result['is_redirect']) || 
                        this.state.is_redirect) && 
                    <div dangerouslySetInnerHTML={ this.createDefaultMarkup()} />
                   
                }
                {this.props.location.state && !this.state.is_refresh &&
                     this.props.payment_initiate_result && this.props.payment_initiate_result['is_redirect'] && //this.state.is_redirect && 
                    <form ref={item => this.form = item} id="Bankfrm" name="Bankfrm" method="post" 
                        action={this.props.payment_initiate_result['actionUrl'] || this.state.actionUrl}>   

                        <div dangerouslySetInnerHTML={ this.createInput()} />

                    </form>
                }

            </div>
        );
    }

}


const mapStateToProps = state => ({
    payment_initiate_result: state.auth.payment_initiate_result
});

export default connect(mapStateToProps, {  initiatePayment })(PaymentPage);