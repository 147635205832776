import React from "react";
import { Row, Col, Container, Form, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { Button, Modal, CloseButton } from "react-bootstrap";
import {
  loadBankAccountList,
  saveBankAccount,
  validateBankAccount,
  verifyStage,
  verifyIdProof
} from "../../actions/verify";
import { Redirect } from "react-router-dom";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import Box from "@mui/material/Box";
import { Table, Collapse, Spinner } from "react-bootstrap";
import Select from "react-select";
import Popup from "../popup";

class BankAccountList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: false,
      messageText: '',
      is_primary_available: false,
      list_bank_account: [],
      activity_mode: "list",
      bank_account_number: "",
      bank_ifsc_code: "",
      bank_account_type: "",
      bank_name: {},
      is_valid_account: false,
      is_valid_idproof: false,
      selectedFile: null,
      src_selectedFile: null,
      bank_information: [],
      bank_attachment_list: [],
      file_bank_tiff: null,
      show_popup: false,
      pop_messagetext: "",
      list_bank: [],
      list_account_type: [],
      selectedAccountType: [],
      login_stage: '',
      modalshow: false
    };
  }

  componentDidMount() {

    this.onLoadBankAccountList();
  }

  handleConfirmCheck = (e, item) => {
    const _list = this.state.list_bank_account;
    _list.forEach((el) => {
      if (el["beneficiary_account_no"] === item["beneficiary_account_no"])
        item["is_primary"] = !item["is_primary"];
      else item["is_primary"] = false;
    });

    this.setState({ list_bank_account: _list });

    console.log(this.state.list_bank_account);
  };

  onLoadBankAccountList = () => {

    this.setState({ isloading: true });
    this.props
      .loadBankAccountList({})
      .then((response) => {

        if (this.props.bank_result["code"] !== "000")
          alert(this.props.bank_result["messageText"]);
        else {
          if (this.props.bank_result["result"]) {

            const bank_list = this.props.bank_result["result"]["customer_bank_list"];

            this.setState({
              isloading: false,
              messageText: bank_list.length > 0 ? '' : this.props.bank_result["messageText"],
              login_stage: this.props.bank_result["result"]['login_stage'],
              list_bank_account: bank_list,
              list_account_type:
                this.props.bank_result["master_list"]["bank_account_type"] ||
                [],
              list_bank:
                this.props.bank_result["master_list"]["bank_list"] || [],
              is_primary_available: bank_list.filter(el => {
                return el['is_primary']
              }).length > 0
            });
          }
          else this.setState({ list_bank_account: [] });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  onValidateBankAccount = () => {

    this.setState({ isloading: true });
    let body_parameters = {
      client_bank_account_number_1: this.state.bank_account_number,
      client_ifsc_code_1: this.state.bank_ifsc_code,
      client_bank_account_type_1: this.state.bank_account_type,
      client_bank: this.state.bank_name,
      request_mode: 'additional'
    };

    this.props
      .validateBankAccount(body_parameters)
      .then((response) => {
        this.setState({ isloading: false });
        if (this.props.bank_result["code"] !== "000")
          alert(this.props.bank_result["messageText"]);
        else {
          this.setState({
            is_valid_account: true,
            bank_information: this.props.bank_result["id_information"],
            bank_attachment_list: this.props.bank_result["attachment_list"],
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  onSaveBankAccount = (request_mode) => {

    this.setState({ isloading: true });

    let formData = new FormData();

    formData.append("id_type", "cheque");
    formData.append("folder_name", "bank");
    formData.append("attachment_category", "bank");
    formData.append("request_mode", request_mode);
    formData.append(
      "account_information",
      JSON.stringify(this.state.bank_information)
    );

    if (request_mode === "add") {
      if (this.state.selectedFile) {
        formData.append("upload_addbank", this.state.selectedFile);

        if (this.state.file_bank_tiff)
          formData.append("upload_addbank", this.state.file_bank_tiff);
      } else return;
    }

    this.props.saveBankAccount(formData, request_mode, (response) => {

      if (this.props.bank_result['code'] === '000') {

        const _list_bank_account = this.state.list_bank_account
          .concat([this.props.bank_result["result"]])

        this.setState({
          activity_mode: 'list',
          list_bank_account: _list_bank_account //this.props.bank_result["result"]["customer_bank_list"],
        });

      }
    });
  };

  handleChangeSelect = (selectedOption, attribute) => {

    if (attribute)
      this.setState({
        [attribute]:
          attribute === "bank_name" ? selectedOption : selectedOption.value,
      });

    // this.filteroption(selectedOption);
  };

  changeHandler = (event) => {

    this.setState({ isloading: true });

    if (event.target.files[0] && (event.target.files[0].type === 'image/png' ||
      event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg')) {

      const uploaded_file = event.target.files[0];

      {
        const file = uploaded_file;

        const reader = new FileReader();
        reader.onloadend = () => {
          this.dataURLtoFile(reader.result, 'bse');
        };
        reader.readAsDataURL(file);
      }

      {

        let bank_information = {};

        this.state.bank_information.forEach(el => {
          bank_information[
            el['attribute'].replace(/ /gm, "_").toLowerCase()] = el['text'];
        });

        let formData = new FormData();

        formData.append("id_type", "cheque");
        formData.append("request_mode", 'save');
        formData.append("upload_addbank", uploaded_file);
        formData.append("folder_name", 'bank');
        formData.append("attachment_category", 'bank');
        formData.append('is_master_update_required', false);

        formData.append("bank_info", JSON.stringify({
          account_number: bank_information['account_no'] ||
            bank_information['account_number'],
          ifsc_code: bank_information['ifsc_code'],
          account_type: bank_information['account_type'],
          bank_name: bank_information['bank_name']
        }));
        formData.append('request_mode', 'additional');

        this.props.verifyIdProof(formData, response => {

          if (response['code'] === '000') {

            this.setState({
              isloading: false,
              selectedFile: uploaded_file,
              src_selectedFile: URL.createObjectURL(uploaded_file),
              is_valid_idproof: true,
              show_popup: false,
              pop_messagetext: ''
            });

          }
          else
            this.setState({
              isloading: false,
              is_valid_idproof: false,
              show_popup: true,
              pop_messagetext: response['messageText']
            });

        });

      }


    }
    else {

      if (event.target.files.length > 0)
        this.setState({
          isloading: false,
          show_popup: true,
          pop_messagetext: 'Upload file format should be of PNG, JPEG, JPG only'
        });
      else
        this.setState({ isloading: false });

    }

  }

  closePopup = () => {
    this.setState({
      show_popup: false,
      pop_messagetext: ''
    })
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    this.setState({
      file_bank_tiff: new File([u8arr], filename + "_bank.tiff", {
        type: mime.split("/")[0] + "/tiff",
      }),
    });
  }

  handleClose = () => {
    this.setState({ bank_account_number: '', bank_ifsc_code: '' })
    this.setState({ activity_mode: "list", modalshow: false });
  }
  Showaccount = () => {

    this.setState({ activity_mode: "add", modalshow: false });
  }

  render() {

    return (
      <>
        <Header1 />
        <Sidebar />
        <div id="navbar-container">
          <div id="navbar" >
            <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
              <Container >
                <Row >
                  <Col>
                    <div className="card-header">
                      <h5 style={{ marginLeft: 20 }}> Your Bank Account List</h5>
                    </div>
                  </Col>
                  <Col >
                    <button
                      className="btn-sm btn-primary"
                      variant="primary"
                      style={{ width: 150, margin: 20, justifyContent: 'center' }}
                      size="sm"
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/mandateList/"
                        });
                      }}
                    >
                      Go to Mandate List
                    </button>
                  </Col>
                </Row>
              </Container>
              <hr />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="container">
            <div className="row " style={{ marginTop: 20 }}>
              <div className="col-xl-12">

                <div className="mini-logo text-center my-5">
                  {/* <Link to={'./'}><img src={require('./../../../images/logo.png')} alt="" /></Link> */}
                </div>



                <Box className="card" sx={{ width: "100%" }}>



                  {this.state.activity_mode === "list" &&
                    (
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        
                        <div class="buy-sell-widget">
                          <div id="chart-timeline">
                            {this.state.isloading && (
                              <Spinner
                                animation="border"
                                variant="dark"
                                style={{
                                  position: "fixed",
                                  top: "50%",
                                  left: "50%",

                                }}
                              />
                            )}

                            {this.state.list_bank_account.length < 1 && (
                              <Alert variant="dark text-center">
                                <Alert.Heading>
                                  {this.state.isloading
                                    ? "We are fetching your bank account list"
                                    : "No bank account information found"}
                                </Alert.Heading>
                              </Alert>
                            )}

                            <table class="table mb-0 table-responsive-sm bordered ">
                              {(this.state.list_bank_account || []).length > 1 && (
                                <thead>
                                  <tr>
                                    <th style={{ fontSize: 12 }}>#</th>
                                    <th style={{ fontSize: 12 }}>Account Number</th>
                                    <th style={{ fontSize: 12 }}>Branch Information</th>
                                    <th style={{ fontSize: 12 }}></th>
                                    <th style={{ fontSize: 12 }}></th>
                                  </tr>
                                </thead>
                              )}

                              {(this.state.list_bank_account || []).map(
                                (item, index) => (
                                  <tbody key={index}>

                                    <tr>
                                      <td style={{ fontSize: 12, color: "#000", width: '30px' }}>{index + 1}</td>
                                      <td style={{ fontSize: 12, color: "#000", width: '30%' }}>
                                        {item['beneficiary_bank_name']}
                                        <p>
                                          {item["beneficiary_account_no"]} (
                                          {item["beneficiary_account_type"]})
                                        </p>
                                        {item[
                                          "beneficiary_account_holder_name"
                                        ] || ""}
                                      </td>
                                      <td style={{ fontSize: 12, color: "#000" }}>
                                        IFSC : {item["beneficiary_ifsc"]}
                                        <p>
                                          BRANCH : {item["beneficiary_bank_branch_name"]}
                                        </p>
                                      </td>
                                      <td >
                                        <label>
                                          <Form.Group
                                            className="mb-3"
                                            controlId="formBasicCheckbox"
                                          >
                                            <Form.Check
                                              type="checkbox"
                                              label="Is Primary ?"
                                              onChange={(e) => {
                                                this.handleConfirmCheck(
                                                  e,
                                                  item
                                                );
                                              }}
                                              defaultChecked={
                                                item["is_primary"]
                                              }
                                              disabled={true}
                                            />
                                          </Form.Group>
                                        </label>
                                      </td>
                                      <td>
                                        {(!item["is_primary"]) && <Button
                                          disabled={
                                            item["is_primary"] === true ||
                                            item["mf_order_status_id"] === 2
                                          }
                                          className="btn btn-success btn-block"
                                          variant="success"
                                          size="sm"
                                          onClick={() => {
                                            this.onSaveBankAccount("save");
                                          }}
                                        >
                                          Make Primary
                                        </Button>}

                                      </td>
                                    </tr>
                                  </tbody>
                                ))}

                            </table>

                            {
                              this.state.list_bank_account.length === 0 &&
                              <div class="col">
                                <p className="text-center">
                                  {this.state.messageText}
                                </p>
                              </div>

                            }
                          </div>
                        </div>
                      </Box>
                    )}

                  {this.state.activity_mode === "add" && (
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <div
                        className="auth-form card"
                      >
                        <div className="card-body">

                          <h5 className="text-center">Add Bank Account</h5>
                          <hr />
                          <form action="#">
                            {
                              <div>
                                <div class="form-group">

                                  <div class="row">
                                    <div class="col-4">

                                      <label class="mr-sm-2">Select Bank</label>

                                      <Select
                                        value={this.state.list_bank.find(
                                          (obj) =>
                                            obj.value ===
                                            this.state.bank_name["value"] || ""
                                        )}
                                        onChange={(e) =>
                                          this.handleChangeSelect(e, "bank_name")
                                        }
                                        options={this.state.list_bank}
                                      />

                                    </div>
                                    <div class="col-3">
                                      <label class="mr-sm-2">Account Type</label>

                                      <Select
                                        value={this.state.list_account_type.find(
                                          (obj) =>
                                            obj.value ===
                                            this.state.bank_account_type
                                        )}
                                        onChange={(e) =>
                                          this.handleChangeSelect(
                                            e,
                                            "bank_account_type"
                                          )
                                        }
                                        options={this.state.list_account_type}
                                      />

                                    </div>
                                    <div class="col-3">
                                      <label>Account Number</label>
                                      <input
                                        type="text"
                                        className="form-control font-weight-bold"
                                        value={this.state.bank_account_number}
                                        onChange={(event) => {
                                          if ((event.target.value.match(/^[0-9]+$/)
                                            || event.target.value === "") && event.target.value.toString().length < 14) {

                                            this.setState({
                                              bank_account_number:
                                                event.target.value,
                                            });

                                          }

                                        }

                                        }
                                      />

                                    </div>
                                    <div class="col-2">
                                      <label>IFSC Code</label>

                                      <input maxLength={17}
                                        value={this.state.bank_ifsc_code}
                                        className="form-control font-weight-bold"
                                        onChange={(event) => {
                                          if ((event.target.value.match(/^[0-9a-zA-Z]+$/)
                                            || event.target.value === "") && event.target.value.toString().length < 12) {

                                            this.setState({
                                              bank_ifsc_code: event.target.value,
                                            })

                                          }

                                        }
                                        }

                                        type="text"
                                      />

                                    </div>
                                  </div>
                                  <div class="row">

                                  </div>

                                </div>
                              </div>
                            }

                            <Form.Group
                              className="mb-3"
                              controlId="formBasicCheckbox"
                            ></Form.Group>
                            {

                              <div>
                                <div class="form-group">

                                  <div class="row">
                                    <div class="col-4"></div>
                                    <div class="col-4"></div>
                                    <div class="col-2">
                                      <Button
                                        className="btn btn-success btn-block"
                                        variant="success"
                                        onClick={this.onValidateBankAccount}
                                        disabled={this.state.bank_ifsc_code.length === "" || this.state.bank_account_number === ""}
                                      >
                                        Verify
                                      </Button>
                                    </div>
                                    <div class="col-2">


                                      <Button
                                        className="btn btn-success btn-block"
                                        variant="success"
                                        onClick={() => { this.setState({ modalshow: true }) }}
                                      >
                                        {" "}
                                        Cancel
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }

                            {this.state.bank_information.length > 0 && (
                              <p className="text-center mb-5">
                                Recently uploaded information
                              </p>
                            )}
                            <div class="row">
                              <div class="col">
                                {this.state.bank_information.map((el, i) => (
                                  <div key={i}>
                                    <label className="text-dark">
                                      {el["attribute"]}{` : `}
                                      <label className="text-success">
                                        {el["text"]}
                                      </label>
                                    </label>
                                    <p></p>
                                  </div>
                                ))}
                              </div>
                              <div class="col">
                                {this.state.is_valid_account && (
                                  <div>
                                    <p>Kindly upload your passbook or valid
                                      cheque (File format should be png or jpeg)
                                    </p>
                                    <Form.Control
                                      type="file"
                                      onChange={this.changeHandler}
                                    />
                                  </div>
                                )}

                                {this.state["src_selectedFile"] && (
                                  <div>
                                    <img src={this.state["src_selectedFile"]}
                                      style={{ width: 300, height: 220 }} />
                                  </div>
                                )}

                              </div>
                            </div>


                            {
                              <div className="text-center">
                                {(this.state.bank_attachment_list || []).map(
                                  (el, i) => (
                                    <div key={i} className="form-group">
                                      {el.length > 5 ? (
                                        <img className="photo" src={el} />
                                      ) : null}
                                    </div>
                                  )
                                )}
                              </div>
                            }

                            {this.state.is_valid_idproof &&
                              <div class="row">
                                <div class="col-6">

                                </div>
                                <div class="col-4">

                                </div>
                                <div class="col-2">
                                  <p></p>
                                  <Button
                                    className="btn btn-success btn-block"
                                    variant="success"
                                    onClick={() => this.onSaveBankAccount("add")}
                                  >
                                    Confirm
                                  </Button>
                                </div>
                              </div>
                            }

                          </form>
                        </div>
                      </div>
                    </Box>
                  )}




                  {this.state.show_popup && (
                    <Modal show={this.state.show_popup} onHide={() => this.closePopup()}>
                      <Modal.Header>

                      </Modal.Header>
                      <Modal.Body>

                        <p>{this.state["pop_messagetext"]}</p>

                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="primary" onClick={() => this.closePopup()}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  )}

                  {this.state.isloading && (
                    <Spinner
                      animation="border"
                      variant="dark"
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        zIndex: 899,
                      }}
                    />
                  )}
                  {this.state.activity_mode === "list" &&
                    (this.state.list_bank_account || []).length > 0 && (
                      <div class="col-4" style={{ alignSelf: 'left' }}>
                        <div class="row">
                          <div class="col-3">
                            <button
                              className="btn-sm btn-success"
                              variant="success"
                              style={{ width: 150, margin: 20, justifyContent: 'center' }}
                              size="sm"
                              disabled={this.state.is_primary_available}
                              onClick={() => {
                                this.setState({ activity_mode: "add" });
                              }}
                            >
                              Add Account
                            </button>
                          </div>

                        </div>
                      </div>
                    )}

                  <Modal show={this.state.modalshow} onHide={() => this.handleClose()}>
                    <Modal.Header>
                      <Modal.Title>Add bank Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you want to cancel the process?</Modal.Body>
                    <Modal.Footer>
                      <Button variant="primary" onClick={() => this.handleClose()}>
                        Yes
                      </Button>
                      <Button variant="secondary" onClick={() => this.Showaccount()}>
                        No
                      </Button>

                    </Modal.Footer>
                  </Modal>


                </Box>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.verify.loading,
  bank_result: state.verify.bank_result,
});


export default connect(mapStateToProps, {
  loadBankAccountList,
  saveBankAccount,
  validateBankAccount,
  verifyStage,
  verifyIdProof
})(BankAccountList);
