import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment';
import {
  createSipOrder,
  uploadMandate,
  registerMandate,
  loadBankAccountList,
  loadNachMandateForm,
  loadNomineeList,
  refreshMandateStatus,
  cancelMandate,
  twoFactorAuthentication
} from "../../actions/orders";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import { PDFReader } from "reactjs-pdf-reader";
import OTPInput, { ResendOTP } from "otp-input-react";

import {
  Table,
  Collapse,
  Modal,
  Form,
  Row,
  Col,
  Button,
  Label,
  Spinner
} from "react-bootstrap";
import Select from "react-select";
import { saveAs } from "file-saver";
import Popup from "../popup";

class SipCheckout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_order: {},
      sip_order: {},
      list_bank_account: [],
      list_mandate_initial: [],
      list_mandate: [],
      list_pending_mandate: [],
      list_mandate_debit: [],
      src_nach_form: "",
      selected_mandate_id: "",
      is_mandate_selected: false,
      is_mandate_registered: false,
      is_mandate_uploaded: false,
      is_register_mandate_allowed: true,
      is_debit_max_limit: false,
      mandate_amount: 25000,
      mandate_id: "",
      mandate_date_validation: {
        message_text: "",
      },
      is_first_order_today: false,
      loading: false,
      is_nach: true,
      nach_option: "existing",
      nach_option_new_processed: false,
      nach_option_new_processed_message_text: "",
      sip_information_text: "You need to make payment physically on ",
      render_pdf: null,
      nachmandateFile: null,
      src_nachmandateFile: null,
      byte_nachmandateFile: null,

      show_upload_popup: false,
      modal_upload_mandate: {},
      modal_nachmandateFile: null,
      src_modal_nachmandateFile: null,

      /****** NOMINEE OPTIONS *******/
      list_nominee: [],
      nominee_option: 'existing',
      existing_nominee_id: '',
      existing_nominee_selected: false,
      is_no_nominee_registered: false,
      no_nominee_form: {},
      nonomineeformFile: null,
      src_nonomineeformFile: null,
      byte_nonomineeformFile: null,
      client_code: '',
      /****** NOMINEE OPTIONS *******/

      is_otp_enabled: false,
      two_factor_authentication: {
        mobile_otp: '',
        email_otp: '',
        twofactor_token_id: ''
      },

      show_popup: false,
      popup_messageText: "",
      invest_more_amount: 0.0,
      redeemable_amount: 0.0,
      is_redeem_full_checked: false,
      is_stage_verified: false,
      is_verify_enabled: false,
      is_verified_editable: false,
      esignkyc_information: {},
      esignkyc_attachment_list: {},
      is_esign_verified: false,
      is_esign_button_disabled: false,
      isloading: false,
    };
  }

  componentDidMount() {

    this.setState(this.props.location.state);
    /*
    this.setState({
      selected_order: this.props.location.state.selected_order,
      sip_order: this.props.location.state.sip_order,
      isloading: true
    });
    */
    this.onLoadBankAccountList();
  }

  onLoadBankAccountList = () => {

    this.props
      .loadBankAccountList({
        sip_date: this.props.location.state.sip_order["sip_date"],
        scheme_name: this.props.location.state.sip_order['scheme_name'],
        scheme_code: this.props.location.state.sip_order['scheme_code'],
      })
      .then((response) => {
        if (this.props.order_result["code"] !== "000") {
          this.setState({ isloading: false });
          alert(this.props.order_result["messageText"]);
        } else {
          let _result = this.props.order_result["result"];

          if (_result) {
            let _customer_pending_mandate_list =
              _result["customer_pending_mandate_list"];
            let _nach_form = _result["mandate_nach_list"].filter((el) => {
              return (
                el["mandate_id"] ===
                _customer_pending_mandate_list[0]["mandate_id"]
              );
            });

            let setStateObject = {
              isloading: false,
              list_bank_account: _result['customer_bank_list'],
              list_mandate_initial: _result["customer_mandate_list"],
              list_mandate: _result["customer_mandate_list"],
              list_pending_mandate: _customer_pending_mandate_list,
              list_mandate_debit: _result["customer_mandate_debit_list"],
              mandate_date_validation: _result["mandate_date_validation"],
              is_register_mandate_allowed: _result['is_register_mandate_allowed'],
              is_mandate_registered:
                _customer_pending_mandate_list.filter((obj) => {
                  return obj.is_selected === true;
                }).length > 0,
              src_nach_form:
                _nach_form.length > 0 ? _nach_form[0]["nach_form"] : "",
              selected_mandate_id:
                _nach_form.length > 0 ? _nach_form[0]["mandate_id"] : "",

              list_nominee: _result["nominee_list"].filter(el => {
                return el['id'] !== 'none'
              }),
              is_no_nominee_registered: _result["is_no_nominee_registered"],
              no_nominee_form: _result["no_nominee_form"] ||
                this.props.location.state['no_nominee_form']
            };

            this.setState(setStateObject);
          } else
            this.setState({
              list_mandate: [],
              list_mandate_initial: [],
              list_pending_mandate: [],
              list_mandate_debit: [],
              mandate_date_validation: {
                message_text: "",
              },
              is_mandate_registered: false,
              src_nach_form: "",
              selected_mandate_id: "",
            });
        }
      })
      .catch((err) => {
        console.log("line 146 :");
        console.log(err);
        alert(err);
      });
  };

  onloadNachMandateForm = () => {

    this.setState({ isloading: true });
    this.props
      .loadNachMandateForm(this.state.selected_order)
      .then((response) => {
        this.setState({ isloading: false });
        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);
        else {
          if (this.props.order_result["render_pdf"])
            this.setState({
              render_pdf: atob(this.props.order_result["pdf_base64"]),
            });
        }
      })
      .catch((err) => {
        console.log("line 168 :");
        console.log(err);
        alert(err);
      });
  };

  refreshMandateStatus = (mandate_id) => {

    this.setState({ isloading: true });
    this.props
      .refreshMandateStatus(
        Object.assign(this.state.selected_order, { mandate_id: mandate_id })
      )
      .then((response) => {
        this.setState({ isloading: false });
        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);
        else {
          if (this.props.order_result["render_pdf"])
            this.setState({
              render_pdf: atob(this.props.order_result["pdf_base64"]),
            });
          else {

            let _result = this.props.order_result["result"];

            if (_result && _result["status"]
            ) {

              let _list = this.state.list_mandate_initial;

              _list.forEach((el) => {

                if (el["mandate_id"] === _result["mandateId"]) {

                  el["is_processed"] = _result["is_processed"];
                  el["is_reupload"] = _result["is_reupload"];

                  el["status"] = _result["status"];

                }

              });

              console.log(_list);

              this.setState({
                list_mandate_initial: _list,
                list_mandate: _list
              });

            }
          }
        }
      })
      .catch((err) => {
        console.log("line 168 :");
        console.log(err);
        alert(err);
      });
  };

  onCreateSipOrder = () => {

    this.setState({ isloading: true });

    let _bank_information =
      this.state.list_bank_account.filter((el) => {
        return el["is_primary"] === true;
      })[0] || {};

    this.props
      .createSipOrder({
        client_info: {
          selected_order: this.state.selected_order,
          mfapi_scheme_code: this.state.selected_order["mfapi_scheme_code"],
          scheme_code: this.state.sip_order["scheme_code"],
          scheme_name: this.state.sip_order["scheme_name"],
          buy_sell: "P",
          buy_sell_type: "FRESH",
          sip_installment_amount: this.state.sip_order["sip_amount"],
          sip_frequency_type: this.state.sip_order["sip_frequency"],
          sip_no_of_installments:
            this.state.sip_order["sip_installment_numbers"],
          sip_start_date: this.state.sip_order["sip_date"],
          mandate_id: this.state.selected_mandate_id,
          sip_mode: "NEW",
          sip_id: "",
          sip_folio_no: '', //this.state.selected_mandate_id,
          sip_type: this.state.is_nach ? "xsip" : "sip",
          is_first_order_today: this.state.is_first_order_today,
          transaction_mode: this.state.sip_order["transaction_mode"],
          minimum_installment_amount:
            this.state.selected_order["minimum_installment_amount"],
          maximum_installment_amount:
            this.state.selected_order["maximum_installment_amount"],
          bank_account_information: _bank_information,
          is_pause_allowed: this.state.selected_order["is_pause_allowed"],
          pause_minimum_installments:
            this.state.selected_order["pause_minimum_installments"],
          pause_maximum_installments:
            this.state.selected_order["pause_maximum_installments"],
          'nominee_option': this.state.nominee_option,
          'nominee_id': this.state.existing_nominee_id
        },
        two_factor_authentication: this.state.two_factor_authentication
      })
      .then((response) => {
        this.setState({ isloading: false });
        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);
        else {
          this.props.history.push({
            pathname: this.props.order_result["navigateScreen"], //'/paymentPage/',
            state: this.props.order_result["result"], //{ selected_order: this.order_result['result'] }
          });
        }
      })
      .catch((err) => {
        console.log("line 236 :");
        console.log(err);
        alert(err);
      });
  };

  onRegisterMandate = () => {

    this.setState({ isloading: true });

    let _bank_information =
      this.state.list_bank_account.filter((el) => {
        return el["is_primary"] === true;
      })[0] || {};

    this.props
      .registerMandate({
        client_info: {
          bank_name: _bank_information["beneficiary_bank_name"],
          branch_name: _bank_information["beneficiary_bank_branch_name"],
          account_no: _bank_information["beneficiary_account_no"],
          account_type: _bank_information["beneficiary_account_type"],
          ifsc_code: _bank_information["beneficiary_ifsc"],
          account_holder_name:
            _bank_information["beneficiary_account_holder_name"],
          sip_date: this.state.sip_order["sip_date"],
          start_date: this.state.sip_order["sip_date"],
          debit_amount: this.state.mandate_amount,
          request_mode: "web",
          mandate_type: "X",
        },
      })
      .then((response) => {

        this.setState({ isloading: false });

        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);
        else {
          //   if(this.props.order_result['state_parameters'])
          //       this.setState(this.props.order_result['state_parameters']);
          let _result = this.props.order_result["result"];

          if (_result) {
            let _customer_pending_mandate_list =
              _result["customer_pending_mandate_list"];
            let _nach_form = _result["mandate_nach_list"].filter((el) => {
              return (
                el["mandate_id"] ===
                _customer_pending_mandate_list[0]["mandate_id"]
              );
            });

            this.setState({
              list_mandate: _result["customer_mandate_list"],
              list_pending_mandate: _customer_pending_mandate_list,
              list_mandate_debit: _result["customer_mandate_debit_list"],
              mandate_date_validation: _result["mandate_date_validation"],
              is_mandate_registered:
                _customer_pending_mandate_list.filter((obj) => {
                  return obj.is_selected === true;
                }).length > 0,
              src_nach_form:
                _nach_form.length > 0 ? _nach_form[0]["nach_form"] : "",
              selected_mandate_id:
                _nach_form.length > 0 ? _nach_form[0]["mandate_id"] : "",
            });
          }
        }
      })
      .catch((err) => {
        console.log("line 303 :");
        console.log(err);
        alert(err);
      });
  };

  onUploadMandate = (request_from) => {

    this.setState({ isloading: true });
    let formData = new FormData();

    let _nachmandateFile = (request_from === 'modal' ?
      this.state.modal_nachmandateFile : this.state.nachmandateFile);

    if (_nachmandateFile) {

      formData.append("upload_file", _nachmandateFile);
      formData.append("id_type", "scanmandate");
      formData.append("mandateId", this.state.selected_mandate_id);
      formData.append("mandateType", "XSIP");
      formData.append("imageName", _nachmandateFile.name);
      formData.append("imageType", _nachmandateFile.type);
      // formData.append("pFileBytes", this.state.byte_nachmandateFile);

      this.props
        .uploadMandate(formData)
        .then((response) => {

          this.setState({ isloading: false });
          if (this.props.order_result["code"] !== "000")
            alert(this.props.order_result["messageText"]);
          else {
            if (this.props.order_result["state_parameters"]) {
              alert(
                this.props.order_result["state_parameters"][
                "nach_option_new_processed_message_text"
                ]
              );
              this.setState(this.props.order_result["state_parameters"]);
            }
            this.onLoadBankAccountList();
          }
        })
        .catch((err) => {
          console.log("line 340 :");
          console.log(err);
          alert(err);
        });
    }

  };

  onCancelMandate = (mandate_id) => {

    this.setState({ isloading: true });
    this.props
      .cancelMandate(
        Object.assign(this.state.selected_order, { mandate_id: mandate_id })
      )
      .then((response) => {

        this.setState({ isloading: false });

        let _result = this.props.order_result["result"];

        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);
        else {
          if (this.props.order_result["render_pdf"])
            this.setState({
              render_pdf: atob(this.props.order_result["pdf_base64"]),
            });
          else {

            if (_result && _result["customer_mandate_list"]) {
              let _customer_pending_mandate_list =
                _result["customer_pending_mandate_list"];
              let _nach_form = (_result["mandate_nach_list"] || []).filter((el) => {
                return (
                  el["mandate_id"] ===
                  _customer_pending_mandate_list[0]["mandate_id"]
                );
              });

              let setStateObject = {
                list_mandate: _result["customer_mandate_list"],
                list_pending_mandate: _customer_pending_mandate_list,
                is_mandate_registered:
                  _customer_pending_mandate_list.filter((obj) => {
                    return obj.is_selected === true;
                  }).length > 0,
                src_nach_form:
                  _nach_form.length > 0 ? _nach_form[0]["nach_form"] : "",
                selected_mandate_id:
                  _nach_form.length > 0 ? _nach_form[0]["mandate_id"] : "",
              };

              this.setState(setStateObject);
            }


          }
        }
      })
      .catch((err) => {
        console.log("line 168 :");
        console.log(err);
        alert(err);
      });
  };

  handleMandateCheck = (e, item) => {

    this.setState({ isloading: true });

    const _list = this.state.list_mandate_initial;

    _list.forEach((el) => {
      el["is_selected"] = false;

      if (el["mandate_id"] === item["mandate_id"])
        el["is_selected"] = e.target.checked;
    });

    let _selected_mandate = _list.filter((el) => {
      return el["is_selected"] === true;
    });
    this.setState({ isloading: false });

    this.setState({
      list_mandate: _selected_mandate.length > 0 ? _selected_mandate : _list,
      is_mandate_selected: _selected_mandate.length > 0,
      selected_mandate_id:
        _selected_mandate.length > 0 ? _selected_mandate[0]["mandate_id"] : "",
    });

    window.scrollTo(0, document.body.scrollHeight);

  };

  handleMandateIdSelect = (item) => {
    this.setState({ isloading: true });
    const _list = this.state.list_pending_mandate;
    _list.forEach((el) => {
      if (el["mandate_id"] === item.value) item["is_selected"] = !item.value;
      else item["is_selected"] = false;
    });
    this.setState({ isloading: false });
    this.setState({
      selected_mandate_id: item.value,
      list_pending_mandate: _list,
      is_mandate_selected:
        _list.filter((el) => {
          return el["is_selected"] === true;
        }).length > 0,
    });
  };

  handleInvestAmount(event, attribute) {
    const re = /^\d+(\.\d{0,3})?$/; ///^\d*\.?\d*$/ ///^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      this.setState({
        /*invest_more_amount*/[attribute]: event.target.value.replace(
        /\b0+/g,
        ""
      ),
      });
    }
  }

  changeHandler = async (event) => {

    if (
      event.target.files[0] &&
      (event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/jpg")
    ) {
      {
        const file = event.target.files[0];
        const buffer = await file.arrayBuffer();

        let byteArray = new Int8Array(buffer);
        this.setState({
          nachmandateFile: file,
          byte_nachmandateFile: byteArray,
          src_nachmandateFile: URL.createObjectURL(file),
        });
      }
    } else {
      this.setState({
        show_popup: true,
        popup_messageText:
          "Upload file format should be of PNG, JPEG, JPG only",
      });
    }
  };

  changeHandlerModal = async (event) => {

    if (
      event.target.files[0] &&
      (event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/jpg")
    ) {
      {
        const file = event.target.files[0];
        const buffer = await file.arrayBuffer();

        let byteArray = new Int8Array(buffer);
        this.setState({
          modal_nachmandateFile: file,
          src_modal_nachmandateFile: URL.createObjectURL(file),
        });
      }
    } else {
      this.setState({
        show_popup: true,
        popup_messageText:
          "Upload file format should be of PNG, JPEG, JPG only",
      });
    }
  };

  handleRadioChange = (value, attribute) => {
    // console.log(event);
    this.setState({ [attribute]: value });
  };

  base64toBlob = (data, pdfContentType) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr(
      `data:${pdfContentType};base64,`.length
    );

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    let _blob = new Blob([out], { type: pdfContentType });

    return URL.createObjectURL(_blob);
    // return new Blob([out], { type: pdfContentType });
  };

  async downloadNachForm() {
    if (this.state.src_nach_form && this.state.src_nach_form.length > 255) {
      const byteCharacters = atob(this.state.src_nach_form);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // const { data } = await getTicketsPdf()
      const blob = new Blob([byteArray], { type: "application/pdf" });
      saveAs(blob, this.state.selected_mandate_id + ".pdf");
    }
  }

  /************** NOMINEE OPTION *****************/
  handleNomineeCheck = (e, item) => {

    let _existing_nominee_selected = false, _existing_nominee_id = '';
    const _list = this.state.list_nominee;
    _list.forEach(el => {

      if (el['id'] === item['id']) {

        item['is_selected'] = !item['is_selected'];

        _existing_nominee_selected = item['is_selected'];
        if (_existing_nominee_selected)
          _existing_nominee_id = item['id'];
        else
          _existing_nominee_id = '';
      }
      else
        item['is_selected'] = false;

    });

    this.setState({
      list_nominee: _list,
      existing_nominee_selected: _existing_nominee_selected,
      existing_nominee_id: _existing_nominee_id
    });

  }

  changeNomineeHandler = async (event) => {

    if (event.target.files[0] && (event.target.files[0].type === 'image/png' ||
      event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg')) {

      if (event.target.files[0].name.toUpperCase() === `NOMINEE_OPTING_OUT_DECLARATION_` + this.state.client_code + `.PNG` ||
        event.target.files[0].name.toUpperCase() === `NOMINEE_OPTING_OUT_DECLARATION_` + this.state.client_code + `.JPEG` ||
        event.target.files[0].name.toUpperCase() === `NOMINEE_OPTING_OUT_DECLARATION_` + this.state.client_code + `.JPG`) {

        const file = event.target.files[0];
        const buffer = await file.arrayBuffer();

        let byteArray = new Int8Array(buffer);
        this.setState({
          nonomineeformFile: file,
          byte_nonomineeformFile: byteArray,
          src_nonomineeformFile: URL.createObjectURL(file)
        });

      }
      else {
        this.setState({
          show_popup: true,
          pop_messagetext: `Upload file name should be ` +
            `NOMINEE_OPTING_OUT_DECLARATION_` + this.state.client_code + `.` +
            event.target.files[0].type.split('/')[1] + ` only`
        });
      }
    }
    else {
      this.setState({
        show_popup: true,
        pop_messagetext: 'Upload file format should be of PNG, JPEG, JPG only'
      });
    }

  }

  async downloadNomineeForm(src_nominee_form, download_file_name) {
    if (src_nominee_form && src_nominee_form.length > 255) {

      const byteCharacters = atob(src_nominee_form);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // const { data } = await getTicketsPdf()
      const blob = new Blob([byteArray], { type: "application/pdf" });
      saveAs(blob, download_file_name + ".pdf");

    }
  }

  onUploadNomineeDeclaration = (request_from) => {

    this.setState({ isloading: true });
    let formData = new FormData();

    let _nonomineeformFile = this.state.nonomineeformFile;

    if (_nonomineeformFile) {

      formData.append("file", _nonomineeformFile);
      formData.append("id_type", "no-nominee");
      formData.append("imageName", _nonomineeformFile.name);
      formData.append("imageType", _nonomineeformFile.type);
      // formData.append("pFileBytes", this.state.byte__nonomineeformFile);
      formData.append('nominee_information', JSON.stringify({
        id: 'none',
        name: '',
        relationship: '',
        poi: '',
        attachment_id: '',
        attachment_list: [],
        id_information: {
          name: '', fathers_name: '', id_no: '', dob: '', id_type: '',
          part: '', 'encoded_signature': '', encoded_image: '', nominee_is_minor: false
        }
      }));

      this.props
        .uploadNominee(formData)
        .then((response) => {

          this.setState({ isloading: false });
          if (this.props.order_result["code"] !== "000")
            alert(this.props.order_result["messageText"]);
          else {
            if (this.props.order_result["state_parameters"]) {
              this.setState(this.props.order_result["state_parameters"]);
            }
          }
        })
        .catch((err) => {
          console.log("line 340 :");
          console.log(err);
          alert(err);
        });
    }
  };
  /************** NOMINEE OPTION *****************/

  closePopup = () => {
    this.setState({
      show_popup: false,
    });
  };

  fnDateOrdinalformat(date, tmp) {
    return [
      (tmp = date.getDate()) +
      ([, 'st', 'nd', 'rd'][/1?.$/.exec(tmp)] || 'th'),
      ['January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'
      ][date.getMonth()],
      date.getFullYear()
    ].join(' ')
  }

  sendOTP = () => {

    this.setState({ isloading: true });

    this.props
      .twoFactorAuthentication({
        client_info: {
          two_factor_authentication: this.state.two_factor_authentication,
          request_action: 'sip order registration',
          is_otp_email_required: false
        },
      })
      .then((response) => {

        if (this.props.order_result["code"] !== "000") {
          this.setState({ isloading: false });
          alert(this.props.order_result["messageText"]);
        }
        else {

          let _result = this.props.order_result; //["result"];
          console.log(_result);

          if (_result && _result['twofactor_id']) {

            this.setState({
              isloading: false,
              is_otp_enabled: true,
              two_factor_authentication: {
                mobile_otp: '',
                email_otp: '',
                twofactor_token_id: _result['twofactor_id']
              }
            });

          }

        }
      })
      .catch((err) => {
        alert(err);
      });

  }

  handle2FactorChange = (input, attribute) => e => {

    if (e)
      this.setState({
        [attribute]: { ...this.state[attribute], [input]: e }
      });
    else
      this.setState({ [input]: e });

  }

  render() {

    const renderButton = buttonProps => {
      return (
        <div >
          {/* <div className="col-6"> */}
            <button style={{width:'200px'}} onClick={this.sendOTP} {...buttonProps}
              class={buttonProps.remainingTime !== 0 ? `btn btn-outline-secondary` : `btn btn-outline-success`}>
              {`Resend OTP`}
            </button>
          {/* </div>
          <div className="col-6"> */}
          {`   `}
            {buttonProps.remainingTime !== 0
              ? <span style={{fontSize: 14}}>Didn't receive the otp ?
                You can resend otp in
                  <span style={{ fontWeight: 'bold', color: 'green' }}>{' '}{buttonProps.remainingTime}</span> secs.
                </span>
              : ``}
          {/* </div> */}
        </div>
      );
    };
    const renderTime = () => React.Fragment;

    return (
      <>
        <Header1 />
        <Sidebar />

        <div className="content-body" style={{ marginTop: 105 }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 style={{ marginLeft: 20 }}>Sip Order Checkout</h4>
                  </div>
                  {this.state.isloading && (
                    <Spinner
                      animation="border"
                      variant="dark"
                      style={{ position: "fixed", top: "50%", left: "50%" }}
                    />
                  )}
                  <div className="card-body" style={{ marginLeft: 20 }}>

                    <div class="row">
                      <div class="col-6">
                        <p>
                          <span style={{ fontSize: 14 }}>
                            {this.state.sip_order["scheme_name"]}
                          </span>
                        </p>
                        <p>
                          <span style={{
                            width: 400,
                            fontSize: 12,
                            color: 'InfoText',
                            backgroundColor: '#a8c3ed', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                          }}>
                            Frequency : {this.state.sip_order["display_text"]}
                          </span>

                          {this.state.sip_order["sip_amount"] && (
                            <span >
                              {`   |   `}
                              <span style={{
                                width: 400,
                                fontSize: 12,
                                color: 'InfoText',
                                backgroundColor: '#a8c3ed', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                              }}>
                                Investment Amount :
                                <span style={{ fontFamily: 'sans-serif' }}>{`   `}&#8377;</span>
                                {this.state.sip_order["sip_amount"]}
                              </span>
                            </span>

                          )}
                        </p>
                        {this.state.sip_order['sip_date'] &&
                          <span style={{ backgroundColor: '#dbcec5', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                            SIP amount will be debited from primary account on
                            <b>{' '}
                              {moment(new Date('1982-11-' + this.state.sip_order['sip_date'])).format('Do')}
                              {/* {this.fnDateOrdinalformat(this.state.sip_order['sip_date']).split(' ')[0]} */}
                            </b>
                            {` of every `}
                            {this.state.sip_order['sip_frequency'].toLowerCase().replace('ly', '')}
                          </span>
                        }

                      </div>
                      <div class="col-6">
                        <div >

                          <p style={{ fontSize: 14 }}>Primary Bank Account Information</p>

                          <ul className="linked_account">
                            {(this.state.list_bank_account || []).map(
                              (item, index) => (
                                // <div>
                                <li key={index}>
                                  <div className="row">
                                    <div className="col-6">

                                      <span className="mr-3">
                                        <i className="fa fa-bank"></i>
                                      </span>
                                      {item["beneficiary_account_no"]} (
                                      {item["beneficiary_account_type"]}
                                      )
                                      <p>
                                        {item[
                                          "beneficiary_account_holder_name"
                                        ] || ""}
                                        {` `}
                                        <span>
                                          <i className="la la-check"></i>
                                        </span>
                                        <Link to={"#"}>Verified</Link>
                                      </p>
                                    </div>
                                    <div class="col-5">
                                      IFSC :{" "}
                                      {item["beneficiary_ifsc"]}
                                      <p>

                                        {item[
                                          "beneficiary_bank_branch_name"
                                        ] || ""}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                                // </div>
                              )
                            )}
                          </ul>

                        </div>
                      </div>
                    </div>
                    <hr />


                    {/* <div className="card" > */}
                    <div >
                      <p>
                        Do you want to add nominee ?
                      </p>
                      <div class="row">
                        <div class="col-4">
                          <Form.Check
                            type="radio"
                            label="Yes"
                            name="nominee_option"
                            id="existing"
                            value="existing"
                            checked={
                              this.state.nominee_option === "existing"
                            }
                            onChange={(e) =>
                              this.setState({ nominee_option: 'existing' })
                            }
                          />
                        </div>
                        <div class="col-3">
                          <Form.Check
                            type="radio"
                            label="No"
                            name="nominee_option"
                            id="none"
                            value="none"
                            checked={
                              this.state.nominee_option ===
                              "none"
                            }
                            onChange={(e) =>
                              this.setState({ nominee_option: 'none' })
                            }
                          />
                        </div>

                        <div>
                          <label></label>
                        </div>
                      </div>
                      {
                        this.state.nominee_option === 'none' &&
                        <div class="row">
                          <p>
                            As per SEBI guidelines as an investor you are required to sign the declaration form (only for first time in our app).
                            Signed declaration form will be used for subsequent fresh orders while sending to the exchange.
                          </p>

                          <div class="col-7">
                            <div >
                              {
                                (this.state
                                  .no_nominee_form || '')
                                  .length > 255 && (
                                  <PDFReader
                                    data={atob(
                                      this.state
                                        .no_nominee_form
                                    )}
                                    width="650"
                                  />
                                )
                              }

                            </div>
                          </div>
                          {
                            !this.state.is_no_nominee_registered &&
                            <div class="col-5">

                              <div class="row">

                                <p
                                  style={{
                                    textAlign: "left",
                                  }}
                                >
                                  <b>Step 1</b>. <Button
                                    variant="success"
                                    onClick={() => {
                                      this.downloadNomineeForm(
                                        this.state.no_nominee_form,
                                        `NOMINEE_OPTING_OUT_DECLARATION_`
                                        + this.state.client_code
                                      );
                                    }
                                    }
                                  >
                                    Download
                                  </Button>

                                </p>
                                <p
                                  style={{
                                    textAlign: "left",
                                  }}
                                >
                                  <b>Step 2</b>. Put your signature at Unit Holder (1) Signature in the form

                                </p>
                                <p
                                  style={{
                                    textAlign: "left",
                                  }}
                                >
                                  <b>Step 3</b>. Scan it (png/jpeg format)

                                </p>
                                <p
                                  style={{
                                    textAlign: "left",
                                  }}>
                                  Upload without changing the file name to authorize opting out nominee option for one time.
                                  For eg. file name should be strictly as below
                                  <span style={{ fontWeight: 'bold', color: 'darkgreen' }}>
                                    {
                                      ` NOMINEE_OPTING_OUT_DECLARATION_`
                                      + this.state.client_code + `.png`}</span>
                                  {` `}
                                  or
                                  <span style={{ fontWeight: 'bold', color: 'darkgreen' }}>
                                    {
                                      ` NOMINEE_OPTING_OUT_DECLARATION_`
                                      + this.state.client_code + ".jpeg"}{` `}
                                  </span>

                                </p>
                                <Form.Control
                                  type="file"
                                  onChange={
                                    this.changeNomineeHandler
                                  }
                                />

                              </div>

                              <div class="row">
                                {this.state.src_nonomineeformFile &&
                                  <div class="col-4">
                                    <img
                                      width="150"
                                      src={
                                        this.state[
                                        "src_nonomineeformFile"
                                        ]
                                      }
                                    />
                                  </div>
                                }
                                <div class="col-6">
                                  <br />
                                  <Button
                                    className="btn btn-success btn-sm"
                                    variant="success"
                                    onClick={() => {
                                      this.onUploadNomineeDeclaration('main');
                                    }
                                    }
                                    disabled={this.state.isloading ||
                                      !this.state.src_nonomineeformFile}
                                  >
                                    Upload Declaration
                                  </Button>
                                </div>

                              </div>

                            </div>
                          }

                        </div>
                      }
                      {
                        this.state.nominee_option === 'existing' &&

                        <div class="row">
                          <table style={{ marginLeft: 15 }}>
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ fontSize: 12 }}>#</th>
                                <th colSpan="4" style={{ fontSize: 12 }}>Name</th>
                                <th colSpan="2" style={{ fontSize: 12 }}>Relationship</th>
                                <th colSpan="2" style={{ fontSize: 12 }}>Proof Of Identity</th>
                                <th colSpan="2" style={{ fontSize: 12 }}>ID No</th>
                                <th colSpan="2" style={{ fontSize: 12 }}>Select</th>
                              </tr>
                            </thead>
                            {(this.state.list_nominee || []).map((item, index) => (
                              <tbody key={index}>
                                <tr>
                                  <td colSpan={1}>
                                    <p style={{ fontSize: 13 }}>
                                      {index + 1}
                                    </p>
                                  </td>
                                  <td colSpan={4}>
                                    <p style={{ fontSize: 13 }}>
                                      {item["name"]}
                                    </p>
                                  </td>
                                  <td colSpan={2}>
                                    <p style={{ fontSize: 13 }}>
                                      {item['relationship'] || ''}
                                    </p>
                                  </td>
                                  <td colSpan={2}>

                                    <p style={{ fontSize: 13 }}>
                                      {item["poi"]}
                                    </p>

                                  </td>
                                  <td colSpan={2}>
                                    <p style={{ fontSize: 13 }}>
                                      {item["id_information"]['id_no']}
                                    </p>
                                  </td>
                                  <td colSpan={2}>
                                    <label>
                                      <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                        <Form.Check type="checkbox"
                                          // label="Choose"
                                          onChange={e => { this.handleNomineeCheck(e, item) }}
                                          defaultChecked={item['is_selected']}
                                        />
                                      </Form.Group>
                                    </label>
                                  </td>
                                </tr>
                                {item['is_selected'] && item['nominee_form'].length > 77 &&
                                  <tr>
                                    <td colSpan={10}>
                                      <PDFReader
                                        data={atob(
                                          item['nominee_form']
                                        )}
                                        width="800"
                                      />
                                    </td>
                                  </tr>
                                }
                              </tbody>
                            ))}

                          </table>
                        </div>
                      }

                    </div>
                    {/* </div> */}
                    <hr />

                    {
                      (
                        (this.state.nominee_option === 'existing' && this.state.existing_nominee_selected) ||
                        (this.state.nominee_option === 'none' && this.state.is_no_nominee_registered)
                      ) &&
                      <div>

                        <div>
                          <p >
                            {this.state.nach_option ===
                              "existing" && (this.state.list_mandate || []).length === 0 &&
                              <span style={{
                                width: 400,
                                fontSize: 12,
                                color: 'InfoText',
                                backgroundColor: '#d9b998', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                              }}>
                                Your mandate list is empty. Kindly register new mandate.
                              </span>
                            }
                          </p>

                          <div className="form">
                            {
                              <Form.Group as={Row} className="mb-3">
                                <Col sm={12}>

                                  {this.state.is_nach && (
                                    <div class="row">
                                      <div class="col-4">
                                        <Form.Check
                                          type="radio"
                                          label="Choose existing mandate"
                                          name="nach_option"
                                          id="existing"
                                          value="existing"
                                          checked={
                                            this.state.nach_option ===
                                            "existing"
                                          }
                                          onChange={(e) =>
                                            this.handleRadioChange(
                                              "existing",
                                              "nach_option"
                                            )
                                          }
                                        />
                                      </div>
                                      <div class="col-4">
                                        <Form.Check
                                          type="radio"
                                          label="Register new mandate"
                                          name="nach_option"
                                          id="new"
                                          value="new"
                                          checked={
                                            this.state.nach_option === "new"
                                          }
                                          onChange={(e) =>
                                            this.handleRadioChange(
                                              "new",
                                              "nach_option"
                                            )
                                          }
                                          disabled={!this.state.is_register_mandate_allowed}
                                        />
                                      </div>
                                      <div>
                                        <label></label>
                                      </div>
                                      {this.state.nach_option ===
                                        "existing" && (this.state.list_mandate || []).length > 0 &&
                                        (
                                          <table class="table mb-0 table-responsive-sm bordered">
                                            <thead>
                                              <tr>
                                                <th colSpan="1" style={{ fontSize: 12 }}>#</th>
                                                <th colSpan="6" style={{ fontSize: 12 }}>Mandate Id</th>
                                                <th colSpan="3" style={{ fontSize: 12 }}>Debit Limit</th>
                                                <th colSpan="3" style={{ fontSize: 12 }}>Bank Info</th>
                                                <th colSpan="3" style={{ fontSize: 12 }}>Validity</th>
                                                <th colSpan="3" style={{ fontSize: 12 }}>Select</th>
                                                <th colSpan="6" style={{ fontSize: 12 }}>Status</th>
                                              </tr>
                                            </thead>
                                            {(
                                              this.state.list_mandate || []
                                            ).map((item, index) => (
                                              <tbody key={index}>
                                                <tr>
                                                  <td colSpan="1" style={{ fontSize: 12, color: "#000" }}>{index + 1}</td>
                                                  <td colSpan="6" style={{ fontSize: 12, color: "#000" }}>
                                                    {item["mandate_id"]}
                                                    {
                                                      item['is_reupload'] &&
                                                      <p>

                                                        <Button className="text-success"
                                                          variant="link"
                                                          style={{ marginLeft: -30 }}
                                                          onClick={() => {
                                                            this.setState({
                                                              show_upload_popup: true,
                                                              modal_upload_mandate: item
                                                            })
                                                            // window.scrollTo(0, document.body.scrollHeight - 300);
                                                          }}>
                                                          Re-Upload </Button>
                                                      </p>
                                                    }
                                                    {/* <p>
                                                      {`type: `} {item["mandate_type"] || ``}
                                                    </p> */}
                                                  </td>
                                                  <td colSpan="3" style={{ fontSize: 12, color: "#000" }}>
                                                    Maximum :
                                                    <span style={{ fontFamily: 'sans-serif' }}>
                                                      {`   `}&#8377;
                                                    </span>{item["amount"] || `-`}
                                                    <p>
                                                      Available{`  : `}
                                                      <span style={{ fontFamily: 'sans-serif' }}>
                                                        {`   `}&#8377;
                                                      </span>{item["amount"] - item["limit_used"]}
                                                    </p>
                                                  </td>
                                                  <td colSpan="3" style={{ fontSize: 12, color: "#000" }}>
                                                    {item["account_no"] || `-`} ({item["account_type"] || `-`})
                                                    <p>
                                                      {item["ifsc_code"] || `-`}
                                                    </p>
                                                  </td>
                                                  <td colSpan="3" style={{ fontSize: 12, color: "#000" }}>
                                                    {item["start_date"]} - {item["end_date"]}
                                                  </td>
                                                  <td colSpan="3" style={{ fontSize: 12, color: "#000" }}>
                                                    {(
                                                      <label>
                                                        <Form.Group
                                                          className="mb-3"
                                                          controlId="formBasicCheckbox"
                                                        >
                                                          <Form.Check
                                                            type="checkbox"
                                                            label=""
                                                            checked={
                                                              item[
                                                              "is_selected"
                                                              ]
                                                            }
                                                            onChange={(
                                                              e
                                                            ) => {
                                                              this.handleMandateCheck(
                                                                e,
                                                                item
                                                              );
                                                            }}
                                                            defaultChecked={
                                                              item[
                                                              "is_primary"
                                                              ]
                                                            }
                                                            disabled={item['is_cancelled'] || !item['is_processed'] || !item['is_active']}
                                                          />
                                                        </Form.Group>
                                                      </label>
                                                    )}
                                                  </td>
                                                  <td colSpan="6" style={{ fontSize: 12, color: "#000" }}>
                                                    <span>
                                                      {item["status"] ||
                                                        "Under process for bank approval"}
                                                    </span>
                                                    {!item[
                                                      "is_cancelled"
                                                    ] && !item[
                                                    "is_processed"
                                                    ] && (
                                                        <div>
                                                          {item[
                                                            "mandate_id"
                                                          ] && (
                                                              <Button
                                                                className="btn btn-success btn-sm"
                                                                variant="success"
                                                                onClick={(e) =>
                                                                  this.refreshMandateStatus(
                                                                    item[
                                                                    "mandate_id"
                                                                    ]
                                                                  )
                                                                }
                                                                disabled={this.state.isloading}
                                                              >
                                                                Refresh
                                                              </Button>
                                                            )}
                                                        </div>
                                                      )}
                                                  </td>

                                                </tr>
                                              </tbody>
                                            ))}
                                          </table>
                                        )}
                                      {/* {this.state.nach_option ===
                                        "existing" && (this.state.list_mandate || []).length === 0 &&
                                        <p style={{
                                          width: 400,
                                          fontSize: 12,
                                          color: 'InfoText',
                                          backgroundColor: '#d9b998', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                        }}>
                                          Your mandate list is empty. Kindly register new mandate.
                                        </p>
                                      } */}
                                      {(this.state.nach_option === "new" ||
                                        this.state.nach_option ===
                                        "existing") &&
                                        this.state.sip_order[
                                        "sip_frequency"
                                        ] &&
                                        (!this.state.is_mandate_selected ||
                                          this.state.nach_option ===
                                          "new") && (
                                          <div class="row">
                                            {
                                              //this.state.is_mandate_registered &&
                                              <div>

                                                {/* <div class="row">
                                                  <div class="col-10">
                                                    <span style={{
                                                      width: 400,
                                                      fontSize: 12,
                                                      color: 'InfoText',
                                                      backgroundColor: '#a8c3ed', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                                    }}>
                                                      Frequency: {this.state.sip_order["sip_frequency"]}
                                                    </span>
                                                    {`  `}
                                                    <span style={{ backgroundColor: '#dbcec5', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                                                      SIP amount will be debited from your primary account on
                                                      <b>{' '}{this.fnDateOrdinalformat(this.state.sip_order['sip_date']).split(' ')[0]}</b>
                                                      {` of every `}
                                                      {this.state.sip_order['sip_frequency'].toLowerCase().replace('ly', '')}
                                                      {'.'}
                                                    </span>

                                                  </div>

                                                </div>
                                                <div>
                                                  <label></label>
                                                </div> */}

                                                <div>
                                                  {this.state
                                                    .nach_option ===
                                                    "new" && (
                                                      <div class="row">
                                                        <div class="col-3">
                                                          {" "}
                                                          Debit Amount Limit
                                                          <Select
                                                            value={this.state.list_mandate_debit.find(
                                                              (obj) =>
                                                                obj.value.toString() ===
                                                                this.state.mandate_amount.toString()
                                                            )}
                                                            onChange={(e) =>
                                                              this.setState({
                                                                mandate_amount:
                                                                  e.value,
                                                              })
                                                            }
                                                            options={
                                                              this.state
                                                                .list_mandate_debit
                                                            }
                                                            isDisabled={
                                                              this.state
                                                                .is_mandate_registered
                                                            }
                                                          />
                                                        </div>
                                                        <div class="col-3">
                                                          <br />
                                                          {this.state.list_pending_mandate.filter(
                                                            (obj) => {
                                                              return (
                                                                obj.is_selected ===
                                                                true
                                                              );
                                                            }
                                                          ).length === 0 &&
                                                            this.state
                                                              .nach_option ===
                                                            "new" && (
                                                              <Button
                                                                className="btn btn-success btn-block"
                                                                variant="success"
                                                                disabled={
                                                                  (this.state
                                                                    .mandate_date_validation[
                                                                    "message_text"
                                                                  ].length > 15 &&
                                                                    !this.state
                                                                      .is_first_order_today) ||
                                                                  this.state.isloading
                                                                }
                                                                onClick={
                                                                  this
                                                                    .onRegisterMandate
                                                                }
                                                              >
                                                                Register Mandate
                                                              </Button>
                                                            )}
                                                        </div>
                                                      </div>
                                                    )}
                                                  {this.state
                                                    .nach_option ===
                                                    "new" &&
                                                    this.state
                                                      .list_pending_mandate
                                                      .length > 0 && (
                                                      <div class="col-4">
                                                        <div>
                                                          {" "}
                                                          Choose Mandate Id
                                                          <Select
                                                            value={this.state.list_pending_mandate.find(
                                                              (obj) =>
                                                                obj.is_selected ===
                                                                true
                                                            )}
                                                            onChange={(e) =>
                                                              this.handleMandateIdSelect(
                                                                e
                                                              )
                                                            }
                                                            options={
                                                              this.state
                                                                .list_pending_mandate
                                                            }
                                                            isDisabled={
                                                              this.state
                                                                .nach_option_new_processed
                                                            }
                                                            isSearchable={
                                                              false
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    )}
                                                </div>

                                                {this.state
                                                  .nach_option ===
                                                  "new" &&
                                                  <div>
                                                    <div class="col">
                                                      <h6>
                                                        {/*  */}
                                                        <p></p>
                                                        Total amount limit is
                                                        not actual debit
                                                        amount and it is upper
                                                        limit where multiple
                                                        debit transactions
                                                        done from different
                                                        sip orders. You are
                                                        suggested to keep a
                                                        higher Mandate amount
                                                        so that with it any
                                                        amount of transactions
                                                        can be placed easily,
                                                        without the need for
                                                        registering a new
                                                        mandate/increasing
                                                        mandate limit You can
                                                        use same mandate id
                                                        for your future sip
                                                        investments
                                                      </h6>
                                                    </div>
                                                  </div>
                                                }

                                                <div class="row">
                                                  <div class="col">
                                                    <Form.Group
                                                      className="mb-3"
                                                      controlId="formBasicCheckbox"
                                                    >
                                                      {this.state
                                                        .mandate_date_validation[
                                                        "message_text"
                                                      ].length > 15 && (
                                                          <div>
                                                            <p
                                                              style={{
                                                                color: "red",
                                                              }}
                                                            >
                                                              {
                                                                this.state
                                                                  .mandate_date_validation[
                                                                "message_text"
                                                                ]
                                                              }
                                                            </p>

                                                            <Form.Check
                                                              type="checkbox"
                                                              label="Is First Order Today"
                                                              onChange={(e) =>
                                                                this.setState(
                                                                  {
                                                                    is_first_order_today:
                                                                      !this
                                                                        .state
                                                                        .is_first_order_today,
                                                                  }
                                                                )
                                                              }
                                                              defaultChecked={
                                                                this.state
                                                                  .is_first_order_today
                                                              }
                                                              disabled={true}
                                                            />
                                                          </div>
                                                        )}
                                                    </Form.Group>

                                                  </div>
                                                </div>
                                              </div>
                                            }

                                            {this.state
                                              .is_mandate_registered &&
                                              !this.state
                                                .nach_option_new_processed && (
                                                <div class="col">

                                                  <hr />
                                                  <div class="row">

                                                    <div class="col-6">
                                                      <div className="text-center">
                                                        {
                                                          this.state
                                                            .src_nach_form
                                                            .length > 255 && (
                                                            <PDFReader
                                                              data={atob(
                                                                this.state
                                                                  .src_nach_form
                                                              )}
                                                              width="450"
                                                            />
                                                          )
                                                          // <PDFViewer document={{ base64: this.state.src_nach_form }} />
                                                        }

                                                      </div>
                                                    </div>
                                                    <div class="col-6">

                                                      <div class="row">

                                                        <div class="col-5">
                                                          <Button
                                                            className="btn btn-success btn-block"
                                                            variant="success"
                                                            onClick={() =>
                                                              this.downloadNachForm()
                                                            }
                                                          >
                                                            Download Mandate
                                                          </Button>
                                                        </div>
                                                        <div class="col-1">
                                                        </div>
                                                        <div class="col-5">
                                                          <Button
                                                            className="btn btn-success btn-block"
                                                            variant="success"
                                                            onClick={(e) =>
                                                              this.onCancelMandate(
                                                                this.state
                                                                  .list_pending_mandate[0][
                                                                "mandate_id"
                                                                ]
                                                              )
                                                            }
                                                            disabled={this.state.isloading}
                                                          >
                                                            Cancel Mandate
                                                          </Button>
                                                        </div>

                                                      </div>

                                                      <div class="row">

                                                        <p
                                                          style={{
                                                            textAlign: "left",
                                                          }}
                                                        >
                                                          <b>Step 1</b>. Download <b>Step 2</b>. Put your signature <b>Step 3</b>. Scan it(png/jpeg format)

                                                        </p>
                                                        <p
                                                          style={{
                                                            textAlign: "left",
                                                          }}>
                                                          Upload without changing the file name to complete the
                                                          registration.
                                                        </p>
                                                        <p
                                                          style={{
                                                            textAlign: "left",
                                                          }}
                                                        >
                                                          For eg. file name
                                                          should be strictly
                                                          as
                                                          <span style={{ fontWeight: 'bold', color: 'darkgreen' }}>{ //this.state.selected_mandate_id +
                                                            ` ` + this.state
                                                              .list_pending_mandate[0]["mandate_id"]
                                                            + `.png`}</span>
                                                          {` `}
                                                          or
                                                          <span style={{ fontWeight: 'bold', color: 'darkgreen' }}>
                                                            {` ` + this.state
                                                              .list_pending_mandate[0]["mandate_id"] +
                                                              ".jpeg"}{` `}
                                                          </span>
                                                        </p>
                                                        <Form.Control
                                                          type="file"
                                                          onChange={
                                                            this.changeHandler
                                                          }
                                                        />

                                                      </div>

                                                      {this.state
                                                        .src_nachmandateFile && (
                                                          <div class="row">
                                                            <div class="col-4">
                                                              <img
                                                                width="150"
                                                                src={
                                                                  this.state[
                                                                  "src_nachmandateFile"
                                                                  ]
                                                                }
                                                              />
                                                            </div>
                                                            <div class="col-4">
                                                              <br />
                                                              <Button
                                                                className="btn btn-success btn-sm"
                                                                variant="success"
                                                                onClick={() => {
                                                                  this.onUploadMandate('main');
                                                                }
                                                                }
                                                                disabled={this.state.isloading}
                                                              >
                                                                Upload Mandate
                                                              </Button>
                                                            </div>

                                                          </div>
                                                        )}

                                                      {this.state
                                                        .show_popup && (
                                                          <Modal
                                                            show={
                                                              this.state
                                                                .show_popup
                                                            }
                                                            onHide={() =>
                                                              this.closePopup()
                                                            }
                                                          >
                                                            <Modal.Header></Modal.Header>
                                                            <Modal.Body>
                                                              <p>
                                                                {
                                                                  this.state.popup_messageText
                                                                }
                                                              </p>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                              <Button
                                                                variant="primary"
                                                                onClick={() =>
                                                                  this.closePopup()
                                                                }
                                                              >
                                                                Close
                                                              </Button>
                                                            </Modal.Footer>
                                                          </Modal>
                                                        )}
                                                    </div>



                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                        )}
                                    </div>
                                  )}
                                </Col>
                              </Form.Group>
                            }
                          </div>

                        </div>

                        {this.state.render_pdf !== null && (
                          <PDFReader
                            data={this.state.render_pdf}
                            width="100px"
                            height="100px"
                          />
                        )}

                        {((this.state.nach_option === "existing" &&
                          this.state.is_mandate_selected) ||
                          (this.state.nach_option === "new" &&
                            this.state.nach_option_new_processed)) && (
                            <div class="row" style={{ margin: 20 }}>
                              <div class="col-lg-12">
                                {/* {this.state.nach_option === "new" && ( */}
                                <h5 className="card-title">
                                  {
                                    this.state
                                      .nach_option_new_processed_message_text
                                  }
                                </h5>
                                {/* )} */}

                                

                                <div class="row">
                                  <div class="col-7" style={{ marginTop: 12 }}>
                                    <span style={{ backgroundColor: '#dbcec5', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                                      SIP amount of
                                      <span style={{ fontFamily: 'sans-serif' }}>{`   `}&#8377;</span>
                                      {this.state.sip_order["sip_amount"]} will be debited from primary account on
                                      <b>{' '}
                                        {moment(new Date('1982-11-' + this.state.sip_order['sip_date'])).format('Do')}
                                      </b>
                                      {` of every `}
                                      {this.state.sip_order['sip_frequency'].toLowerCase().replace('ly', '')}
                                    </span>
                                  </div>
                                  {!this.state.is_otp_enabled &&
                                    <div class="col-4">
                                      <Button
                                        className="btn btn-success btn-block"
                                        variant="success"
                                        onClick={() => {
                                          this.sendOTP();
                                        }}
                                        disabled={
                                          (this.state.mandate_date_validation[
                                            "message_text"
                                          ].length > 15 &&
                                            !this.state.is_first_order_today) ||
                                          this.state.isloading
                                        }
                                      >
                                        Create Sip Order
                                      </Button>
                                    </div>
                                  }
                                </div>

                                {this.state.is_otp_enabled &&

                                  <div>
                                    <hr/>
                                    <div class="row">
                                      <div class="col">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                          <Form.Label>Enter OTP received on your registered mobile</Form.Label>
                                          <OTPInput
                                            value={this.state.two_factor_authentication['mobile_otp']}
                                            onChange={this.handle2FactorChange('mobile_otp', 'two_factor_authentication')}
                                            autoFocus
                                            OTPLength={6}
                                            otpType="number"
                                            secure
                                            isInputNum={true}
                                          />
                                        </Form.Group>
                                      </div>
                                      <div class="col">
                                        <br/>
                                        <button
                                          className="btn btn-success btn-sm"
                                          onClick={() => {
                                            this.onCreateSipOrder();
                                          }}
                                          disabled={
                                            (this.state.mandate_date_validation[
                                              "message_text"
                                            ].length > 15 &&
                                              !this.state.is_first_order_today) ||
                                            this.state.isloading
                                          }
                                        >
                                          Confirm Sip Order
                                        </button>
                                      </div>
                                    </div>
                                    {/* <div class="row"> */}
                                      <ResendOTP maxTime={120} renderButton={renderButton} style={{ color: 'black', marginTop: 20 }} renderTime={renderTime}
                                        onResendClick={(e) => { this.sendOTP() }}
                                      />
                                    {/* </div> */}

                                  </div>
                                  
                                }
                              </div>
                            </div>
                          )}

                        {this.state.is_nach === false && (
                          <div class="row">
                            <p>
                              {" "}
                              {this.state.sip_information_text}{" "}
                              {this.state.sip_order["sip_date"].toISOString()}{" "}
                              of every{" "}
                              {this.state.sip_order[
                                "sip_frequency"
                              ].toLowerCase()}
                            </p>

                            <Button
                              className="btn btn-success btn-block"
                              variant="success"
                              onClick={() => {
                                this.onCreateSipOrder();
                              }}
                              disabled={this.state.isloading}
                            >
                              Confirm Sip Order
                            </Button>
                          </div>
                        )}

                      </div>
                    }

                  </div>

                  <Modal
                    show={
                      this.state
                        .show_upload_popup
                    }
                    onHide={() => {
                      this.setState({
                        modal_upload_mandate: {},
                        show_upload_popup: false
                      });
                    }
                    }
                  >
                    <Modal.Header></Modal.Header>
                    <Modal.Body>
                      {
                        this.state
                          .modal_upload_mandate['mandate_id'] &&
                        <div class="row">

                          <p
                            style={{
                              textAlign: "left",
                            }}
                          >
                            <b>Step 1</b>. Download Mandate
                            <br />
                            <b>Step 2</b>. Put your signature
                            <br />
                            <b>Step 3</b>. Scan it(png/jpeg format)

                          </p>
                          <p
                            style={{
                              textAlign: "left",
                            }}>
                            Upload without changing the file name to complete the
                            registration.
                          </p>
                          <p
                            style={{
                              textAlign: "left",
                            }}
                          >
                            For eg. file name
                            should be strictly
                            as
                            <span style={{ fontWeight: 'bold', color: 'darkgreen' }}>{ //this.state.selected_mandate_id +
                              ` ` + this.state.modal_upload_mandate['mandate_id'] + `.png`}</span>
                            {` `}
                            or
                            <span style={{ fontWeight: 'bold', color: 'darkgreen' }}>
                              {` ` + this.state.modal_upload_mandate['mandate_id'] + ".jpeg"}{` `}
                            </span>
                          </p>
                          <Form.Control
                            type="file"
                            onChange={
                              this.changeHandlerModal
                            }
                          />

                        </div>
                      }

                      {this.state
                        .src_modal_nachmandateFile && (
                          <div class="row">
                            <div class="col-5">
                              <img
                                width="150"
                                src={
                                  this.state[
                                  "src_modal_nachmandateFile"
                                  ]
                                }
                              />
                            </div>
                            <div class="col-5">
                              <br />
                              <Button
                                className="btn btn-success btn-sm"
                                variant="success"
                                onClick={() => {
                                  this.onUploadMandate('modal');
                                }
                                }
                                disabled={this.state.isloading}
                              >
                                Upload Mandate
                              </Button>
                            </div>

                          </div>
                        )}

                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        onClick={() => {
                          this.setState({
                            modal_upload_mandate: {},
                            show_upload_popup: false
                          });
                        }
                        }
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>

                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
// OrderCheckout.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

/*
const mapStateToProps = state => ({
    loading: state.order.loading,
    order_result: state.order.order_result
});
*/

const mapStateToProps = ({ order }) => {
  const { loading, order_result } = order;
  return {
    loading,
    order_result,
  };
};

export default connect(mapStateToProps, {
  createSipOrder,
  uploadMandate,
  registerMandate,
  loadBankAccountList,
  loadNachMandateForm,
  loadNomineeList,
  refreshMandateStatus,
  cancelMandate,
  twoFactorAuthentication,
})(SipCheckout);
