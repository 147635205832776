import React, {  } from 'react';
import { Link } from 'react-router-dom';
import {Button,Spinner} from 'react-bootstrap';
import Otp2 from './otp-2';
import { validateOtp } from '../../jsx/actions/auth';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import Background from '../../images/background/bg-14.jpg';

class Otp1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: '',
            password: '',
            data:'',
            isloading:this.props.loading
        };

    } 

    onSubmit = () => {
        const {  phoneNumber } = this.state;
 
 

//Uncomment the line of the code

this.setState({data:phoneNumber});
this.setState({isloading:true})
if(this.state.phoneNumber.length < 10){
    alert("Enter a valid mobile number");
}

        let body_parameters = {
            "login_mobile_number": phoneNumber,
            "login_email": "",
            "login_role": "customer",
            "login_hint": ""

        }

        this.props.validateOtp(body_parameters);
        this.setState({isloading:false})
        
    };



    render(){
    return (
        <>
        <div style={{
              background: `url(${Background})`,
              backgroundRepeat: 'repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              
            }}>
            <div className="authincation section-padding">
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                {this.state.isloading &&
       <Spinner animation="border" variant="success" 
      style={{ position: "fixed", top: "50%", left: "50%" }}/>
    }
                    <div className="col-xl-5 col-md-6">
                        <div className="mini-logo text-center my-5">
                            <Link to={'./'}><img src={require('./../../images/logo.png')} alt="" width="200px" /></Link>
                        </div>
                       
                        <div className="auth-form card">
                            <div className="card-body">
                                <Link className="page-back text-muted" to={'./signin'}>
                                    <span><i className="fa fa-angle-left"></i></span> Back</Link>
                                <h3 className="text-center">Enter Phone Number</h3>
                                <p className="text-center mb-5">We will send one time code on this number</p>
                                <form action="#">
                                    <div className="form-group">
                                        <label>Your phone number</label>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text pl-4 pr-4"><i
                                                        className="fa fa-phone"></i></span>
                                            </div>
                                            <input type="phonenumber" className="form-control" placeholder=""
                                                name="phonenumber" value={this.state.phoneNumber}
                                                onChange={event => this.setState({ phoneNumber: event.target.value })} />
                                        </div>
                                    </div>
                                    <div className="text-center mt-4">
                                    <Link to={'./otp-2'} type="submit" className="btn btn-success btn-block">Verify</Link>
                                    <Button
                                                className="btn btn-success btn-block"
                                                variant="success"
                                                onClick={this.onSubmit}

                                            >OTP Verification</Button>
                                    </div>
                                </form>
                                <div className="new-account mt-3 d-flex justify-content-between">
                                    <p>Don't get code? <Link  className="btn btn-success btn-block" to={'./otp-1'}>Resend</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </>
    )
}
}
Otp1.propTypes = {
  
    isAuthenticated: PropTypes.bool,
    phoneNumber:PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    phoneNumber:state.phoneNumber
});

export default connect(mapStateToProps, { validateOtp })(Otp1);

