import React, { } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {AppConstant,API_END_POINTS } from '../../constants/constants';

class AccountActivation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            activation_id :''
        };

    }
    componentDidMount() {
       console.log(this.props.match.params["id"])
       const url_params = this.props.match.params["id"]
        // Simple POST request with a JSON body using fetch
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                        'bb-access-token' : url_params }
        };
        fetch('https://smart-fish-79.loca.lt/user/verifyaccount',requestOptions)
            .then(response => response.json())
            .then(data => this.setState({ activation_id:url_params }));
    }
    render(){
    return (
        <>
<div className="terms_condition">
            <div className="container">
            <div className="mini-logo text-center my-5">
                                    <Link to={'./'}><img src={require('./../../../images/logo.png')} alt="" /></Link>
                                </div>
                <div className="row justify-content-center">
                
                    <div className="col-lg-9">
                        <div className="terms_condition-content">
                            <div className="terms_condition-text">
                                <h3>Account Activation Status: </h3>
                                <p>Your Account activated succesfully
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

           
        </>
    )
}
}
export default AccountActivation;