import React, { TouchableOpacity} from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router";
import { Navbar, Nav ,DropdownButton,Button,Dropdown,thumb} from 'react-bootstrap';
import Emitter from '../utils/emitter';
import { connect } from "react-redux";

class Header1 extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        masterList: JSON.parse(localStorage.getItem("profileData") || JSON.stringify({}))
      };

    }
  
    componentDidMount() {

        Emitter.once('HEADER1', (data) => {
            console.log(data);
            this.setState({ masterList: typeof data === 'string' ? JSON.parse(data) : data});
        });

    }
    
    onLogout = () => {

        localStorage.removeItem('token'); 
        localStorage.removeItem('profileData'); 
        localStorage.removeItem('masterList'); 
        // masterList = {};
        this.props.history.push({ 
            pathname: '/signin'
        });

        // history.push("/signin")
    };

    // function Header2(props) {

    render() 
    {
        
    return (
        <>
<div className="header landing_page" style={{boxShadow:0}}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="navigation">
                                <Navbar expand="lg">
                                    <Link className="navbar-brand" to={'/schemeList'}>
                                        <img src={require('./../../images/logo.png')} alt="" width="115px"/>
                                       
                                    </Link>
                                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                    <Navbar.Collapse>
                                    {/* <Nav.Item><Nav.Link className="nav-link" href="#/home">Home</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link className="nav-link" href="#/sipList">SIP</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link className="nav-link" href="#/orderList">Order</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link className="nav-link" href="#demo">Help and Support</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link className="nav-link" href="#/accounts">Accounts</Nav.Link></Nav.Item> */}
                                           
                                    <div className="header_auth">
            
                                    </div>
                                    </Navbar.Collapse>
                                    <div className="header-right">
                                        {this.state.masterList && 
                                            this.state.masterList['login_mobile_number'] && 
                                            <DropdownButton
                                                alignRight
                                                title={(this.state.masterList["login_mobile_number"] || '').length > 7 ? 
                                                    this.state.masterList["login_mobile_number"] : this.state.masterList["login_email"]}
                                                variant="success"
                                                className="profile_log"
                                            >
                                                <Link to={'/accounts'} className="dropdown-item">
                                                    <i className="la la-user"></i> My KYC
                                                </Link>
                                                <Link to={'/mpinInformation'} className="dropdown-item">
                                                    <i className="la la-book"></i> mPin
                                                </Link>
                                                {/* <Link to={'/settings'} className="dropdown-item">
                                                    <i className="la la-cog"></i> Setting
                                                </Link>
                                                <Link to={'/lock'} className="dropdown-item">
                                                    <i className="la la-lock"></i> Lock
                                                </Link> */}
                                                <Link to='/signin' className="dropdown-item logout">
                                                    <i className="la la-sign-out"></i> Logout
                                                    

                                                </Link>
                                                
                                            </DropdownButton>
                                        }
                                  </div>
                                </Navbar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

}
// export default Header2;

const mapStateToProps = (state) => ({
// isloading: state.master.loading,
});

export default connect(mapStateToProps, { })(Header1);