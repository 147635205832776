
import React from "react";
import { Link } from "react-router-dom";
import { Form, Alert, Modal, Button, Spinner, Row, Col, Container, } from "react-bootstrap";
import { connect } from "react-redux";
import Select from 'react-select';
import {
  loadPortfolio,
  loadPaymentResponse,
  cancelOrder,
  createSwpOrder,
  twoFactorAuthentication,
  cancelSwpOrder
} from "../../actions/orders";
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
//import Spinner from "../../layout/spinner";
import { TabPanel, a11yProps } from "../../shared/Tabs";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Table, Collapse } from "react-bootstrap";
import { Box, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import OTPInput, { ResendOTP } from "otp-input-react";

class PortfolioInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab_orders: 0,
      tab_single_order_child: 0,
      cardopen: false,

      is_portfolio_available: false,
      investment_information: {},
      list_single_orders: [],
      _list_single_orders: [],
      status_filter_list: [],
      status_filter_selected: null,

      swp_order: {
        display_text: '',
        swp_no_of_installments: '',
        swp_amount: '',
        swp_date: new Date(new Date().setDate(new Date().getDate() + 8)),
        swp_frequency: '',
        is_otp_enabled: false,
        two_factor_authentication: {
          mobile_otp: '',
          email_otp: '',
          twofactor_token_id: ''
        }
      },
      swp_minimum_date: new Date(new Date().setDate(new Date().getDate() + 8)),
      swp_maximum_date: new Date().setDate(new Date().getDate() + 40),
      swp_exclude_date_list: [],

      bank_information: {},
      is_verify_enabled: false,
      is_confirm_chk_enabled: false,
      is_verified_editable: false,
      esignkyc_information: {},
      esignkyc_attachment_list: {},
      is_esign_verified: false,
      is_esign_button_disabled: false,
      isloading: false,
      show_popup: false,
      popup_headerText: '',
      popup_messageText: '',

      show_swp_modal: false,
      show_view_modal: false,
      modal_orderinfo: {},
      modal_header_text: '',
      show_modal_filter: false,

      show_modal_pin_confirm_dialog: false,
      modal_pin_confirm_action: '',
      modal_pin_confirm_value: '',
      modal_pin_confirm_dialog_messagetext: 'Enter your 4 digit pin to complete your action'

    };
  }

  componentDidMount() {

    window.addEventListener("scroll", () => {

      if (document.getElementById("navbar-container") &&
        document.getElementById("navbar") &&
        document.getElementById("navbar").classList) {
        const topBorder = document
          .getElementById("navbar-container")
          .getBoundingClientRect().top;

        topBorder >= 0
          ? document.getElementById("navbar").classList.remove("fixed")
          : document.getElementById("navbar").classList.add("fixed");
      }

    });

    this.setState({ isloading: true });
    this.onLoadPortfolio(0);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', null);
  }

  handleConfirmCheck = (event) => {
    this.setState({
      is_confirm_chk_checked: !this.state.is_confirm_chk_checked,
    });
  };

  onLoadPortfolio = (order_type) => {

    order_type = order_type === 0 ? "single" : 0; //(order_type === 1 ? 'sip' : 'xsip')

    this.props
      .loadPortfolio({

      })
      .then((response) => {
        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);
        else {

          if (this.props.order_result["result"]) {
              this.setState({
                is_portfolio_available: this.props.order_result['is_portfolio_available'],
                investment_information: this.props.order_result["result"],
                isloading: false
              });

          }
          else
            this.setState({
              investment_information: {},
              isloading: false
            });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  gotoCheckOutPage(request_mode, selected_order) {

    this.setState({ isloading: true });

    if ((selected_order["mf_order_action_list"] || []).length > 0) {

      selected_order["order_information"] =
        selected_order['mf_order_action_list'][selected_order["mf_order_action_list"].length - 1];
    }

    selected_order["bank_information"] = this.state.bank_information;
    selected_order["request_mode"] = request_mode;
    this.setState({ isloading: false });

    /*
    if(request_mode === 'paynow') {
        const win = window.open("/orderEditCheckout/" + request_mode.toLowerCase(), "_blank");
        win.focus();
    }
    else*/
    this.props.history.push({
      pathname: "/orderEditCheckout/",
      state: {
        request_mode: request_mode,
        selected_order: selected_order,
      },
    });


  }

  routeChange = (type, item, mode) => {

    /*if((window.matchMedia && window.matchMedia("(max-width: 480px)").matches) ){
        this.props.history.push({ 
          pathname: "/chartsmobile", 
          state: { param_data: {
              scheme_isin: item['scheme_isin'],
              scheme_code: item['scheme_code'], 
              scheme_name: item['scheme_name'] 
            }
          }
        });
    }
    else */
    console.log(item)
    {

      const win = window.open("/sipConsolidatedStatement/" + item['mf_order_registration_no'] + '/' + item['mf_order_number'] + '/' + item['mf_order_type'].toLowerCase(), "_blank");
      win.focus();

    }

  };

  showTransactionHistory(item) {

    let _html = `<div>we will show you soon</div>`;

    this.setState({
      show_popup: true,
      popup_headerText: 'Transaction History',
      popup_messageText: item['modal_transaction_history_html'] || _html,
    })

  }

  createDefaultMarkup(text) {
    return {
      __html: text,
    };
  }

  render() {

    return (
      <>
        <Header1 />
        <Sidebar />
        
        <div id="navbar-container">
          <div id="navbar" >
            <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
              <Container >
                <Row >
                  <Col>
                    <div className="card-header">
                      <h5 >Your Portfolio (After allotment)</h5>
                    </div>
                  </Col>
                </Row>
              </Container>
              <hr />
            </div>
          </div>
        </div>

        <div className="content-body" style={{ marginTop: 25 }}>

          <div className="container">

            <div className="row">

              <div className="col-lg-12">

                <div className="card">

                  {/* <div className="card-body"> */}

                    {this.state.isloading && (
                      <Spinner
                        animation="border"
                        variant="dark"
                        style={{
                          position: "fixed",
                          top: "50%",
                          left: "50%",
                          zIndex: 899,
                        }}
                      />
                    )}

                    {this.state.isloading ?
                      <Alert variant="dark text-center">
                        <Alert.Heading>
                          We are fetching your portfolio
                        </Alert.Heading>
                      </Alert>
                      :
                      <div>
                        {this.state.is_portfolio_available && 
                          (this.state.investment_information || {})['overall_investment_amount'] &&
                          <div>

                            <div className="card">
                              <div className="card-body">
                                <div class="row">
                                  <div class="col">
                                    <h6>Overall Investment</h6>
                                  </div>
                                  <div class="col">
                                    <h6>Total Returns</h6>
                                  </div>
                                  <div class="col">
                                    <h6>
                                      Gain/Loss
                                    </h6>
                                  </div>
                                  <div class="col">
                                    <h6>
                                      Returns Percent
                                    </h6>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col">
                                    <h6>
                                      <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                      <span style={{ color: '#429bf5', marginLeft: 2 }}>
                                        {this.state.investment_information['overall_investment_amount']}
                                      </span>
                                    </h6>
                                  </div>
                                  <div class="col">
                                    <h6>
                                      <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                      <span style={{
                                        color:
                                          this.state.investment_information['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                            ""
                                            ? "#f57362"
                                            : "#03b45e", marginLeft: 2
                                      }}>
                                        {this.state.investment_information['total_returns_amount']}
                                      </span>
                                    </h6>
                                  </div>
                                  <div class="col">
                                    <h6>
                                      <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                      <span style={{
                                        color:
                                          this.state.investment_information['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                            ""
                                            ? "#f57362"
                                            : "#03b45e", marginLeft: 2
                                      }}>
                                        {this.state.investment_information['returns_difference_amount']}
                                      </span>
                                    </h6>
                                  </div>
                                  <div class="col">
                                    <h6>
                                      <span style={{
                                        color:
                                          this.state.investment_information['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                            ""
                                            ? "#f57362"
                                            : "#03b45e",
                                      }}>
                                        {this.state.investment_information['total_returns_percent']}%
                                      </span>
                                    </h6>
                                  </div>
                                </div>
                                <hr/>
                              </div>
                            </div>

                            <div class="row">

                              {this.state.investment_information['single_order_count'] &&
                                parseInt(this.state.investment_information['single_order_count']) > 0 &&
                                <div class="col">
                                  <div className="card">
                                    <div className="card-body">
                                      <div class="row">
                                        <div class="col">
                                          <p>
                                            <span style={{
                                              color: 'InfoText',
                                              backgroundColor: '#ec9fa4', fontWeight: 500,
                                              padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                            }}>
                                              Lumpsum
                                            </span>
                                            <span className="circleNumberCount"
                                              style={{
                                                marginLeft: '90px'
                                              }}>
                                              {this.state.investment_information['single_order_count']}
                                            </span>
                                          </p>
                                        </div>
                                        <div class="col">
                                          <button type="button"
                                            className="btn-sm btn-success"
                                            style={{ marginTop: -7, color: 'black', width: 60 }}
                                            onClick={() => {
                                              this.props.history.push({
                                                pathname: "/orderList/",
                                              });
                                            }}
                                          >

                                            <span className="icon"><i className="fa fa-arrow-right fa-lg"></i></span>
                                          </button>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col">
                                          <h6>Total Investment</h6>
                                        </div>
                                        <div class="col">
                                          <h6>Total Returns</h6>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col">
                                          <h6>
                                            <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                            <span style={{ color: '#429bf5', marginLeft: 2 }}>
                                              {this.state.investment_information['single']['overall_investment_amount']}
                                            </span>
                                          </h6>
                                        </div>
                                        <div class="col">
                                          <h6>
                                            <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                            <span style={{
                                              color:
                                                this.state.investment_information['single']['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                                  ""
                                                  ? "#f57362"
                                                  : "#03b45e", marginLeft: 2
                                            }}>
                                              {this.state.investment_information['single']['total_returns_amount']}
                                            </span>
                                          </h6>
                                        </div>
                                      </div>
                                      <hr />
                                      <div class="row">
                                        <div class="col">
                                          <h6>
                                            {
                                              this.state.investment_information['single']['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                                "" ? 'Loss' : 'Profit'
                                            }
                                          </h6>
                                        </div>
                                        <div class="col">
                                          <h6>
                                            <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                            <span style={{
                                              color:
                                                this.state.investment_information['single']['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                                  ""
                                                  ? "#f57362"
                                                  : "#03b45e", marginLeft: 2
                                            }}>
                                              {this.state.investment_information['single']['returns_difference_amount']}
                                            </span>
                                          </h6>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col">
                                          <h6>
                                            Returns Percent
                                          </h6>
                                        </div>                                        
                                        <div class="col">
                                          <h6>
                                            <span style={{
                                              color:
                                                this.state.investment_information['single']['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                                  ""
                                                  ? "#f57362"
                                                  : "#03b45e",
                                            }}>
                                              {this.state.investment_information['single']['total_returns_percent']}%
                                            </span>
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }

                              {this.state.investment_information['sip_order_count'] &&
                                parseInt(this.state.investment_information['sip_order_count']) > 0 &&
                                <div class="col">
                                  <div className="card">
                                    <div className="card-body">
                                      <div class="row">
                                        <div class="col">
                                          <p>
                                            <span style={{
                                              color: 'InfoText',
                                              backgroundColor: '#ec9fa4', fontWeight: 500,
                                              padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                            }}>
                                              SIP
                                            </span>
                                            <span className="circleNumberCount"
                                              style={{
                                                marginLeft: '45px'
                                              }}>
                                              {this.state.investment_information['sip_order_count']}
                                            </span>
                                          </p>
                                        </div>
                                        {/* <div class="col-3">
                                          <h6 className="circleNumberCount">
                                            {this.state.investment_information['sip_order_count']}
                                          </h6>
                                        </div> */}
                                        <div class="col">
                                          <button type="button"
                                            className="btn-sm btn-success"
                                            style={{ marginTop: -7, color: 'black', width: 60 }}
                                            onClick={() => {
                                              this.props.history.push({
                                                pathname: "/orderList/",
                                              });
                                            }}
                                          >

                                            <span className="icon"><i className="fa fa-arrow-right fa-lg"></i></span>
                                          </button>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col">
                                          <h6>Total Investment</h6>
                                        </div>
                                        <div class="col">
                                          <h6>Total Returns</h6>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col">
                                          <h6>
                                            <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                            <span style={{ color: '#429bf5', marginLeft: 2 }}>
                                              {this.state.investment_information['xsip']['overall_investment_amount']}
                                            </span>
                                          </h6>
                                        </div>
                                        <div class="col">
                                          <h6>
                                            <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                            <span style={{
                                              color:
                                                this.state.investment_information['xsip']['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                                  ""
                                                  ? "#f57362"
                                                  : "#03b45e", marginLeft: 2
                                            }}>
                                              {this.state.investment_information['xsip']['total_returns_amount']}
                                            </span>
                                          </h6>
                                        </div>
                                      </div>
                                      <hr />
                                      {/* <div class="row">
                                        <div class="col">
                                          <h6>
                                            {
                                              this.state.investment_information['xsip']['total_returns_amount'].substring(0, 1) === "-" ||
                                                "" ? 'Loss' : 'Profit'
                                            }
                                          </h6>
                                        </div>
                                        <div class="col">
                                          <h6>
                                            Returns Percent
                                          </h6>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col">
                                          <h6>
                                            <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                            <span style={{
                                              color:
                                                this.state.investment_information['xsip']['total_returns_amount'].substring(0, 1) === "-" ||
                                                  ""
                                                  ? "#f57362"
                                                  : "#03b45e", marginLeft: 2
                                            }}>
                                              {this.state.investment_information['xsip']['returns_difference_amount']}
                                            </span>
                                          </h6>
                                        </div>
                                        <div class="col">
                                          <h6>
                                            <span style={{
                                              color:
                                                this.state.investment_information['xsip']['total_returns_amount'].substring(0, 1) === "-" ||
                                                  ""
                                                  ? "#f57362"
                                                  : "#03b45e",
                                            }}>
                                              {this.state.investment_information['xsip']['total_returns_percent']}%
                                            </span>
                                          </h6>
                                        </div>
                                      </div> */}
                                      <div class="row">
                                        <div class="col">
                                          <h6>
                                            {
                                              this.state.investment_information['xsip']['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                                "" ? 'Loss' : 'Profit'
                                            }
                                          </h6>
                                        </div>
                                        <div class="col">
                                          <h6>
                                            <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                            <span style={{
                                              color:
                                                this.state.investment_information['xsip']['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                                  ""
                                                  ? "#f57362"
                                                  : "#03b45e", marginLeft: 2
                                            }}>
                                              {this.state.investment_information['xsip']['returns_difference_amount']}
                                            </span>
                                          </h6>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col">
                                          <h6>
                                            Returns Percent
                                          </h6>
                                        </div>
                                        <div class="col">
                                          <h6>
                                            <span style={{
                                              color:
                                                this.state.investment_information['xsip']['returns_difference_amount'].toString().substring(0, 1) === "-" ||
                                                  ""
                                                  ? "#f57362"
                                                  : "#03b45e", marginLeft: 2
                                            }}>
                                              {this.state.investment_information['xsip']['total_returns_percent']}%
                                            </span>
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }

                              {/* {this.state.investment_information['sip_order_count'] &&
                                parseInt(this.state.investment_information['sip_order_count']) > 0 &&
                                <div class="col">
                                  <div className="card">
                                    <div className="card-body">
                                      <div class="row">
                                        <div class="col">
                                          <span style={{
                                            color: 'InfoText',
                                            backgroundColor: '#ec9fa4', fontWeight: 500,
                                            padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                          }}>
                                            SIP
                                          </span>
                                          <span className="circleNumberCount"
                                            style={{
                                              marginLeft: '45px'
                                            }}>
                                            {this.state.investment_information['sip_order_count']}
                                          </span>
                                        </div>
                                        <div class="col">
                                          <button type="button"
                                            className="btn-sm btn-success"
                                            style={{ marginTop: -7, color: 'black', width: 60 }}
                                            onClick={() => {
                                              this.props.history.push({
                                                pathname: "/sipList/",
                                              });
                                            }}
                                          >
                                            <span className="icon"><i className="fa fa-arrow-right fa-lg"></i></span>
                                          </button>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col">
                                          <h6>Total Investment</h6>
                                        </div>
                                        <div class="col">
                                          <h6>
                                            <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                            <span style={{ color: '#429bf5', marginLeft: 2 }}>
                                              {this.state.investment_information['xsip']['overall_investment_amount']}
                                            </span>
                                          </h6>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col">
                                          <h6>Total Returns</h6>
                                        </div>
                                        <div class="col">
                                          <h6>
                                            <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                            <span style={{
                                              color:
                                                this.state.investment_information['xsip']['total_returns_amount'].substring(0, 1) === "-" ||
                                                  ""
                                                  ? "#f57362"
                                                  : "#03b45e", marginLeft: 2
                                            }}>
                                              {this.state.investment_information['xsip']['total_returns_amount']}
                                            </span>
                                          </h6>
                                        </div>
                                      </div>
                                      <hr />
                                      <div class="row">
                                        <div class="col">
                                          <h6>
                                            {
                                              this.state.investment_information['xsip']['total_returns_amount'].substring(0, 1) === "-" ||
                                                "" ? 'Loss' : 'Profit'
                                            }
                                          </h6>
                                        </div>
                                        <div class="col">
                                          <h6>
                                            <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                            <span style={{
                                              color:
                                                this.state.investment_information['xsip']['total_returns_amount'].substring(0, 1) === "-" ||
                                                  ""
                                                  ? "#f57362"
                                                  : "#03b45e", marginLeft: 2
                                            }}>
                                              {this.state.investment_information['xsip']['returns_difference_amount']}
                                            </span>
                                          </h6>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col">
                                          <h6>
                                            Returns Percent
                                          </h6>
                                        </div>
                                        <div class="col">
                                          <h6>
                                            <span style={{
                                              color:
                                                this.state.investment_information['xsip']['total_returns_amount'].substring(0, 1) === "-" ||
                                                  ""
                                                  ? "#f57362"
                                                  : "#03b45e", marginLeft: 2
                                            }}>
                                              {this.state.investment_information['xsip']['total_returns_percent']}%
                                            </span>
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              } */}

{this.state.investment_information['stp_order_count'] &&
                                parseInt(this.state.investment_information['stp_order_count']) > 0 &&
                                <div class="col">
                                  <div className="card">
                                    <div className="card-body">
                                      <div class="row">
                                        <div class="col">
                                          <p>
                                            <span style={{
                                              color: 'InfoText',
                                              backgroundColor: '#ec9fa4', fontWeight: 500,
                                              padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                            }}>
                                              SIP
                                            </span>
                                            <span className="circleNumberCount"
                                              style={{
                                                marginLeft: '45px'
                                              }}>
                                              {this.state.investment_information['stp_order_count']}
                                            </span>
                                          </p>
                                        </div>
                                        {/* <div class="col-3">
                                          <h6 className="circleNumberCount">
                                            {this.state.investment_information['stp_order_count']}
                                          </h6>
                                        </div> */}
                                        <div class="col">
                                          <button type="button"
                                            className="btn-sm btn-success"
                                            style={{ marginTop: -7, color: 'black', width: 60 }}
                                            onClick={() => {
                                              this.props.history.push({
                                                pathname: "/orderList/",
                                              });
                                            }}
                                          >

                                            <span className="icon"><i className="fa fa-arrow-right fa-lg"></i></span>
                                          </button>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col">
                                          <h6>Total Investment</h6>
                                        </div>
                                        <div class="col">
                                          <h6>Total Returns</h6>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col">
                                          <h6>
                                            <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                            <span style={{ color: '#429bf5', marginLeft: 2 }}>
                                              {this.state.investment_information['stp']['overall_investment_amount']}
                                            </span>
                                          </h6>
                                        </div>
                                        <div class="col">
                                          <h6>
                                            <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                            <span style={{
                                              color:
                                                this.state.investment_information['stp']['total_returns_amount'].toString().substring(0, 1) === "-" ||
                                                  ""
                                                  ? "#f57362"
                                                  : "#03b45e", marginLeft: 2
                                            }}>
                                              {this.state.investment_information['stp']['total_returns_amount']}
                                            </span>
                                          </h6>
                                        </div>
                                      </div>
                                      <hr />
                                      {/* <div class="row">
                                        <div class="col">
                                          <h6>
                                            {
                                              this.state.investment_information['stp']['total_returns_amount'].substring(0, 1) === "-" ||
                                                "" ? 'Loss' : 'Profit'
                                            }
                                          </h6>
                                        </div>
                                        <div class="col">
                                          <h6>
                                            Returns Percent
                                          </h6>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col">
                                          <h6>
                                            <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                            <span style={{
                                              color:
                                                this.state.investment_information['stp']['total_returns_amount'].substring(0, 1) === "-" ||
                                                  ""
                                                  ? "#f57362"
                                                  : "#03b45e", marginLeft: 2
                                            }}>
                                              {this.state.investment_information['stp']['returns_difference_amount']}
                                            </span>
                                          </h6>
                                        </div>
                                        <div class="col">
                                          <h6>
                                            <span style={{
                                              color:
                                                this.state.investment_information['stp']['total_returns_amount'].substring(0, 1) === "-" ||
                                                  ""
                                                  ? "#f57362"
                                                  : "#03b45e",
                                            }}>
                                              {this.state.investment_information['stp']['total_returns_percent']}%
                                            </span>
                                          </h6>
                                        </div>
                                      </div> */}
                                      <div class="row">
                                        <div class="col">
                                          <h6>
                                            {
                                              this.state.investment_information['stp']['total_returns_amount'].toString().substring(0, 1) === "-" ||
                                                "" ? 'Loss' : 'Profit'
                                            }
                                          </h6>
                                        </div>
                                        <div class="col">
                                          <h6>
                                            <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                            <span style={{
                                              color:
                                                this.state.investment_information['stp']['total_returns_amount'].toString().substring(0, 1) === "-" ||
                                                  ""
                                                  ? "#f57362"
                                                  : "#03b45e", marginLeft: 2
                                            }}>
                                              {this.state.investment_information['stp']['returns_difference_amount']}
                                            </span>
                                          </h6>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col">
                                          <h6>
                                            Returns Percent
                                          </h6>
                                        </div>
                                        <div class="col">
                                          <h6>
                                            <span style={{
                                              color:
                                                this.state.investment_information['stp']['total_returns_amount'].toString().substring(0, 1) === "-" ||
                                                  ""
                                                  ? "#f57362"
                                                  : "#03b45e", marginLeft: 2
                                            }}>
                                              {this.state.investment_information['stp']['total_returns_percent']}%
                                            </span>
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                              
                            </div>

                          </div>
                        }
                        {
                          !this.state.is_portfolio_available && 
                          <div>
                            
                            <Alert variant="dark text-center">
                              <Alert.Heading>
                                You have not started your investment.
                              </Alert.Heading>
                            </Alert>
                            <div style={{textAlign:'center'}}>
                              <Link to={"/schemeList"} title="Home">
                                  Start Investing Now<span><i className="fa fa-bar-chart"></i></span>
                              </Link>
                            </div>
                            
                          </div>
                        }
                      </div>
                    }

                    {/* <Fab
                  style={{ bottom: 27, right: -20 }}
                  // event="hover"
                  alwaysShowTitle={true}
                  mainButtonStyles={{
                    backgroundColor: "#00b5ad"
                  }}
                  icon={<i className="mdi mdi-filter" ></i>}
                  onClick={() => this.setState({ show_modal_filter: true })}
                >
                </Fab> */}

                  {/* </div> */}

                </div>

              </div>
            </div>
          </div>
        </div>

      </>
    );
  }

}

const mapStateToProps = ({ order }) => {
  const { order_result } = order;

  return {
    order_result,
  };
};

export default connect(mapStateToProps, {
  loadPortfolio,
  loadPaymentResponse,
  cancelOrder,
  createSwpOrder,
  twoFactorAuthentication,
  cancelSwpOrder
})(PortfolioInformation);
