
import React from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import { Form, Modal, ListGroup, Row, Col, Container, Spinner } from "react-bootstrap";

import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import { listWatchSchemes, addWatchList, modifyWatchList } from "../../../jsx/actions/master";
import { loadSchemeAdviseList } from "../../../jsx/actions/general";
import { createRazorPayOrder } from "../../../jsx/actions/payments";
import { TabPanel, a11yProps } from "../../shared/Tabs";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";

import { Button, Badge, Collapse, Alert } from "react-bootstrap";

const filter_conditional_parameters = ['growth', 'minimum_investment', 'investment_method'];

class AdvisorAdviseList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab_index: 0,
      masterList: JSON.parse(localStorage.getItem("masterList") || JSON.stringify({})),
      error: null,
      isLoaded: false,
      mutualFundList: [],
      pageIndex: 1,
      openCollapse: false,
      pageIndexAvailability: true,
      selected_order: {},
      selected_scheme: {},
      stp_selected_scheme_list: [],
      stp_order: {
        stp_frequency: '',
        stp_scheme: {},
        stp_scheme_code: {},
        stp_installments: '',
        stp_amount: '',
        stp_date: '',
        stp_date_value: '',
        validation_message_text: '',
        mfapi_scheme_code: '',
        selected_stp_frequency: [],
        selected_stp_scheme_code: [],
        selected_stp_installments: [],
      },
      show_targetscheme_popup: false,
      show_stpdate_popup: false,
      popup_stp_date_list: [],
      filterOption: {
        scheme_amc: /*selectedOptionAmc*/[],
        category: [],
        risk: [],
        sub_category: [],
        growth: [],
        minimum_investment: [],
        investment_method: [],
      },
      stp_frequency_list: [],
      stp_scheme_specification_list: [],
      stp_installment_list: [],
      sub_category_list: [],
      selectedOptionAmc: [],
      selectedOptionCategory: [],
      selectedOptionRisk: [],
      selectedOptionSubCategory: [],
      selectedOptionGrowth: [],
      selectedOptionMinInvestment: [],
      selectedOptionInvestmentMethod: [],
      options: [],
      newValue: 0,
      scheme_list: [],
      initial_scheme_list: [],
      scheme_watch_list: [],
      initial_scheme_watch_list: [],
      watch_list: [],
      perPage: 30,
      page: 1,
      pages: 0,
      isloading: false,

      notification_html: ``,

      list_pricing: [],
      selected_pricing_id: '',

      list_scheme_advisors: [],
      selected_scheme_advisor_id: '',

      submit_watchlist_enabled: false,
      total_watchlist_schemes: 0,
      total_allowed_schemes_in_watchlist: 0,

      selected_watchlist: '',
      display_mode: "view watchlist",
      show_modal_add_watchlist: false,
      modal_pin_confirm_action: '',
      modal_watchlist_name: '',
      modal_watchlist_confirm_enabled: false,

      show_modal_advise_pricing: false,
      show_modal_scheme_advisor: false,
      show_modal_scheme_advise: false,

      show_modal_information: false,
      modal_information_dialog_messagetext: '',

      modal_pin_confirm_dialog_messagetext: 'Enter your 4 digit pin to complete your action',
      modal_pin_confirm_dialog_error_messagetext: ''
    };
  }

  componentDidMount() {

    window.addEventListener("scroll", () => {

      if (document.getElementById("navbar-container") &&
        document.getElementById("navbar") &&
        document.getElementById("navbar").classList) {
        const topBorder = document
          .getElementById("navbar-container")
          .getBoundingClientRect().top;

        topBorder >= 0
          ? document.getElementById("navbar").classList.remove("fixed")
          : document.getElementById("navbar").classList.add("fixed");
      }

    });

    this.loadAdviseList();

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', null);
  }

  handleChangeAmc = (selectedOption) => {

    this.setState(
      {
        filterOption: { ...this.state.filterOption, scheme_amc: selectedOption },
        selectedOptionAmc: selectedOption,
        page: 0
      },
      () => this.filterGridList(/*selectedOption, 'scheme_amc'*/)

    );
  };

  handleChangeCategory = (selectedOption) => {

    this.setState(
      {
        filterOption: { ...this.state.filterOption, category: selectedOption },
        selectedOptionCategory: selectedOption,
        // sub_category_list: sub_category_list 
      },
      () => this.filterGridList(/*selectedOption, 'category'*/)

    );

  };

  handleChangeSubCategory = (selectedOption) => {

    this.setState({ selectedOptionSubCategory: selectedOption });
    this.filterGridList(/*selectedOption, 'sub_category'*/);

  };

  handleChangeRisk = (selectedOption) => {

    this.setState(
      {
        filterOption: { ...this.state.filterOption, risk: selectedOption },
        selectedOptionRisk: selectedOption
      },
      () => this.filterGridList(/*selectedOption, 'risk'*/)

    );

  };

  handleChangeGrowth = (selectedOption) => {

    this.setState(
      {
        filterOption: { ...this.state.filterOption, growth: selectedOption },
        selectedOptionGrowth: selectedOption
      },
      () => this.filterGridList(/*selectedOption, 'growth'*/)

    );

    // this.filterGrowthoption(selectedOption);
    // this.setState({ selectedOptionGrowth: selectedOption });

  };

  handleChangeMinInvestment = (selectedOption) => {

    this.setState(
      {
        filterOption: { ...this.state.filterOption, minimum_investment: selectedOption },
        selectedOptionMinInvestment: selectedOption
      },
      () => this.filterGridList(/*selectedOption, 'minimum_investment'*/)

    );

  };

  handleChangeInvestmentMode = (selectedOption) => {

    this.setState(
      {
        filterOption: { ...this.state.filterOption, investment_method: selectedOption },
        selectedOptionInvestmentMode: selectedOption
      },
      () => this.filterGridList(/*selectedOption, 'investment_method'*/)

    );

  };

  routeChange = (item) => {

    if ((window.matchMedia && window.matchMedia("(max-width: 480px)").matches)) {
      this.props.history.push({
        pathname: "/chartsmobile",
        state: {
          param_data: {
            scheme_isin: item['scheme_isin'],
            scheme_code: item['scheme_code'],
            scheme_name: item['scheme_name']
          }
        }
      });
    }
    else {

      const win = window.open("/charts/" + item['scheme_isin'] + '/' + item['scheme_code'] + '/' + item['scheme_name'], "_blank");
      win.focus();

    }

  };

  filterGridList = (amclistFilter) => {

    let _filtered_list = this.props.result['result']["scheme_list"] || []; //this.state.scheme_list || [];
    let _filterOption = amclistFilter ? amclistFilter : this.state.filterOption;

    if (amclistFilter) {

      this.setState({
        filterOption: {
          ...this.state.filterOption,
          scheme_amc: _filterOption['scheme_amc'],
          category: _filterOption['category'],
          risk: _filterOption['risk'],
          growth: _filterOption['growth'],
          minimum_investment: _filterOption['minimum_investment'],
          investment_method: _filterOption['investment_method']
        },
        selectedOptionGrowth: _filterOption['growth'],
        selectedOptionMinInvestment: _filterOption['minimum_investment'],
        selectedOptionInvestmentMode: _filterOption['investment_method']
        /*
        selectedOptionAmc: _filterOption['scheme_amc'],
        selectedOptionCategory: _filterOption['category'],
        selectedOptionRisk: _filterOption['risk'],
        selectedOptionSubCategory: _filterOption['sub_category']
        */
      });

    }

    let _selected_list = [];

    Object.keys(_filterOption).forEach(key => {

      /*if(key === 'risk' ||  key === 'category') {
        if(_filterOption[key]['value']) {
          _selected_list = _filterOption[key]['value'] === 'all' ? [] : [_filterOption[key]] ;
        }
        else
          _selected_list = [];
      }
      else*/
      _selected_list = _filterOption[key];

      if (_selected_list.length > 0) {

        if (filter_conditional_parameters
          .filter(__el => { return __el === key; }).length > 0) {

          console.log(key);
          // console.log(_selected_list);

          switch (key) {

            case 'growth':

              let _filter = _filterOption[key];
              let _arr_values = [];
              let boolTrue = false;

              let __filtered_list = [];

              _filtered_list.forEach(el => {

                boolTrue = false;

                _filter.forEach(el_ => {

                  if (!boolTrue) {

                    _arr_values = el_.value.split('-');

                    boolTrue = (parseFloat(el['one_year_percent'] || 0) >= parseFloat(_arr_values[0]) &&
                      parseFloat(el['one_year_percent'] || 0) <= parseFloat(_arr_values[1]));

                    if (!boolTrue)
                      boolTrue = (parseFloat(el['three_year_percent'] || 0) >= parseFloat(_arr_values[0]) &&
                        parseFloat(el['three_year_percent'] || 0) <= parseFloat(_arr_values[1]));

                    if (!boolTrue)
                      boolTrue = (parseFloat(el['five_year_percent'] || 0) >= parseFloat(_arr_values[0]) &&
                        parseFloat(el['five_year_percent'] || 0) <= parseFloat(_arr_values[1]));

                  }

                  if (boolTrue)
                    __filtered_list.push(el);

                });

                // return boolTrue;

                /*
                return _filter.filter(el_ =>  {
                  _arr_values = el_.value.split('-');
                  return (parseFloat(el['one_year_percent'] || 0) >= parseFloat(_arr_values[0]) && 
                    parseFloat(el['one_year_percent'] || 0) <= parseFloat(_arr_values[1])) 
                    || 
                    (parseFloat(el['three_year_percent'] || 0) >= parseFloat(_arr_values[0]) && 
                    parseFloat(el['three_year_percent'] || 0) <= parseFloat(_arr_values[1]))
                    ||
                    (parseFloat(el['five_year_percent'] || 0) >= parseFloat(_arr_values[0]) && 
                    parseFloat(el['five_year_percent'] || 0) <= parseFloat(_arr_values[1])) ;
                });*/ //.length > 0;

              });

              _filtered_list = __filtered_list;

              // _filtered_list = [...new Set(_filtered_list)];

              break;
            case 'minimum_investment':
              _filtered_list = _filtered_list.filter(el => {

                return _filterOption[key].filter(el_ => {
                  return parseFloat(el['scheme_minimum_investment_amount'] || 0) >= parseFloat(el_.value) &&
                    parseFloat(el['scheme_minimum_investment_amount'] || 0) <= parseFloat(el_.value) + 10;
                }).length > 0;

              });
              break;
            case 'investment_method':

              let _condition = _filterOption[key].map(el => {
                return el['value'];
              });

              _filtered_list = _filtered_list.filter(el => {

                if (_condition.length > 1)
                  return (el[_condition[0]] === 'Y' || el[_condition[1]] === 'Y');
                else
                  return el[_condition[0]] === 'N';

              });
              break;

            case 'category':

              let condition_category = _filterOption[key].map(el => {
                return el['value'];
              });

              _filtered_list = _filtered_list.filter(el => {

                if (condition_category.length > 1)
                  return (el[condition_category[0]]);


              });
              break;

            default:
              break;
          }

        }
        else {

          //  console.log('no switch =>' + key);

          let _loc = '';
          let _condition = _selected_list; /*.map(el => {
               return el.value.toLowerCase();
             });*/

          _filtered_list = _filtered_list.filter(el => {

            // return _condition.indexOf((el[key] || '').toLowerCase());
            _loc = (el[key] || '').toLowerCase();
            return _condition.filter(el_ => {
              return _loc === el_['value'].toLowerCase();
            }).length > 0;

          });

        }
      }

    });

    sessionStorage.setItem('amclistFilter', JSON.stringify(_filterOption));

    this.setState({
      scheme_list: _filtered_list,
      pages: Math.round/*floor*/(_filtered_list.length /
        this.props.result['per_page_count'] || this.state.perPage)
    });

  };

  filterGrowthoption = (selectedOption) => {

    let _filtered_list = this.state.scheme_list || [];

    let text = selectedOption[0].value || "";
    let filtered_list = _filtered_list.filter((item) => {
      return text.length > 1
        ? (
          item.three_year_percent.toString() ||
          item.one_month_percent.toString() ||
          item.one_year_percent.toString()
        ).match(text)
        : true;
    });

    filtered_list = [...new Set(filtered_list)];

    this.setState({
      scheme_list: filtered_list,
      pages: Math.round/*floor*/(filtered_list.length /
        this.props.result['per_page_count'] || this.state.perPage)
    });

  };

  filterMininvestmentoption = (selectedOption) => {

    let _filtered_list = this.state.scheme_list || [];

    let text = selectedOption[0].value || "";
    let filtered_list = _filtered_list.filter((item) => {
      return text.length > 1
        ? item.minimum_investment_amount.toString().match(text)
        : true;
    });

    this.setState({
      scheme_list: filtered_list,
      pages: Math.round/*floor*/(filtered_list.length /
        this.props.result['per_page_count'] || this.state.perPage)
    });

  };

  loadAdviseList = () => {

    this.setState({ isloading: true });

    this.props
      .listWatchSchemes({
        file_index: this.state.pageIndex
      })
      .then((response) => {

        const { perPage } = this.state;

        if (this.props.result["code"] === "000") {

          this.setState({
            show_targetscheme_popup: true,
            scheme_list: this.props.result['result']["scheme_list"] || [],
            scheme_watch_list: [],
            initial_scheme_watch_list: this.props.result['result']["scheme_watch_list"] || [],
            watch_list: this.props.result['result']["watch_list"] || [],
            initial_scheme_list: this.props.result['result']["scheme_list"] || [],
            list_pricing: this.props.result['result']["list_pricing"] || [],
            list_scheme_advisors: this.props.result['result']["list_scheme_advisors"] || [],
            total_allowed_schemes_in_watchlist: this.props.result['result']["total_allowed_schemes_in_watchlist"] || 10,

            pages:
              Math.round/*floor*/(
                (this.props.result['result']["scheme_list"] || []).length /
                this.props.result['per_page_count'] || perPage
              ),
            isloading: false,
            perPage: this.props.result['per_page_count'] || this.state.perPage
          });

          this.filterGridList(
            sessionStorage.getItem('amclistFilter') ? JSON.parse(sessionStorage.getItem('amclistFilter')) : null);

          if (sessionStorage.getItem('amclistFilter'))
            sessionStorage.removeItem('amclistFilter');

        } else {
          alert(this.props.result["messageText"]);
          this.setState({ isloading: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSchemeCheck = (/*e,*/ item) => {

    // let _list = this.state.initial_scheme_list;

    // _list.forEach((el) => {
    //   el["is_checked"] = false;

    //   if (el["_id"] === item["_id"])
    //     el["is_checked"] = true; //e.target.checked;
    // });

    // console.log(item);

    let _watch_scheme_filtered = this.state.scheme_watch_list.filter(el => {
      return el['scheme_isin'] === item['scheme_isin'];
    });

    console.log(_watch_scheme_filtered);
    console.log('selected');

    _watch_scheme_filtered = _watch_scheme_filtered.map(el => {
      return {
        label: 'Min ₹' + el['stp_in_minimum_installment_amount']
          + ' - Max ₹' + (parseInt(el['stp_in_maximum_installment_amount']) < 1
            ? 99999999.000 : el['stp_in_maximum_installment_amount'])
          // + '| SETTLEMENT TYPE :' + el['param_settlement_type']
          + (el['scheme_name'].split('-').length < 3 ?
            '' :
            ('|' + el['scheme_name'].split('-')[el['scheme_name'].split('-').length - 1])),
        // + '| REDEEM ' + (el['param_redemption_allowed'] === 'N' ? 'NOT ' : '') + 'ALLOWED',
        value: el['scheme_code'],
        frequency: el['stp_frequency']
      }
    });

    this.setState({
      stp_frequency_list: this.props.result["stp_frequency_list"] || [],
      stp_installment_list: this.props.result["stp_installment_list"] || [],
      stp_order: {
        ...this.state.stp_order,
        stp_frequency: '',
        stp_scheme: {},
        stp_scheme_code: {},
        stp_installments: '',
        stp_amount: '',
        stp_date: '',
        stp_date_value: '',
        validation_message_text: '',
        selected_stp_frequency: [],
        selected_stp_scheme_code: [],
        selected_stp_installments: [],
      },
      // scheme_list: _list,
      stp_scheme_specification_list: _watch_scheme_filtered,
      selected_scheme: item,
      show_targetscheme_popup: false
    });
    // window.scrollTo(0, document.body.scrollHeight);

  };

  handleChangeWatchList = (selectedOption) => {

    console.log(selectedOption);

    this.setState(
      {
        selected_watchlist: selectedOption
      },
      () => {

        let _scheme_watch_list = this.state.initial_scheme_watch_list.filter(el => {
          return el['id'] === selectedOption['value'];
        });

        console.log(_scheme_watch_list);

        _scheme_watch_list = _scheme_watch_list.length > 0 ?
          _scheme_watch_list[0]['scheme_list'] : []

        // const _scheme_list = this.state.initial_scheme_list.filter(el => {
        //   return el['scheme_code'] === selectedOption['value'];
        // });

        this.setState({
          scheme_watch_list: this.myFilter(this.state.initial_scheme_list,
            _scheme_watch_list.map(el => {
              return el['scheme_code']
            }).join(','))
        });

      });

    // this.setState({
    //   scheme_watch_list: this.state.initial_scheme_list.filter
    // });

    // this.setState(
    //   {
    //     status_filter_selected: selectedOption
    //   },
    //   () => {

    //     let _filtered_list_single_orders = [];

    //     selectedOption.forEach(item => {
    //       _filtered_list_single_orders = _filtered_list_single_orders.concat(
    //         this.state.list_single_orders.filter(el => {
    //           return el[item['value']] === true;
    //         })
    //       );
    //     });

    //     this.setState({
    //       _list_single_orders: selectedOption.length > 0 ?
    //         _filtered_list_single_orders : this.state.list_single_orders
    //     });

    //   });

  };

  handleRadioChange = (value, item, key) => {

    console.log(value);

    this.setState({
      // selected_pricing_id: item['id']
      [key]: item['id']
    });

  };

  myFilter(base, query) {

    const queries = query.split(",")
    return base.filter((a) => {
      let doReturn = false;
      queries.forEach((q) => {

        if (a.scheme_code.includes(q)) {
          doReturn = true;
        }

      })
      return doReturn;
    });

  }

  loadScript = async (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  displayRazorpay = async () => {

    // const res = await this.loadScript(
    //   "https://checkout.razorpay.com/v1/checkout.js"
    // );

    // if (!res) {
    //   alert("Razorpay SDK failed to load. Are you online?");
    //   return;
    // }
    // else 
    {

      // const _selected_scheme_list = this.state.scheme_list.filter(el => {
      //   return el['is_checked'] === true;
      // });

      const list_pricing = this.state.list_pricing.filter(el => {
        return el['id'] === this.state.selected_pricing_id;
      });

      const list_scheme_advisors = this.state.list_scheme_advisors.filter(el => {
        return el['_id'] === this.state.selected_scheme_advisor_id;
      });

      const _props = this.props;
      const _state = this.state;

      this.props
        .createRazorPayOrder({
          selected_watchlist: this.state.selected_watchlist,
          request_mode: 'create order',
          pricing_plan: list_pricing[0],
          scheme_advisor: list_scheme_advisors[0],
          watchlist_name: this.state.modal_watchlist_name
        // }).then((response) => {
        }, response => {

          console.log(_props)

          if(_props.payment_result)
             response = _props.payment_result;
          
          if (response["code"] === "000") {

            let _result = response["result"];
            const {
              key, amount, id: order_id, currency, name, email, mobile, address, description, color, logo
            } = _result['razorpay_orderinfo'];

            const options = {
              key: key,
              amount: amount,
              currency: currency,
              name: name,
              description: description,
              image: { logo },
              order_id: order_id,
              handler: async function (response) {

                console.log(response);
                /*
                const data = {
                  orderCreationId: order_id,
                  razorpayPaymentId: response.razorpay_payment_id,
                  razorpayOrderId: response.razorpay_order_id,
                  razorpaySignature: response.razorpay_signature,
                };
                */

                let payment_order_response = response;
                payment_order_response['order_id'] = order_id;

                _props
                  .createRazorPayOrder({
                    selected_watchlist: _state.selected_watchlist['value'],
                    request_mode: 'confirm order',
                    pricing_plan: list_pricing[0],
                    scheme_advisor: list_scheme_advisors[0],
                    // watchlist_name: this.state.modal_watchlist_name,
                    payment_order_response: payment_order_response
                  // }).then((response) => {
                  }, _response => {

                    if(_props.payment_result)
                    _response = _props.payment_result;
                    
                    console.log(_response);

                    if (_response["code"] === "000") {

                      const initial_scheme_watch_list = _response['result']["scheme_watch_list"] || [];

                      let _scheme_watch_list = initial_scheme_watch_list.filter(el => {
                        return el['id'] === _state.selected_watchlist['value'];
                      });

                      _scheme_watch_list = _scheme_watch_list.length > 0 ?
                        _scheme_watch_list[0]['scheme_list'] : [];

                      this.setState({

                        selected_pricing_id: '',
                        selected_scheme_advisor_id: '',

                        submit_watchlist_enabled: false,
                        total_watchlist_schemes: 0,

                        selected_watchlist: '',
                        display_mode: "view watchlist",
                        show_modal_add_watchlist: false,
                        modal_watchlist_name: '',
                        modal_watchlist_confirm_enabled: false,

                        show_modal_advise_pricing: false,
                        show_modal_scheme_advisor: false,
                        show_modal_scheme_advise: false,

                        show_modal_information: true,
                        modal_information_dialog_messagetext: _response['messageText'],

                        watch_list: _response['result']["watch_list"] || [],
                        initial_scheme_watch_list: initial_scheme_watch_list,
                        scheme_watch_list: this.myFilter(this.state.initial_scheme_list,
                          _scheme_watch_list.map(el => { return el['scheme_code'] }).join(','))
                        
                      });

                    }

                  });

                // const result = await axios.post("http://localhost:5000/payment/success", data);

                // alert(result.data.msg);
              },
              prefill: {
                name: name,
                email: email,
                contact: mobile,
              },
              notes: {
                address: address,
              },
              theme: {
                color: color, //"#61dafb",
              },
            };

            const paymentObject = new window.Razorpay(options);

            paymentObject.on("payment.failed", function (response) {
              /*
              alert(response.error.code);
              alert(response.error.description);
              alert(response.error.source);
              alert(response.error.step);
              alert(response.error.reason);
              alert(response.error.metadata.order_id);
              alert(response.error.metadata.payment_id);
              */
            });

            paymentObject.open();
          }
          else {

          }
        })
        .catch((err) => {
          alert(err);
        });

    }

  }

  handleChangeSelect = (selectedOption, input) => {

    if (input === 'stp_frequency') {

      console.log(this.state.stp_scheme_specification_list);

      this.setState({
        stp_order: {
          ...this.state.stp_order,
          [input]: selectedOption.value,
          selected_stp_frequency: selectedOption
        },
        stp_selected_scheme_list: this.state.stp_scheme_specification_list.filter(el => {
          return el['frequency'].toLowerCase() === selectedOption.value.toLowerCase();
        })
      });

    }
    else if (input === 'stp_scheme_code') {

      let _selected_scheme = this.state.scheme_watch_list.filter(el => {
        return el['scheme_code'] === selectedOption.value;
      });

      if (_selected_scheme.length > 0) {

        _selected_scheme = _selected_scheme[0];

        let _loc = [];
        for (var _rec = parseInt(_selected_scheme['stp_minimum_installment_numbers']);
          _rec <= parseInt(_selected_scheme['stp_maximum_installment_numbers']); _rec++) {
          _loc.push({ value: _rec, label: _rec });
        }

        let _mfapi_scheme_code = this.state.scheme_list.filter(el => {
          return el['scheme_isin'] === _selected_scheme['scheme_isin'] ||
            el['scheme_isin_1'] === _selected_scheme['scheme_isin'];
        });

        console.log(_mfapi_scheme_code[0]['scheme_code']);

        _selected_scheme['mfapi_scheme_code'] = _mfapi_scheme_code.legnth > 0 ?
          _mfapi_scheme_code[0]['scheme_code'] : '';

        this.setState({

          stp_order: {
            ...this.state.stp_order,
            [input]: _selected_scheme['scheme_code'],
            mfapi_scheme_code: _mfapi_scheme_code.legnth > 0 ?
              _mfapi_scheme_code[0]['scheme_code'] : '',
            stp_scheme: _selected_scheme,
            selected_stp_scheme_code: selectedOption
          },
          stp_installment_list: _loc,
          popup_stp_date_list: _selected_scheme['stp_dates'].split(',')

        });

      }

    }
    else if (input === 'stp_installments') {

      this.setState({
        stp_order: {
          ...this.state.stp_order,
          [input]: selectedOption.value,
          selected_stp_installments: selectedOption
        }
      });

    }
    // else if (input === 'stp_amount') {

    // }

  };

  handlePageClick = (event) => {

    let page = event.selected;
    this.setState({ page });
    window.scrollTo(0, 0);

  };

  onNextPrevious = (count) => {
    // this.setState({pageIndex: this.state.pageIndex + count});
  };

  getBase64Image = (imgUrl) => {

    var img = new Image();

    // onload fires when the image is fully loadded, and has width and height

    img.onload = function () {

      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/png"),
        dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");

      return (dataURL); // the base64 string

    };

    // set attributes and src 
    img.setAttribute('crossOrigin', 'anonymous'); //
    img.src = imgUrl;

  }

  stpParameterValidation() {

    let _stp_order = this.state.stp_order;

    var isValid = (_stp_order['stp_frequency'] || '').length > 4 && parseInt(_stp_order['stp_installments'] || 0) >= 1
      && !isNaN(this.state.stp_order['stp_amount'] || '')
      && parseInt(this.state.stp_order['stp_amount']) >= parseInt(_stp_order['stp_scheme']['stp_in_minimum_installment_amount'])
      && parseInt(this.state.stp_order['stp_amount']) <= parseInt(_stp_order['stp_scheme']['stp_in_maximum_installment_amount']);

    return isValid;

    /*
    return parseFloat(_sip_order['sip_amount']) >= parseFloat(this.state.selected_sip_order['minimum_installment_amount']) &&
      parseFloat(_sip_order['sip_amount']) <= parseFloat(this.state.selected_sip_order['maximum_installment_amount']) &&
      _sip_order['sip_frequency'].length > 2 && _sip_order['sip_installment_numbers'] > 0;
    */
  }

  handleSchemeSelect = (item) => {

    console.log(item);

    if(this.state.total_watchlist_schemes >= 10) {
        alert('You can save only 10 schemes in an single watchlist')
    }
    else {

      let _list_scheme = this.state.scheme_list;
      _list_scheme.forEach(el => {
        if (el['_id'] === item['_id']) {
          item['is_checked'] = !item['is_checked']
        }
      });
  
      const submit_watchlist_enabled = _list_scheme.filter(el => {
        return el['is_checked'] === true;
      }).length ;
  
      this.setState({
        scheme_list: _list_scheme,
        submit_watchlist_enabled: submit_watchlist_enabled > 0,
        total_watchlist_schemes: submit_watchlist_enabled
      });  

    }

  };

  submitWatchList = (request_mode) => {

    // if (isValid) 
    {

      this.setState({ isloading: true });

      const _selected_scheme_list = this.state.scheme_list.filter(el => {
        return el['is_checked'] === true;
      })
      this.props
        .addWatchList({
          scheme_watchlist: _selected_scheme_list,
          request_mode: request_mode,
          watchlist_name: this.state.modal_watchlist_name
        }).then((response) => {

          if (this.props.result["code"] !== "000") {
            this.setState({
              isloading: false
            });
          }
          else {

            let _result = this.props.result["result"];

            const initial_scheme_watch_list = _result["scheme_watch_list"] || [];

            let selected_scheme_watch_list = initial_scheme_watch_list.filter(el => {
              return el['id'] === _result['added_watchlist_id'];
            });

            let _scheme_watch_list = selected_scheme_watch_list.length > 0 ?
              selected_scheme_watch_list[0]['scheme_list'] : [];

            this.setState({

              isloading: false,

              selected_pricing_id: '',
              selected_scheme_advisor_id: '',

              submit_watchlist_enabled: false,
              total_watchlist_schemes: 0,

              selected_watchlist: selected_scheme_watch_list[0],
              display_mode: "view watchlist",
              show_modal_add_watchlist: false,
              modal_watchlist_name: '',
              modal_watchlist_confirm_enabled: false,

              show_modal_advise_pricing: false,
              show_modal_scheme_advisor: false,
              show_modal_scheme_advise: false,

              show_modal_information: true,
              modal_information_dialog_messagetext: _result['messageText'],

              watch_list: _result["watch_list"] || [],
              initial_scheme_watch_list: initial_scheme_watch_list,
              scheme_watch_list: this.myFilter(this.state.initial_scheme_list,
                _scheme_watch_list.map(el => {
                  return el['scheme_code']
                }).join(','))

            });

          }
        })
        .catch((err) => {
          alert(err);
        });

    }
    // else
    //   alert('Your inputs are not valid');

  }

  createDefaultMarkup() {
    return {
      __html: this.state.modal_information_dialog_messagetext,
    }
  };

  render() {

    const { page, perPage, pages, scheme_list, scheme_watch_list } = this.state;

    let _scheme_watch_list = scheme_watch_list;

    let _list_scheme = scheme_list.length > perPage ?
      scheme_list.slice(page * perPage, (page + 1) * perPage) : scheme_list;

    return (
      <>
        <Header1 />
        <Sidebar />
        <div id="navbar-container">
          <div id="navbar" >
            <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
              <Container >
                <Row>
                <Tabs
                  value={this.state.tab_index}
                  // onChange={this.handleChange}
                  aria-label="basic tabs example"
                  textColor="primary"
                  indicatorColor="primary"
                  
                >
                  <Tab label="Your Mobile Number" classname="btn btn-primary"  {...a11yProps(0)} />
                  <Tab label="Your Email Id" classname="btn btn-primary" {...a11yProps(1)} />
                </Tabs>
                </Row>
                <Row >
                  <Col sm={3}>
                    <div className="card-header">
                      <h5 >
                        {/* {
                          this.state.display_mode === 'add watchlist' ?
                            `Create Your Watch List` :
                            `Choose Your Watch List`
                        } */}
                        Scheme WatchList
                      </h5>
                    </div>
                  </Col>
                  {this.state.display_mode === 'view watchlist' &&
                    <Col sm={4} style={{ marginTop: 10 }}>
                      <div
                        style={{ width: '270px' }} >
                        <Select
                          placeholder={`-Select Your WatchList-`}
                          // isMulti
                          value={this.state.selected_watchlist}
                          onChange={this.handleChangeWatchList}
                          options={this.state.watch_list}
                        />
                      </div>
                    </Col>
                  }
                  {this.state.display_mode === 'view watchlist' &&
                  (this.state.selected_watchlist['value'] || '').length > 3 &&
                    <Col style={{ marginTop: 10 }}>
                      {!this.state.selected_watchlist['subscription_enabled'] ?
                        <button type="button"
                          className="btn-sm btn-success"
                          style={{ color: 'white', width: '120px' }}
                          onClick={() => {
                            this.setState({
                              show_modal_scheme_advisor: true
                            })
                          }}
                        >
                          Ask Expert Advise
                          {/* <span>{`  `}<i
                          className="fa fa-angle-right"></i></span> */}
                        </button>
                        :
                        <button type="button"
                          className="btn-sm btn-success"
                          style={{ color: 'white', width: '240px' }}
                          disabled={true}
                          // onClick={() => {
                          //   this.setState({
                          //     show_modal_scheme_advise: true
                          //   })
                          // }}
                        >
                          You are Subscribed for Expert Advise
                          <span>{`  `}<i
                            className="fa fa-angle-right"></i></span>
                        </button>
                      }
                    </Col>
                  }
                  {this.state.display_mode === 'view watchlist' &&
                    <Col sm={4} style={{ marginTop: 10 }}>
                      <button type="button"
                        className="btn-sm btn-primary"
                        style={{ color: 'white', width: '210px' }}
                        onClick={() => {
                          this.setState({
                            display_mode: 'add watchlist'
                          });
                        }}
                      >
                        Create Your Watch List
                      </button>
                    </Col>
                  }

                  {this.state.display_mode === 'add watchlist' &&
                    <Col sm={4} style={{ marginTop: 10 }}>
                      <button type="button"
                        className="btn-sm btn-success"
                        style={{ color: 'white' }}
                        onClick={() => {
                          this.setState({
                            show_modal_add_watchlist: true
                          });
                        }}
                        disabled={!this.state.submit_watchlist_enabled}
                      >
                        Submit Your Watch List
                      </button>
                    </Col>
                  }
                  {this.state.display_mode === 'add watchlist' &&
                    <Col sm={4} style={{ marginTop: 10 }}>
                      <button type="button"
                        className="btn-sm btn-primary"
                        style={{ color: 'white' }}
                        onClick={() => {
                          this.setState({
                            display_mode: 'view watchlist'
                          });
                        }}
                      >
                        Return To Your Watch List
                      </button>
                    </Col>
                  }

                </Row>
              </Container>
              <hr />
            </div>
          </div>
        </div>

        <div className="content-body" style={{ marginTop: 30 }}>

          <div className="container">

            {this.state.isloading && (
              <Spinner
                animation="border"
                variant="dark"
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  zIndex: 899,
                }}
              />
            )}

            {this.state.display_mode === 'view watchlist' &&
              (this.state.scheme_watch_list.length || []).length < 1 && (
                <Alert variant="dark text-center">
                  <Alert.Heading>
                    {this.state.isloading
                      ? "We are fetching your watchlist schemes"
                      :
                      this.state.watch_list.length > 0 ?
                        "Choose your watchlist name" : "Your watchlist is empty"
                    }
                  </Alert.Heading>
                </Alert>
              )}

            {this.state.display_mode === 'view watchlist' &&
              <div>
                {_scheme_watch_list.map((item) => (
                  <ListGroup key={item["_id"]}>
                    <ListGroup.Item
                      style={{ marginBottom: 5 }}
                      action
                      variant="light"
                      className="wallet-address text-dark"
                    >
                      <Row >
                        <Col xs={9} md={7}>

                          <p class="text-gray-dark">
                            <img className="fundlogo" src={item['logo_url']} />  {
                              item["scheme_name"]
                            }
                          </p>
                          <div className="ms-2 me-auto">
                            <div className="font-size-list-f1 text-secondary">

                            {this.state.selected_watchlist['subscription_enabled'] &&
                            <button type="button"
                              className="btn-sm btn-success"
                              style={{ color: 'white', width: '110px' }}
                              onClick={() => {
                                this.setState({
                                  show_modal_scheme_advise: true
                                })
                              }}
                            >
                              Expert Advise
                              <span>{`  `}<i
                                className="fa fa-angle-right"></i></span>
                            </button>
                          }
                              <span style={{ fontSize: 12, color: 'InfoText', backgroundColor: '#e3e0de', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }} >
                                {item['scheme_category']}
                              </span>
                              <span style={{
                                fontSize: 12,
                                color: 'InfoText',
                                backgroundColor: item["risk"].toLowerCase().indexOf('low') > -1 ? 'lightgreen' :
                                  (item["risk"].toLowerCase().indexOf('high') > -1 ? '#d96f76' : '#81a6d6'), padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                              }} >
                                {item["risk"]}
                              </span>
                              {/* {
                                          item['scheme_purchase_allowed'] === 'Y' && 
                                          <span style={{ fontSize: 12, color: 'InfoText', backgroundColor:'#e0e0e0', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                                            Lumpsum
                                          </span>
                                        }
                                        {
                                          item['scheme_sip_allowed'] === 'Y' && 
                                          <span style={{ fontSize: 12, color: 'InfoText', backgroundColor:'#e0e0e0', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                                            SIP
                                          </span>                                          
                                        } */}
                              <span>
                                <span
                                  style={{ fontSize: 12 }}
                                  class="text-dark"
                                >

                                  {`  `}
                                  {item["one_month_percent"] ?
                                    <span style={{ fontSize: 13, color: (item["one_month_percent"].substring(0, 1) === '-' || '') ? 'red' : '#03b45e' }}>
                                      {item["one_month_percent"]}%
                                    </span> : ''}
                                </span>
                                {`  `}<span
                                  style={{ fontSize: 12 }}
                                >
                                  1M
                                </span>
                              </span>

                            </div>
                          </div>
                          <div>
                          </div>
                        </Col>
                        {/* {item["one_month_percent"] && (
                                    <Col xs={3} md={1}>
                                      {" "}
                                      <div class="text-right">
                                        <h5
                                          class="text-gray-dark"
                                          style={{ fontSize: 12 }}
                                        >
                                          1M
                                        </h5>

                                        <span
                                          style={{ fontSize: 12 }}
                                          class="text-dark"
                                        >

                                          {" "}
                                          {item["one_month_percent"] ?
                                            <p style={{ fontSize: 13, color: (item["one_month_percent"].substring(0, 1) === '-' || '') ? 'red' : '#03b45e' }}>
                                              {item["one_month_percent"]}%
                                            </p> : ''}
                                        </span>
                                      </div>
                                    </Col>
                                  )} */}
                        {item["three_month_percent"] && !isNaN(parseInt(item["three_month_percent"])) &&
                          <Col xs={7} md={2}>
                            <div style={{ width: 450 }} class="row">
                              <div class="col">
                                <div style={{ borderBottom: '1px solid', borderBottomColor: 'lightgray', textAlign: 'center' }}>
                                  Lumpsum
                                </div>
                                <div class="row">
                                  {!item["three_year_percent_show"] && item["three_month_percent_show"] && <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                    <div style={{ fontSize: 12, color: '#7d7777' }}>3M</div>
                                    <span style={{ fontSize: 13, color: (item["three_month_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                      {item["three_month_percent"]}%</span>
                                  </div>
                                  }
                                  {!item["five_year_percent_show"] && item["six_month_percent_show"] &&
                                    <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                      <div style={{ fontSize: 12, color: '#7d7777' }}>6M</div>
                                      <span style={{ fontSize: 13, color: (item["six_month_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                        {item["six_month_percent"]}%</span>
                                    </div>
                                  }
                                  {item["one_year_percent_show"] && <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                    <div style={{ fontSize: 12, color: '#7d7777' }}>1Y</div>
                                    <span style={{ fontSize: 13, color: (item["one_year_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                      {item["one_year_percent"]}%</span>

                                  </div>
                                  }
                                  {item["three_year_percent_show"] && <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                    <div style={{ fontSize: 12, color: '#7d7777' }}>3Y</div>
                                    <span style={{ fontSize: 13, color: (item["three_year_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                      {item["three_year_percent"]}%</span>

                                  </div>
                                  }
                                  {item["five_year_percent_show"] && <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                    <div style={{ fontSize: 12, color: '#7d7777' }}>5Y</div>
                                    <span style={{ fontSize: 13, color: (item["five_year_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                      {item["five_year_percent"]}%</span>

                                  </div>
                                  }
                                </div>
                              </div>
                              <div class="col-1" style={{ borderLeft: '1px solid', marginTop: 24, height: 34, position: 'absolute', left: '79.5%' }}></div>
                              <div class="col">
                                <div style={{ fontFamily: 'sans-serif', borderBottom: '1px solid', borderBottomColor: 'lightgray', textAlign: 'center' }}>
                                  {/* SIP (Eg. &#8377;1000 monthly) */}
                                  SIP (Annualized %)
                                </div>
                                <div class="row">
                                  {['1y', '3y', '5y'].map((el, index) => (
                                    item['sip_returns_data'] && item['sip_returns_data'][el + '_percent_annualized'] && !isNaN(parseInt(item['sip_returns_data'][el + '_percent_annualized'])) &&
                                    <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                      <div style={{ fontSize: 12, color: '#7d7777' }}>{el.toUpperCase()}</div>
                                      <span style={{ fontSize: 13, color: (item['sip_returns_data'][el + '_percent_annualized'].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                        {item['sip_returns_data'][el + '_percent_annualized']}%</span>
                                    </div>
                                  )
                                  )}
                                  {/* {item['sip_returns_data'] && item['sip_returns_data']["1y_percent_annualized"] && !isNaN(parseInt(item['sip_returns_data']["1y_percent"])) &&
                                              <div style={{width: '30px', textAlign: 'center' }} class="col">
                                                <div style={{ fontSize: 12, color: '#7d7777' }}>1Y</div>
                                                <span style={{ fontSize: 13, color: (item['sip_returns_data']["1y_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                                  {item['sip_returns_data']["1y_percent"]}%</span>
                                              </div>
                                            }
                                            {item['sip_returns_data'] && item['sip_returns_data']["3y_percent"] && !isNaN(parseInt(item['sip_returns_data']["3y_percent"])) &&
                                              <div style={{width: '30px', textAlign: 'center' }} class="col">
                                                <div style={{ fontSize: 12, color: '#7d7777' }}>3Y</div>
                                                <span style={{ fontSize: 13, color: (item['sip_returns_data']["3y_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                                  {item['sip_returns_data']["3y_percent"]}%</span>
                                              </div>
                                            }
                                            {item['sip_returns_data'] && item['sip_returns_data']["5y_percent"] && !isNaN(parseInt(item['sip_returns_data']["5y_percent"])) &&
                                              <div style={{width: '30px', textAlign: 'center' }} class="col">
                                                <div style={{ fontSize: 12, color: '#7d7777' }}>5Y</div>
                                                <span style={{ fontSize: 13, color: (item['sip_returns_data']["5y_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                                  {item['sip_returns_data']["5y_percent"]}%</span>
                                              </div>
                                            } */}
                                </div>
                              </div>
                            </div>
                          </Col>
                        }

                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
                ))}
              </div>
            }
            {this.state.display_mode === 'add watchlist' &&
              <div>
                {_list_scheme.map((item) => (
                  <ListGroup key={item["_id"]}>
                    <ListGroup.Item
                      style={{ marginBottom: 5 }}
                      action
                      variant="light"
                      className="wallet-address text-dark"
                    >
                      <Row >
                        <Col xs={9} md={7}>

                          <p class="text-gray-dark">
                            <img className="fundlogo" src={item['logo_url']} />  {
                              item["scheme_name"]
                            }
                          </p>
                          <div className="ms-2 me-auto">
                            <div className="font-size-list-f1 text-secondary">

                              <label>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicCheckbox"
                                >
                                  <Form.Check
                                    type="checkbox"
                                    label="Select"
                                    onChange={() => this.handleSchemeSelect(item)}
                                    defaultChecked={false}
                                  // disabled={this.state.is_redeem_button_clicked}
                                  />
                                </Form.Group>
                              </label>
                              <span style={{ fontSize: 12, color: 'InfoText', backgroundColor: '#e3e0de', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }} >
                                {item['scheme_category']}
                              </span>
                              <span style={{
                                fontSize: 12,
                                color: 'InfoText',
                                backgroundColor: item["risk"].toLowerCase().indexOf('low') > -1 ? 'lightgreen' :
                                  (item["risk"].toLowerCase().indexOf('high') > -1 ? '#d96f76' : '#81a6d6'), padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                              }} >
                                {item["risk"]}
                              </span>
                              {/* {
                                          item['scheme_purchase_allowed'] === 'Y' && 
                                          <span style={{ fontSize: 12, color: 'InfoText', backgroundColor:'#e0e0e0', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                                            Lumpsum
                                          </span>
                                        }
                                        {
                                          item['scheme_sip_allowed'] === 'Y' && 
                                          <span style={{ fontSize: 12, color: 'InfoText', backgroundColor:'#e0e0e0', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                                            SIP
                                          </span>                                          
                                        } */}
                              <span>
                                <span
                                  style={{ fontSize: 12 }}
                                  class="text-dark"
                                >

                                  {`  `}
                                  {item["one_month_percent"] ?
                                    <span style={{ fontSize: 13, color: (item["one_month_percent"].substring(0, 1) === '-' || '') ? 'red' : '#03b45e' }}>
                                      {item["one_month_percent"]}%
                                    </span> : ''}
                                </span>
                                {`  `}<span
                                  style={{ fontSize: 12 }}
                                >
                                  1M
                                </span>
                              </span>

                            </div>
                          </div>
                          <div>
                          </div>
                        </Col>
                        {/* {item["one_month_percent"] && (
                                    <Col xs={3} md={1}>
                                      {" "}
                                      <div class="text-right">
                                        <h5
                                          class="text-gray-dark"
                                          style={{ fontSize: 12 }}
                                        >
                                          1M
                                        </h5>

                                        <span
                                          style={{ fontSize: 12 }}
                                          class="text-dark"
                                        >

                                          {" "}
                                          {item["one_month_percent"] ?
                                            <p style={{ fontSize: 13, color: (item["one_month_percent"].substring(0, 1) === '-' || '') ? 'red' : '#03b45e' }}>
                                              {item["one_month_percent"]}%
                                            </p> : ''}
                                        </span>
                                      </div>
                                    </Col>
                                  )} */}
                        {item["three_month_percent"] && !isNaN(parseInt(item["three_month_percent"])) &&
                          <Col xs={7} md={2}>
                            <div style={{ width: 450 }} class="row">
                              <div class="col">
                                <div style={{ borderBottom: '1px solid', borderBottomColor: 'lightgray', textAlign: 'center' }}>
                                  Lumpsum
                                </div>
                                <div class="row">
                                  {!item["three_year_percent_show"] && item["three_month_percent_show"] && <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                    <div style={{ fontSize: 12, color: '#7d7777' }}>3M</div>
                                    <span style={{ fontSize: 13, color: (item["three_month_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                      {item["three_month_percent"]}%</span>
                                  </div>
                                  }
                                  {!item["five_year_percent_show"] && item["six_month_percent_show"] &&
                                    <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                      <div style={{ fontSize: 12, color: '#7d7777' }}>6M</div>
                                      <span style={{ fontSize: 13, color: (item["six_month_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                        {item["six_month_percent"]}%</span>
                                    </div>
                                  }
                                  {item["one_year_percent_show"] && <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                    <div style={{ fontSize: 12, color: '#7d7777' }}>1Y</div>
                                    <span style={{ fontSize: 13, color: (item["one_year_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                      {item["one_year_percent"]}%</span>

                                  </div>
                                  }
                                  {item["three_year_percent_show"] && <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                    <div style={{ fontSize: 12, color: '#7d7777' }}>3Y</div>
                                    <span style={{ fontSize: 13, color: (item["three_year_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                      {item["three_year_percent"]}%</span>

                                  </div>
                                  }
                                  {item["five_year_percent_show"] && <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                    <div style={{ fontSize: 12, color: '#7d7777' }}>5Y</div>
                                    <span style={{ fontSize: 13, color: (item["five_year_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                      {item["five_year_percent"]}%</span>

                                  </div>
                                  }
                                </div>
                              </div>
                              <div class="col-1" style={{ borderLeft: '1px solid', marginTop: 24, height: 34, position: 'absolute', left: '79.5%' }}></div>
                              <div class="col">
                                <div style={{ fontFamily: 'sans-serif', borderBottom: '1px solid', borderBottomColor: 'lightgray', textAlign: 'center' }}>
                                  {/* SIP (Eg. &#8377;1000 monthly) */}
                                  SIP (Annualized %)
                                </div>
                                <div class="row">
                                  {['1y', '3y', '5y'].map((el, index) => (
                                    item['sip_returns_data'] && item['sip_returns_data'][el + '_percent_annualized'] && !isNaN(parseInt(item['sip_returns_data'][el + '_percent_annualized'])) &&
                                    <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                      <div style={{ fontSize: 12, color: '#7d7777' }}>{el.toUpperCase()}</div>
                                      <span style={{ fontSize: 13, color: (item['sip_returns_data'][el + '_percent_annualized'].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                        {item['sip_returns_data'][el + '_percent_annualized']}%</span>
                                    </div>
                                  )
                                  )}
                                  {/* {item['sip_returns_data'] && item['sip_returns_data']["1y_percent_annualized"] && !isNaN(parseInt(item['sip_returns_data']["1y_percent"])) &&
                                              <div style={{width: '30px', textAlign: 'center' }} class="col">
                                                <div style={{ fontSize: 12, color: '#7d7777' }}>1Y</div>
                                                <span style={{ fontSize: 13, color: (item['sip_returns_data']["1y_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                                  {item['sip_returns_data']["1y_percent"]}%</span>
                                              </div>
                                            }
                                            {item['sip_returns_data'] && item['sip_returns_data']["3y_percent"] && !isNaN(parseInt(item['sip_returns_data']["3y_percent"])) &&
                                              <div style={{width: '30px', textAlign: 'center' }} class="col">
                                                <div style={{ fontSize: 12, color: '#7d7777' }}>3Y</div>
                                                <span style={{ fontSize: 13, color: (item['sip_returns_data']["3y_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                                  {item['sip_returns_data']["3y_percent"]}%</span>
                                              </div>
                                            }
                                            {item['sip_returns_data'] && item['sip_returns_data']["5y_percent"] && !isNaN(parseInt(item['sip_returns_data']["5y_percent"])) &&
                                              <div style={{width: '30px', textAlign: 'center' }} class="col">
                                                <div style={{ fontSize: 12, color: '#7d7777' }}>5Y</div>
                                                <span style={{ fontSize: 13, color: (item['sip_returns_data']["5y_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                                  {item['sip_returns_data']["5y_percent"]}%</span>
                                              </div>
                                            } */}
                                </div>
                              </div>
                            </div>
                          </Col>
                        }

                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
                ))}
              </div>
            }

          </div>

          {this.state.show_modal_add_watchlist && (
            <Modal show={this.state.show_modal_add_watchlist}
              onHide={() => this.setState({
                show_modal_add_watchlist: false
              })}
              centered
              keyboard={false}
              fullscreen={false}
              backdrop="static"
            >
              <Modal.Header>
                <p>ADD NEW WATCH LIST</p>
              </Modal.Header>
              <Modal.Body>
                <p>
                  Type Your Preferred Name
                </p>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.modal_watchlist_name}
                    // disabled={

                    // }
                    maxLength="30"
                    onChange={(event) =>
                    // this.validEmail(event)
                    {
                      const targetValue = event.target.value; //.replace(/\s/g, '');
                      this.setState({
                        modal_watchlist_name: targetValue,
                        // modal_watchlist_confirm_enabled: !new RegExp(/\w([-_+.']*\w+)+@(\w(-*\w+)+\.)+[a-zA-Z]{2,4}$/gm).test(targetValue)
                      });
                    }
                    }
                  />
                </div>
                <p style={{ color: 'red' }}>{this.state["modal_pin_confirm_dialog_error_messagetext"]}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary"
                  disabled={this.state.modal_watchlist_confirm_enabled}
                  onClick={() => {
                    this.submitWatchList('add')
                  }}>
                  Submit
                </Button>
                <Button variant="primary"
                  onClick={() => this.setState({
                    show_modal_add_watchlist: false,
                    modal_watchlist_name: ''
                  })}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          )}

          {this.state.show_modal_scheme_advisor && (
            <Modal show={this.state.show_modal_scheme_advisor}
              onHide={() => this.setState({
                show_modal_scheme_advisor: false
              })}
              centered
              keyboard={false}
              fullscreen={false}
              backdrop="static"
            >
              <Modal.Header>
                <p>Choose your scheme advisor</p>
              </Modal.Header>
              <Modal.Body>

                <div className="form-group">
                  {this.state.list_scheme_advisors.map((item) => (
                    <div class="row">
                      <div class="col">
                        <Form.Check
                          type="radio"
                          label={
                            <span style={{ fontFamily: 'sans-serif' }}>
                              {item['description']}
                            </span>
                          }
                          name="pricing_plan"
                          id={item['_id']}
                          value={item['value']}
                          checked={
                            this.state.selected_scheme_advisor_id ===
                            item['_id']
                          }
                          onChange={(e) =>
                            this.handleRadioChange(
                              e, item, 'selected_scheme_advisor_id'
                            )
                          }
                        />
                        <hr />
                      </div>
                    </div>
                  ))
                  }

                </div>
                <p style={{ color: 'red' }}>{this.state["modal_pin_confirm_dialog_error_messagetext"]}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary"
                  disabled={this.state.selected_scheme_advisor_id.length < 1}
                  onClick={() => {
                    // this.displayRazorpay()
                    this.setState({
                      show_modal_scheme_advisor: false,
                      show_modal_advise_pricing: true
                    })
                  }}>
                  Continue
                </Button>
                <Button variant="primary"
                  onClick={() => this.setState({
                    show_modal_scheme_advisor: false,
                    modal_watchlist_name: ''
                  })}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          )}

          {this.state.show_modal_advise_pricing && (
            <Modal show={this.state.show_modal_advise_pricing}
              onHide={() => this.setState({
                show_modal_advise_pricing: false
              })}
              centered
              keyboard={false}
              fullscreen={false}
              backdrop="static"
            >
              <Modal.Header>
                <p>Choose your price plan</p>
              </Modal.Header>
              <Modal.Body>

                <div className="form-group">
                  {this.state.list_pricing.map((item) => (
                    <div class="row">
                      <div class="col">
                        <Form.Check
                          type="radio"
                          label={
                            <span style={{ fontFamily: 'sans-serif' }}>
                              {item['label']}
                            </span>
                          }
                          name="pricing_plan"
                          id={item['id']}
                          value={item['value']}
                          checked={
                            this.state.selected_pricing_id ===
                            item['id']
                          }
                          onChange={(e) =>
                            this.handleRadioChange(
                              e, item, 'selected_pricing_id'
                            )
                          }
                        />
                        <hr />
                      </div>
                    </div>
                  ))
                  }

                </div>
                <p style={{ color: 'red' }}>{this.state["modal_pin_confirm_dialog_error_messagetext"]}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary"
                  disabled={this.state.selected_pricing_id.length < 1}
                  onClick={() => {
                    this.displayRazorpay()
                  }}>
                  Continue
                </Button>
                <Button variant="primary"
                  onClick={() => this.setState({
                    show_modal_scheme_advisor: true,
                    show_modal_advise_pricing: false
                  })}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          )}

          {this.state.show_modal_scheme_advise &&
            <Modal show={this.state.show_modal_scheme_advise}
              onHide={() => this.setState({
                show_modal_scheme_advise: false
              })}
              centered
              keyboard={false}
              fullscreen={false}
              backdrop="static"
            >
              <Modal.Header>
                <p>EXPERT ADVISE</p>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">

                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary"
                  onClick={() => this.setState({
                    show_modal_scheme_advise: false
                  })}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          }

          {/* {this.state.show_modal_information &&
            <Modal show={this.state.show_modal_information}
              onHide={() => this.setState({
                show_modal_information: false
              })}
              centered
              keyboard={false}
              fullscreen={false}
              backdrop="static"
            >
              <Modal.Header>
                <p>Payment Confirmation</p>
              </Modal.Header>
              <Modal.Body>
                <div dangerouslySetInnerHTML={this.createDefaultMarkup()} />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary"
                  onClick={() => this.setState({
                    show_modal_information: false
                  })}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          } */}

        </div>
      </>
    );
  }

}

const mapStateToProps = (state) => ({
  result: state.master.result,
  order_result: state.order.order_result,
  payment_result: state.payment.payment_result,
  general_result: state.general.general_result
});

export default connect(mapStateToProps,
  { listWatchSchemes, addWatchList, modifyWatchList, createRazorPayOrder, loadSchemeAdviseList })
  (AdvisorAdviseList);

