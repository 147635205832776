import Emitter from '../utils/emitter';

const setUserData = (result, mode) => {
  
  if(mode === 'SAVE') {

    console.log(result);
    if (result) {

      localStorage.setItem('profileData', JSON.stringify(result['profileData']));       
      Emitter.emit('HEADER1', result['profileData']);

      // if(result['profileData'])
      //   localStorage.setItem('profileData', JSON.stringify(result['profileData'])); 
      //  else 
      if(result['masterList'])
        localStorage.setItem('masterList', JSON.stringify(result['masterList']));
      
    }
    // else
    //   Emitter.emit('HEADER1', {});

  }
  else if(mode === 'MASTER') 
  {
    
    if(result['profileData'])
      Emitter.emit('HEADER1', result['profileData']);
    
    if (result['masterList']) {
      if(result['masterList']['amc_filter_list'])
      localStorage.setItem('masterList', JSON.stringify(result['masterList']['amc_filter_list']));
    }

  }
  else {
    // localStorage.removeItem('profileData');
    localStorage.removeItem('masterList');
  }

};

export default setUserData;
