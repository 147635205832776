import React, { } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import {Form,Modal} from 'react-bootstrap';
import { connect } from "react-redux";
import '../../../css/bankdetails.css'; 
import { verifyBank, verifyStage, loadStageInfo } from '../../../jsx/actions/verify';
import { Redirect } from 'react-router-dom';
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import Popup from '../popup'

class BankAutopay extends React.Component {
    constructor(props) {
      super(props);
      
      this.state = {
        suggestions: [],
        text: '',
        bank_account_number: '',
        bank_ifsc_code: '',
        is_stage_verified: false,
        is_verify_enabled: false,
        is_confirm_chk_enabled: false,
        is_verified_editable: false,
        selectedFile: null,
        src_selectedFile: null,
        bank_information: [],
        bank_attachment_list: [],
        file_bank_tiff: null,
        show_popup: false,
        pop_messagetext: '',
      }
    }

    componentDidMount()
    {
       this.onLoadStageInfo();
    }

    closePopup = () => {
        this.setState({
            show_popup: false,
            pop_messagetext: ''
        })
    }
  
  suggestionSelected(value) {
    this.setState(() => ({
      text: value,
      suggestions: [] 
    }));
  }
  
  renderSuggestions() {
    const {suggestions} = this.state;
    if(suggestions.length === 0) {
      return null;
    }
    return (
      <div className="srchList">
        <ul>
          {suggestions.map((item) => <li onClick={() => this.suggestionSelected(item)} key={item}>
              
              {item && item.length < 0  ? "No Record Found"  : item }
              
              </li>)}
        </ul>
      </div>
    );
  }

  handleConfirmCheck = (event) => {
      this.setState({is_confirm_chk_checked: !this.state.is_confirm_chk_checked})
  }
  

onLoadStageInfo = () => {

    this.props.loadStageInfo({ stage_name: 'bank'}).then(response => {

        if(this.props.bank_result['code'] !== '000')
            alert(this.props.bank_result['messageText']);
        else {
            
            if(this.props.bank_result['state_parameters'])
                this.setState(this.props.bank_result['state_parameters']);
            else
              this.setState({ 
                  is_stage_verified: this.props.bank_result['is_stage_verified'], 
                  is_verify_enabled: this.props.bank_result['is_verify_enabled'],
                  is_confirm_chk_enabled: this.props.bank_result['is_confirm_chk_enabled'],
                  is_verified_editable: this.props.bank_result['is_verified_editable'],
                  bank_information: this.props.bank_result['id_information'],
                  bank_attachment_list:  this.props.bank_result['attachment_list'],
              });
        }

    })
    .catch(err => {
      alert(err)
    });
 
    
};

changeHandler = (event) => {


  if(event.target.files[0] && (event.target.files[0].type === 'image/png' || 
     event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg') ) {
      this.setState({
        selectedFile: event.target.files[0],
        src_selectedFile: URL.createObjectURL(event.target.files[0])
      });

      {
          const file = event.target.files[0];
    
          const reader = new FileReader();
          reader.onloadend = () => {
            this.dataURLtoFile(reader.result, 'bse');
          };
          reader.readAsDataURL(file);
      }
  }
  else {
      this.setState({
          show_popup: true,
          pop_messagetext: 'Upload file format should be of PNG, JPEG, JPG only'
      });
  }

}

dataURLtoFile(dataurl, filename) {
  
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
      
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  
  this.setState({
    file_bank_tiff:  new File([u8arr], (filename + "_bank.tiff"), {type: mime.split('/')[0] + '/tiff'})
  });
  
}


onVerify = () => {

    const {  otp } = this.state;
    
    let body_parameters = {
      client_bank_account_number_1: this.state.bank_account_number,
      client_ifsc_code_1: this.state.bank_ifsc_code
    };
    
    this.props.verifyBank(body_parameters).then(response => {

        if(this.props.bank_result['code'] !== '000')
            alert(this.props.bank_result['messageText']);
        else {

          if(this.props.bank_result['state_parameters'])
              this.setState(this.props.bank_result['state_parameters']);
          else
            this.setState({ 
                is_stage_verified: this.props.bank_result['is_stage_verified'], 
                is_verify_enabled: this.props.bank_result['is_verify_enabled'],
                is_confirm_chk_enabled: this.props.bank_result['is_confirm_chk_enabled'],
                is_verified_editable: this.props.bank_result['is_verified_editable'],
                bank_information: this.props.bank_result['id_information'],
                bank_attachment_list:  this.props.bank_result['attachment_list'], 
            });
        }

    })
    .catch(err => {
      alert(err)
    });
 
    
};

onVerifyStage = () => {
    
    let formData = new FormData();
    let body_parameters = { verify_stage: 'bank'};

    if(this.state.selectedFile) {

      formData.append("upload_file", this.state.selectedFile);
      if(this.state.file_bank_tiff)
        formData.append("upload_file", this.state.file_bank_tiff);

      formData.append("id_type", 'bank');
      formData.append("folder_name", 'bank');
      formData.append("attachment_category", 'bank');
      formData.append("verify_stage", "bank")

    }
    
    this.props.verifyStage(this.state.selectedFile ? formData : body_parameters, 'bank', response => {
            
        // this.setState({ 
        //     is_verify_enabled: false, 
        //     is_confirm_chk_enabled: true,
        //     pan_information: this.props.pan_result['id_information'] 
        // });

    });
    
};
  
    render(){
        const { text } = this.state;

        if(this.props.bank_result && this.props.bank_result['bank_stage_verified'])
           return <Redirect to={this.props.bank_result['navigateScreen']} />; 

    return (
        <>        
        <Header1 />
        <Sidebar />
              <div className="authincation section-padding">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-xl-8 col-md-12">
                            <div className="mini-logo text-center my-5">
                                {/* <Link to={'./'}><img src={require('./../../../images/logo.png')} alt="" /></Link> */}
                            </div>
                            <div className="auth-form card" style={{marginTop:100}}>
                                <div className="card-body">
                                    <h3 className="text-center">Bank Account Number</h3>
                                    <p className="text-center mb-5">Bank Account number is compulsory for investing on mutual funds</p>
                                    <form action="#">
                                      
                                    {(this.state.is_verify_enabled || this.state.is_verified_editable ) &&
                                    <div>
                                        <div className="form-group">
                                            <label>Your Bank Account Number</label>
                                            <input type="text" className="form-control text-center font-weight-bold"
                                            value={this.state.bank_account_number}
                                            onChange={event=> 
                                              this.setState({bank_account_number: event.target.value })}
                                              
                                                />

                                        </div>
                                        <div className="form-group">
                                            <label>IFSC Code</label>
                                            
                                       <input value={this.state.bank_ifsc_code} 
                                        onChange={event=> 
                                          this.setState({bank_ifsc_code: event.target.value })}
                                       
                                        className="form-control text-center font-weight-bold"
                                         type="text"  />
                                        </div>
                                        <div className="col-md-12 justify-content-md-center">
                                          {this.renderSuggestions()}
                                        </div>
                                        </div>
                                        }


                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
   
                                        </Form.Group>
                                      { /*this.state.bank_information.length === 0*/
                                      (this.state.is_verify_enabled || this.state.is_verified_editable ) &&
                                        <div className="text-center">
                                            <Button
                                                className="btn btn-success btn-block"
                                                variant="success"
                                                onClick={this.onVerify}

                                            >Verify</Button>
                                                {/* <Link to={'./otp-1'} type="submit" className="btn btn-success btn-block">Verify</Link> */}
                                        </div>
                                        }

                                        {
                                            this.state.bank_information.length > 0 &&
                                            <p className="text-center mb-5">Recently uploaded information</p>
                                        }
                                        <br/>
                                        {this.state.bank_information.map((el, i) => 
                                            <div key={i} className="form-group">
                                                <label>{el['attribute']} :
                                                    <label>
                                                        {el['text']}
                                                    </label>
                                                </label>
                                            </div>         
                                            )
                                        }

                                        {
                                            <div className="text-center">
                                                {(this.state.bank_attachment_list || []).map((el, i) => 
                                                <div key={i} className="form-group">
                                                    <img src={el}/>
                                                </div>         
                                                )
                                                }
                                            </div>
                                        }

                                        {
                                        !this.state.is_stage_verified && this.state.is_confirm_chk_enabled &&
                                       
                                          <div className="text-center">
                                          <Form.Label>Kindly upload your passbook or valid cheque (File format should be png or jpeg)</Form.Label>
                                                      <Form.Control type="file" onChange={this.changeHandler} />
                                          </div>
                                        }
                                        

                                        { (this.state.is_verify_enabled || this.state.is_verified_editable ) &&
                                            this.state['src_selectedFile'] &&
                                            <div className="text-center">
                                                <img src={this.state['src_selectedFile']}/>
                                            </div>
                                        }

                                        {
                                            // !this.state.is_stage_verified && 
                                            this.state.is_confirm_chk_enabled && 
                                            ((this.state.bank_attachment_list || []).length > 0 || 
                                              this.state.selectedFile ) && 
                                            <div className="text-center">
                                                <Button
                                                className="btn btn-success btn-block"
                                                variant="success"
                                                onClick={this.onVerifyStage}>Confirm</Button>
                                            </div>
                                        }
                                        {
                                            this.state.is_stage_verified &&
                                            <div className="text-center">
                                                <Link to={'./personalVerification'} type="submit" 
                                                    className="btn btn-success pl-5 pr-5">Next</Link>
                                            </div>
                                        }

{this.state.show_popup && (
                  <Modal show={this.state.show_popup} onHide={() => this.closePopup()}>
                   <Modal.Header>
                      
                   </Modal.Header>
                   <Modal.Body>
                   
                   <p>{this.state["pop_messagetext"]}</p>
                                                       
                   </Modal.Body>
                   <Modal.Footer>
                       <Button variant="primary" onClick={() => this.closePopup()}>
                           Close
                       </Button>
                   </Modal.Footer>
               </Modal> 
               )}
                   

                                        {/* {this.state.show_popup && <Popup
                                            content={<>
                                                <p>
                                                    {this.state["pop_messagetext"]}
                                                </p>
                                            </>}
                                            handleClose={() => this.closePopup()}
                                        />} */}

                                    </form>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}}


const mapStateToProps = state => ({
  bank_result: state.verify.bank_result
});

export default connect(mapStateToProps, {  verifyBank, verifyStage, loadStageInfo })
  ( BankAutopay );