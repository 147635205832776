const validate = (inputs) => {
   
   var errors = {};
   var list_errors = {};

   console.log(inputs);
   
   if (!inputs.marital_status) {
       errors.marital_status = 'Please select Marital Status';
       //errors = [errors, false]
       // return errors;
   }   
   if (inputs['address_information']) {
       
    if(inputs['address_information']['city'].length < 3) {
        errors.city = 'Enter valid city name';
        //errors = [errors, false]
        // return errors;
    }   
    if ((inputs['address_information']['state'].length < 3) ) {
        errors.state = 'Enter valid state name';
        //errors = [errors, false]
        // return errors;
    }   
    if ((inputs['address_information']['pincode'].length < 3) ) {
        errors.pincode = 'Enter valid pin code';
        //errors = [errors, false]
        // return errors;
    }

   } 
    
    return errors;
}
const validateincome = (inputs) => {
    
   var errors = {};
    console.log(inputs);

if ((inputs.source_of_wealth || '').length < 2) {
    errors.source_of_wealth = 'Please select Source of wealth';
    // errors = [errors,false]
    // return errors
}
if ((inputs.occupation || '').length < 2) {
    errors.occupation = 'Please select Occupation';
    // errors = [errors,false]
    // return errors
} 

if ((inputs.annual_income || '').length < 2) {
    errors.annual_income = 'Please select annual income';
    // errors = [errors,false]
    // return errors
}

   return errors;
}
const validatenominee = (inputs) => {
  
   console.log(inputs);
   var errors = {};

   if ((inputs.nominee_information['relationship'] || '').length < 2) {
       errors.nominee_relationship = 'Please select relationship';
   }
   if ((inputs.nominee_information['name'] || '').length < 5) {
        errors.nominee_name = 'Please enter nominee name with minimum 7 characters';
   }
   if ((inputs.nominee_information['poi'] || '').length < 2) {
         errors.nominee_poi = 'Please select proof of identity';
   }
   if((inputs.nominee_information['poi'] || '').toLowerCase() !== 
      (inputs.nominee_information['id_information']['id_type'] || '').toLowerCase()
    && inputs.nominee_information['is_validated'])
   {
        errors.nominee_validated = 'Please uplaod valid proof of identity';
   }
//    if ((inputs.nominee_information_relationship || '').length < 2) {
//         errors.marital_status = 'Please select relationship';
//    }

   return errors;
}

const imageverification = (inputs) => {
    //Email errors
   console.log(inputs);
   console.log(!inputs.gender)
   var errors = {};
   if (!inputs.marital_status) {
       errors.marital_status = 'Please select Marital Status';
        errors = [errors,false]
       return errors
   }   
//    if (!inputs.first_name) {
//     errors.first_name = 'Please enter first name';
//     errors = [errors,false]
//     return errors
// } 
// if (!inputs.last_name) {
//     errors.last_name = 'Please enter last name';
//     errors = [errors,false]
//     return errors
// } 
// if (!inputs.do_birth) {
//     errors.do_birth = 'Please enter a valid date of birth';
//      errors = [errors,false]
//     return errors
// } 
if (!inputs.marital_status) {
    errors.marital_status = 'Please select Marital Status';
    errors = [errors,false]
    return errors
} 
if (!inputs.occupation) {
    errors.occupation = 'Please select Occupation';
    errors = [errors,false]
    return errors
} 
if (!inputs.source_of_wealth) {
    errors.source_of_wealth = 'Please select Source of wealth';
    errors = [errors,false]
    return errors
}
if (!inputs.annual_income) {
    errors.annual_income = 'Please select annual income';
    errors = [errors,false]
    return errors
}
if (!inputs.is_income_outside_india) {
    errors.is_income_outside_india = 'Please select';
    errors = [errors,false]
    return errors
}
if (!inputs.is_political_india) {
    errors.is_political_india = 'Please select';
    errors = [errors,false]
    return errors
}
if (!inputs.is_political_foreign) {
    errors.is_political_foreign = 'Please select ';
    errors = [errors,false]
    return errors
}


   //Password Errors
//  console.log(inputs.client_primary_info['client_primary_first_name'])
//    if(!inputs.client_primary_info['client_primary_first_name']){
//        errors.client_primary_info['client_primary_first_name'] = 'Please Enter City Name'
//    }
   return errors;
}


export  {validate, validateincome, validatenominee, imageverification};