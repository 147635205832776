import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Dropdown, DropdownButton, Form, Alert, Modal, Button, Spinner, Row, Col, Container, } from "react-bootstrap";
import { connect } from "react-redux";
import Select from 'react-select';
import {
  loadOrderList,
  loadPaymentResponse,
  cancelOrder,
  createSwpOrder,
  twoFactorAuthentication,
  cancelSwpOrder
} from "../../actions/orders";
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
//import Spinner from "../../layout/spinner";
import { TabPanel, a11yProps } from "../../shared/Tabs";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Table, Collapse } from "react-bootstrap";
import { Box, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import OTPInput, { ResendOTP } from "otp-input-react";

class OrderList extends React.Component {

  listener = null;

  constructor(props) {
    super(props);
    this.state = {
      nav: false,
      tab_orders: 0,
      tab_single_order_child: 0,
      cardopen: false,
      list_single_orders: [],
      _list_single_orders: [],
      status_filter_list: [],
      status_filter_selected: null,

      swp_order: {
        display_text: '',
        swp_no_of_installments: '',
        swp_amount: '',
        swp_date: new Date(new Date().setDate(new Date().getDate() + 8)),
        swp_frequency: '',
        is_otp_enabled: false,
        two_factor_authentication: {
          mobile_otp: '',
          email_otp: '',
          twofactor_token_id: ''
        }
      },

      swp_minimum_date: new Date(new Date().setDate(new Date().getDate() + 8)),
      swp_maximum_date: new Date().setDate(new Date().getDate() + 40),
      swp_exclude_date_list: [],

      bank_information: {},
      is_verify_enabled: false,
      is_confirm_chk_enabled: false,
      is_verified_editable: false,
      esignkyc_information: {},
      esignkyc_attachment_list: {},
      is_esign_verified: false,
      is_esign_button_disabled: false,
      isloading: false,
      show_popup: false,
      popup_headerText: '',
      popup_messageText: '',

      show_swp_popup: false,
      popup_swp: {},

      modal_orderinfo: {},

      show_modal_pin_confirm_dialog: false,
      modal_pin_confirm_action: '',
      modal_pin_confirm_value: '',
      modal_pin_confirm_dialog_messagetext: 'Enter your 4 digit pin to complete your action'
    };

  }

  componentDidMount() {

    window.addEventListener("scroll", () => {

      if (document.getElementById("navbar-container") &&
        document.getElementById("navbar") &&
        document.getElementById("navbar").classList) {
        const topBorder = document
          .getElementById("navbar-container")
          .getBoundingClientRect().top;

        topBorder >= 0
          ? document.getElementById("navbar").classList.remove("fixed")
          : document.getElementById("navbar").classList.add("fixed");
      }

    });

    // window.addEventListener("scroll", this.handleScroll);
    this.setState({ isloading: true });
    this.onLoadOrderList(0);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', null);
  }

  handleScroll = () => {

    if (window.pageYOffset > 140) {
      if (!this.state.nav) {
        this.setState({ nav: true });
      }
    } else {
      if (this.state.nav) {
        this.setState({ nav: false });
      }
    }
  }

  handleConfirmCheck = (event) => {
    this.setState({
      is_confirm_chk_checked: !this.state.is_confirm_chk_checked,
    });
  };

  onLoadOrderList = (order_type) => {

    order_type = order_type === 0 ? "single" : 0; //(order_type === 1 ? 'sip' : 'xsip')

    this.props
      .loadOrderList({
        order_type: order_type,
      })
      .then((response) => {
        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);
        else {

          if (this.props.order_result["result"]) {

            this.setState({
              bank_information: this.props.order_result["bank_information"],
              ["list_" + order_type + "_orders"]: this.props.order_result["result"],
              ["_list_" + order_type + "_orders"]: this.props.order_result["result"],
              status_filter_list: this.props.order_result['status_filter_list'],
              isloading: false
            });

          }
          else
            this.setState({
              ["list_" + order_type + "_orders"]: [], ['_list_' + order_type + '_orders']: [],
              isloading: false
            });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleChangeStatus = (selectedOption) => {

    this.setState(
      {
        status_filter_selected: selectedOption
      },
      () => {

        let _filtered_list_single_orders = [];

        selectedOption.forEach(item => {
          _filtered_list_single_orders = _filtered_list_single_orders.concat(
            this.state.list_single_orders.filter(el => {
              return el[item['value']] === true;
            })
          );
        });

        this.setState({
          _list_single_orders: selectedOption.length > 0 ?
            _filtered_list_single_orders : this.state.list_single_orders
        });

      });

  };

  gotoCheckOutPage(request_mode, selected_order) {

    this.setState({ isloading: true });

    if ((selected_order["mf_order_action_list"] || []).length > 0) {

      selected_order["order_information"] =
        selected_order['mf_order_action_list'][selected_order["mf_order_action_list"].length - 1];
    }

    selected_order["bank_information"] = this.state.bank_information;
    selected_order["request_mode"] = request_mode;
    this.setState({ isloading: false });

    /*
    if(request_mode === 'paynow') {
        const win = window.open("/orderEditCheckout/" + request_mode.toLowerCase(), "_blank");
        win.focus();
    }
    else*/
    this.props.history.push({
      pathname: "/orderEditCheckout/",
      state: {
        request_mode: request_mode,
        selected_order: selected_order,
      },
    });


  }

  routeChange = (type, item, mode) => {

    /*if((window.matchMedia && window.matchMedia("(max-width: 480px)").matches) ){
        this.props.history.push({ 
          pathname: "/chartsmobile", 
          state: { param_data: {
              scheme_isin: item['scheme_isin'],
              scheme_code: item['scheme_code'], 
              scheme_name: item['scheme_name'] 
            }
          }
        });
    }
    else */
    console.log(item)
    {

      const win = window.open("/sipConsolidatedStatement/" + item['mf_order_registration_no'] + '/' + item['mf_order_number'] + '/' + item['mf_order_type'].toLowerCase(), "_blank");
      win.focus();

    }

  };

  showChildInformation(order_type, order, request_type) {

    if (request_type === "view") {

      this.setState({ isloading: true });

      this.props
        .loadPaymentResponse({
          request_mode: "order_payment_status",
          order_type: "single",
          order_number: order["mf_order_number"] || "",
          order_id: order["_id"],
          selected_order: order,
        })
        .then((response) => {

          if (this.props.order_result) {

            if (this.props.order_result["code"] !== "000") {
              alert(this.props.order_result["messageText"]);
              this.setState({ isloading: false });
            }
            else {

              let _result = this.props.order_result["result"];
              if (_result) {

                let _list = this.state['list_' + order_type + '_orders'];
                let _new_list = [];

                _list.forEach(el => {

                  if (el['mf_order_registration_no'] === _result['mf_order_registration_no']) {
                    _result['show_child'] = true;
                    _new_list.push(_result);
                  }
                  else {
                    el['show_child'] = false;
                    _new_list.push(el);
                  }
                });

                let _filtered_list_xsip_orders = (this.state.status_filter_selected || []).length > 0 ?
                  [] : _new_list;

                (this.state.status_filter_selected || []).forEach(item => {
                  _filtered_list_xsip_orders = _filtered_list_xsip_orders.concat(
                    _new_list.filter(el => {
                      return el[item['value']] === true;
                    })
                  );
                });

                this.setState({
                  isloading: false,
                  modal_orderinfo: _result,
                  ["list_" + order_type + "_orders"]: _new_list,
                  ["_list_" + order_type + "_orders"]: _filtered_list_xsip_orders
                });

              }

            }

          }

        })
        .catch((err) => {
          alert(err);
        });
    } else {

      let _list = this.state["list_" + order_type + "_orders"];

      _list.forEach((el) => {
        if (
          order["mf_order_registration_no"] === el["mf_order_registration_no"]
        )
          el["show_child"] = false; // !el['show_child'];
      });

      this.filterListBasedOnStatus(_list, order_type);

    }
  }

  showSwpInformation(order_type, order, request_type) {

    let _result = this.state["list_" + order_type + "_orders"]; //this.props.order_result["result"];

    if (_result) {

      let _list = _result; //this.state["list_" + order_type + "_orders"];

      _list.forEach((el) => {
        if (order["order_id"] === el["order_id"]
        ) {
          // el["show_swp"] = (request_type === 'view'); //true;
          this.setState({
            show_swp_popup: (request_type === 'view'),
            popup_swp: (request_type === 'view') ? el : {},
            swp_order: {
              ...this.state.swp_order,
              is_otp_enabled: false,
              two_factor_authentication: {
                mobile_otp: '',
                email_otp: '',
                twofactor_token_id: ''
              }
            },
          });
        }
      });

      this.filterListBasedOnStatus(_list, order_type);

    }
  }

  showChildHistoryInformation(order_type, order, request_type) {

    if (request_type === "view") {
      this.setState({ isloading: true });
    }

    let _list = this.state["list_" + order_type + "_orders"];

    _list.forEach((el) => {
      if (order["mf_order_registration_no"] === el["mf_order_registration_no"])
        el["show_child_history"] = request_type === "view" ? true : false;
    });

    this.setState({
      isloading: false,
      ["list_" + order_type + "_orders"]: _list,
    });
  }

  OnCancelOrder = (order_type, order) => {

    this.setState({ isloading: false });
    this.props
      .cancelOrder({
        order_type: order_type,
        client_info: order,
      })
      .then((response) => {
        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);
        else {
          if (this.props.order_result["result"]) {
            this.filterListBasedOnStatus(this.props.order_result, order_type, 'bank_information');
          }
          else {
            this.setState({
              ['list_' + order_type + '_orders']: [],
              ['_list_' + order_type + '_orders']: []
            });
          }
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleChangeSelect = (selectedOption, order, input, category) => {

    if (input === 'swp_frequency') {

      if (category === 'swp') {

        this.setState({
          swp_order: {
            ...this.state.swp_order,
            swp_frequency: selectedOption.value
          }
        });

      }

    }
    else if (input === 'swp_no_of_installments') {

      if (category === 'swp')
        this.setState({
          swp_order: {
            ...this.state.swp_order,
            swp_no_of_installments: selectedOption.value
          }
        });

    }

  }

  OnCreateSwpOrder = (order_type, order) => {

    console.log(this.state.swp_order);

    this.setState({ isloading: false });

    this.props
      .createSwpOrder({
        order_type: order_type,
        client_info: Object.assign(
          { swp_order: this.state.swp_order }, order
        ),
      })
      .then((response) => {

        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);
        else {

          let _result = this.props.order_result["result"];
          if (_result) {

            this.setState({
              show_swp_modal: false,
              popup_swp: {},
              swp_order: {
                ...this.state.swp_order,
                is_otp_enabled: false,
                two_factor_authentication: {
                  mobile_otp: '',
                  email_otp: '',
                  twofactor_token_id: ''
                }
              },
              modal_orderinfo: {}
            });

            let _list = this.state['list_' + order_type + '_orders'];
            let _new_list = [];

            _list.forEach(el => {

              if (el['tab_single_order_child'] === _result['tab_single_order_child']) {
                _result['show_swp'] = false;
                _new_list.push(_result);
              }
              else
                _new_list.push(el);

            });

            this.filterListBasedOnStatus(_new_list, order_type);

          }

        }
      })
      .catch((err) => {
        alert(err);
      });

  };

  OnCancelSwpOrder = (order_type, order) => {

    this.setState({ isloading: true });

    this.props
      .cancelSwpOrder({
        order_type: order_type,
        client_info: Object.assign(
          { swp_order: this.state.swp_order }, order
        ),
      })
      .then((response) => {
        if (this.props.order_result["code"] !== "000") {
          this.setState({ isloading: false });
          alert(this.props.order_result["messageText"]);
        }
        else {

          let _result = this.props.order_result["result"];
          if (_result) {

            let _list = this.state['list_' + order_type + '_orders'];
            let _new_list = [];

            _list.forEach(el => {

              _new_list.push(el);
              if (el['tab_single_order_child'] === _result['tab_single_order_child']) {
                _result['show_swp'] = false;
                _new_list.push(_result);
              }
            });

            this.filterListBasedOnStatus(_new_list, order_type);

          }

        }
      })
      .catch((err) => {
        alert(err);
      });

  };

  showTransactionHistory(item) {

    let _html = `<div>we will show you soon</div>`;

    this.setState({
      show_popup: true,
      popup_headerText: 'Transaction History',
      popup_messageText: item['modal_transaction_history_html'] || _html,
    })

  }

  filterListBasedOnStatus = (order_result, order_type, attribute_name) => {

    let _new_list = order_result["result"] || (order_result || []);

    let _filtered_list_xsip_orders = (this.state.status_filter_selected || []).length > 0 ?
      [] : _new_list;

    (this.state.status_filter_selected || []).forEach(item => {
      _filtered_list_xsip_orders = _filtered_list_xsip_orders.concat(
        _new_list.filter(el => {
          return el[item['value']] === true;
        })
      );
    });

    let _state_parameters = {
      isloading: false,
      ['list_' + order_type + '_orders']: _new_list,
      ['_list_' + order_type + '_orders']: _filtered_list_xsip_orders
    }

    if (attribute_name)
      _state_parameters[[attribute_name]] = order_result[attribute_name];

    this.setState(_state_parameters);

  }

  closePopup = () => {
    this.setState({
      show_popup: false,
      popup_headerText: '',
      popup_messageText: ''
    });
  };

  createDefaultMarkup(text) {
    return {
      __html: text,
    };
  }

  sendOTP = () => {

    this.setState({ isloading: true });

    this.props
      .twoFactorAuthentication({
        client_info: {
          two_factor_authentication: this.state.swp_order['two_factor_authentication'],
          request_action: 'swp registration',
          is_otp_email_required: false
        },
      })
      .then((response) => {

        if (this.props.order_result["code"] !== "000") {
          this.setState({ isloading: false });
          alert(this.props.order_result["messageText"]);
        }
        else {

          let _result = this.props.order_result; //["result"];
          console.log(_result);

          if (_result && _result['twofactor_id']) {

            this.setState({
              isloading: false,
              swp_order: {
                ...this.state.swp_order,
                is_otp_enabled: true,
                twofactor_token_id: {
                  mobile_otp: '',
                  email_otp: '',
                  twofactor_token_id: _result['twofactor_id']
                }
              }
            });

          }

        }
      })
      .catch((err) => {
        alert(err);
      });

  }

  render() {

    const renderButton = buttonProps => {
      return (
        <div class="row">
          <div class="col">
            <button onClick={this.sendOTP} {...buttonProps}
              class={buttonProps.remainingTime !== 0 ? `btn btn-outline-secondary` : `btn btn-outline-success`}>
              {`Resend OTP`}
            </button>
          </div>
          <div class="col" >
            {buttonProps.remainingTime !== 0
              ? <span>Didn't receive the otp ?
                <br />
                <p>You can resend otp in
                  <span style={{ fontWeight: 'bold', color: 'green' }}>{' '}{buttonProps.remainingTime}</span> secs.
                </p>
              </span>
              : ``}
          </div>
        </div>
      );
    };
    const renderTime = () => React.Fragment;

    return (
      <>
        <Header1 />
        <Sidebar />
        <div id="navbar-container">
          <div id="navbar" >
            <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
              <Container >
                <Row >
                  <Col>
                    <div className="card-header">
                      <h5 > Lumpsum Order list</h5>
                    </div>
                  </Col>
                  <Col style={{ marginTop: 10 }}>
                    <div
                      style={{ width: '270px' }} >
                      <Select
                        placeholder={`Filter based on order status`}
                        isMulti
                        value={this.state.status_filter_selected}
                        onChange={this.handleChangeStatus}
                        options={this.state.status_filter_list}
                      />
                    </div>
                  </Col>
                  <Col style={{ marginTop: 10 }}>
                    <button type="button"
                      className="btn-sm btn-success"
                      style={{ color: 'white', width: '140px' }}
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/stpList",
                        })
                      }}
                    >
                      Go to SWP List
                      <span>{`  `}<i
                        className="fa fa-angle-right"></i></span>
                    </button>
                  </Col>
                </Row>
              </Container>
              <hr />
            </div>
          </div>
        </div>
        <div className="content-body" >

          <div className="container" style={{ marginTop: 30 }}>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  {/* <Container class="card-header">
                    <Row fixed="top" >
                      <Col>
                        <div className="card-header">
                          <h6 style={{ marginLeft: 20 }}> Single/Lumpsum Order list</h6>
                        </div>
                      </Col>
                      <Col style={{ marginTop: 10 }}>
                        <div
                          style={{ width: '270px' }} >
                          <Select
                            placeholder={`Filter based on order status`}
                            isMulti
                            value={this.state.status_filter_selected}
                            onChange={this.handleChangeStatus}
                            options={this.state.status_filter_list}
                          />
                        </div>
                      </Col>
                      <Col style={{ marginTop: 10 }}>
                        <button type="button"
                          className="btn-sm btn-success"
                          style={{ color: 'white', width: '140px' }}
                          onClick={() => {
                            this.props.history.push({
                              pathname: "/stpList",
                            })
                          }}
                        >
                          Go to SWP List
                          <span>{`  `}<i
                            className="fa fa-angle-right"></i></span>
                        </button>
                      </Col>
                    </Row>
                  </Container> */}
                  {/* <div className="card-body"> */}

                  {this.state.isloading && (
                    <Spinner
                      animation="border"
                      variant="dark"
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        zIndex: 899,
                      }}
                    />
                  )}


                  {(this.state.list_single_orders || []).length < 1 && (
                    <Alert variant="dark text-center">
                      <Alert.Heading>
                        {this.state.isloading
                          ? "We are fetching your orders"
                          : "No orders found"}
                      </Alert.Heading>
                    </Alert>
                  )}


                  <table class="table">
                    {/* {(this.state.list_single_orders || []).length > 0 && (
                        <thead>
                          <tr>
                            <th style={{ fontSize: 12 }}>#</th>
                            <th style={{ fontSize: 12 }}>Order Information</th>

                          </tr>
                        </thead>
                      )} */}

                    {(this.state._list_single_orders || []).map(
                      (item, index) => (
                        <tbody key={index}>

                          <tr>
                            {/* <td style={{ width:7, fontSize: 12, color: "#000" }}>{index + 1}</td> */}

                            <td >

                              {(item["mf_order_number"] || '').length < 4 ? (

                                <p>
                                  <span style={{
                                    color: 'InfoText',
                                    backgroundColor: '#dae3e6',
                                    fontWeight: 500,
                                    padding: 4, paddingLeft: 8, paddingRight: 8, margin: 1, borderRadius: 4
                                  }}>
                                    Registration Number:{" "}
                                    {item["mf_order_registration_no"]}

                                  </span>
                                </p>
                              ) : (
                                <div>
                                  <ul className="list-horizontal-nobullet" >
                                    <li>

                                      <span
                                        style={{
                                          // backgroundColor: '#e8e7e6',
                                          fontWeight: 500,
                                          paddingLeft: 4, paddingRight: 4, margin: 4, borderRadius: 4
                                        }}
                                      >
                                        Order# {item["mf_order_number"]}
                                      </span>
                                      {/* {
                                        (item['mf_order_folio_no'] || '').length > 6 &&
                                        <span
                                          style={{
                                            backgroundColor: '#e8e7e6',
                                            fontWeight: 500,
                                            paddingLeft: 4, paddingRight: 4, margin: 4, borderRadius: 4
                                          }}
                                        >
                                          Folio#  {item['mf_order_folio_no']}
                                        </span>
                                      }
                                      {
                                        item['mf_order_swp_enabled'] &&

                                        <span style={{
                                          fontFamily: 'sans-serif', color: 'InfoText',
                                          backgroundColor: '#ec9fa4', fontWeight: 500,
                                          padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                        }} >
                                          SWP Enabled : {item["mf_order_swp_information"]['swp_frequency']} OF
                                          &#8377;{item["mf_order_swp_information"]['swp_amount']}
                                        </span>
                                      } */}

                                    </li>

                                    {
                                      (item['mf_order_folio_no'] || '').length > 6 &&
                                      <li>
                                        <span
                                          style={{
                                            backgroundColor: '#e8e7e6',
                                            fontWeight: 500,
                                            paddingLeft: 4, paddingRight: 4, margin: 4, borderRadius: 4
                                          }}
                                        >
                                          Folio#  {item['mf_order_folio_no']}
                                        </span>
                                      </li>
                                    }
                                    {
                                      item['mf_order_swp_enabled'] &&
                                      <li>
                                        <span style={{
                                          fontFamily: 'sans-serif', color: 'InfoText',
                                          backgroundColor: '#ec9fa4', fontWeight: 500,
                                          padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                        }} >
                                          SWP Enabled : {item["mf_order_swp_information"]['swp_frequency']} OF
                                          &#8377;{item["mf_order_swp_information"]['swp_amount']}
                                        </span>
                                      </li>
                                    }
                                    <li>
                                      <span
                                        style={{
                                          fontSize: 13,
                                          marginTop: 5
                                        }}
                                      >
                                        {item["mf_order_scheme_name"] || ""}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              )}
                              {/* <p
                                style={{
                                  fontSize: 13,
                                  marginTop: 5
                                }}
                              >
                                {item["mf_order_scheme_name"] || ""}
                              </p> */}

                              <p
                                style={{
                                  fontSize: 12
                                }}
                              >
                                {" "}
                              </p>
                              {
                                item['mf_order_message_is_showed'] &&
                                <p
                                  style={{
                                    fontSize: 12
                                  }}
                                >
                                  <span style={{ width: 100 }}>
                                    {item["mf_order_message"]}
                                  </span>
                                  {
                                    !item['mf_order_is_allotted'] &&

                                    <Button className="text-success"
                                      variant="link" onClick={() => {
                                        this.setState({
                                          show_popup: true,
                                          popup_headerText: item['modal_rta_validation_information']['header'],
                                          popup_messageText:
                                            item['modal_rta_validation_information']['body'],
                                        })
                                      }}>
                                      Why ? </Button>
                                  }

                                </p>
                              }
                              <p>

                                {`   `}
                                <ul className="list-horizontal">
                                  <li>
                                    Invested<span 
                                    style={{
                                      fontFamily: 'Verdana, sans-serif', color: 'InfoText',
                                      backgroundColor: '#dfedf5',
                                      // fontWeight: 500,
                                      padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                    }}
                                    >
                                      &#8377;{item['mf_order_overall_investment_amount']}
                                    </span>
                                    <span>
                                      {`  `}
                                    </span>
                                  </li>
                                  <li>
                                    Returns<span style={{
                                      fontFamily: 'Verdana, sans-serif',
                                      // backgroundColor: '#f8f2f2',
                                      fontWeight: 500,
                                      padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4,
                                      color: parseFloat(item['total_returns_amount'] || 0) -
                                        parseFloat(item['mf_order_overall_investment_amount']) < 0
                                        ? "#f57362"
                                        : (parseFloat(item['total_returns_amount'] || 0) -
                                          parseFloat(item['mf_order_overall_investment_amount'])) === 0 ? '' : 'green',
                                    }}>
                                      &#8377;{item['total_returns_amount']}
                                    </span>
                                  </li>
                                  <li>
                                      { parseFloat(item['returns_difference_amount'] || 0) < 0 ? 
                                      `Loss` : `Gain`
                                      }
                                      <span style={{
                                      fontFamily: 'Verdana, sans-serif',
                                      padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4,
                                      // backgroundColor: '#e6e3e3',
                                      color:
                                        parseFloat(item['returns_difference_amount'] || 0) < 0
                                          ? "#f57362"
                                          : parseFloat(item['returns_difference_amount'] || 0) === 0 ? '' : 'green',
                                    }}>&#8377;{
                                        item['returns_difference_amount'] || 0
                                      }
                                    </span>

                                  </li>
                                  <li>
                                    Returns Percent<span style={{
                                      fontFamily: 'Verdana, sans-serif',
                                      padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4,
                                      // backgroundColor: '#e6e3e3',
                                      color:
                                        parseFloat(item['returns_difference_amount']) < 0
                                          ? "#f57362"
                                          : (parseFloat(item['returns_difference_amount']) === 0 ? '' : 'green'),
                                    }}>
                                      {parseFloat(item['returns_difference_amount']) > 0 ? `+` : ``}
                                      {item['total_returns_percent']}%</span>
                                  </li>
                                </ul>


                              </p>
                            </td>

                            <td>
                              <Dropdown style={{ height: '5px' }}>
                                <Dropdown.Toggle variant="outline-success">
                                  Transactions
                                </Dropdown.Toggle>

                                <Dropdown.Menu>

                                  <Dropdown.Item href="#"
                                    onClick={() => {
                                      this.showTransactionHistory(item)
                                    }}>
                                    <i className="la la-user"></i> Show History
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item href="#"
                                    onClick={() => {
                                      this.routeChange(
                                        "single",
                                        item,
                                        'initiate'
                                      );
                                    }}>
                                    <i className="la la-user"></i> View Statement
                                  </Dropdown.Item>

                                </Dropdown.Menu>
                              </Dropdown>
                            </td>

                            <td>
                              <Dropdown style={{ height: '5px' }}>
                                <Dropdown.Toggle variant="outline-info">
                                  Explore
                                </Dropdown.Toggle>

                                <Dropdown.Menu>

                                  <Dropdown.Item href="#"
                                    onClick={() => {
                                      this.showChildInformation(
                                        "single",
                                        item,
                                        "view"
                                      );
                                    }}>
                                    <i className="la la-user"></i> View Info
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  {item['is_stp_allowed'] && !item['mf_order_stp_enabled'] &&
                                    <Dropdown.Item href="#" onClick={() => {
                                      this.props.history.push({
                                        pathname: "/stpSchemeList/",
                                        state: {
                                          request_mode: 'START STP',
                                          selected_order: item,
                                        },
                                      });
                                    }}>
                                      <i className="la la-user"></i> Start STP
                                    </Dropdown.Item>
                                  }
                                  {item['is_stp_allowed'] && !item['mf_order_stp_enabled'] &&
                                    <Dropdown.Divider />
                                  }
                                  {item['is_swp_allowed'] && !item['mf_order_swp_enabled'] &&
                                    <Dropdown.Item href="#" onClick={() => {
                                      /*
                                      this.showSwpInformation(
                                        "single",
                                        item,
                                        "view"
                                      );
                                      */
                                      this.setState({
                                        show_swp_popup: true,
                                        popup_swp: item
                                      });
                                    }}>
                                      <i className="la la-user"></i> Start SWP
                                      <hr />
                                    </Dropdown.Item>
                                  }
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>

                            {/* {item['is_stp_allowed'] && !item['mf_order_stp_enabled'] &&
                              <td>
                                <button type="button"
                                  className="btn-sm btn-primary"
                                  style={{ color: 'white', width: '80px' }}
                                  onClick={() => {
                                    this.props.history.push({
                                      pathname: "/stpSchemeList/",
                                      state: {
                                        request_mode: 'START STP',
                                        selected_order: item,
                                      },
                                    });
                                  }}
                                >
                                  Start STP
                                </button>
                              </td>
                            }
                            {item['is_swp_allowed'] && !item['mf_order_swp_enabled'] &&
                              <td>
                                <button type="button"
                                  className="btn-sm btn-primary"
                                  style={{ color: 'white', width: '80px' }}
                                  onClick={() => {
                                    this.setState({
                                      show_swp_popup: true,
                                      popup_swp: item
                                    });

                                  }}
                                >
                                  Start SWP
                                </button>
                              </td>
                            }

                            <td>
                              {!item["show_swp"] && item["show_child"] && (
                                <Button
                                  className="btn btn-danger"
                                  variant="success"
                                  onClick={() => {
                                    this.showChildInformation(
                                      "single",
                                      item,
                                      "hide"
                                    );
                                  }}
                                >
                                  Hide
                                </Button>
                              )}
                              {!item["show_child"] && (
                                <button type="button"
                                  className="btn-sm btn-outline-success"
                                  style={{
                                    borderColor: 'rgb(176 184 176)',
                                    borderBottomColor: 'rgb(176 184 176)',
                                    width: '120px'
                                  }}
                                  onClick={() => {
                                    this.showChildInformation(
                                      "single",
                                      item,
                                      "view"
                                    );
                                  }}
                                >
                                  View
                                </button>
                              )}
                            </td> */}

                          </tr>
                          {item["show_child"] &&
                            (item["display_installment_info_html"] || "")
                              .length > 7 && (
                              <tr>
                                <td style={{ padding: 0 }}>
                                  <Card sx={{ backgroundColor: '#edf3f0', p: 2, borderRadius: 2 }}>
                                    <CardContent  >
                                      <div style={{ margin: 0, padding: 0, fontSize: 12 }}
                                        dangerouslySetInnerHTML={this.createDefaultMarkup(
                                          item[
                                          "display_installment_info_html"
                                          ]
                                        )}
                                      />
                                      <div>

                                        {item["is_cancel_allowed"] && (
                                          <button
                                            className="btn btn-danger"
                                            style={{ fontSize: 12 }}
                                            onClick={() => {
                                              /*this.OnCancelOrder("single", item);*/
                                              this.setState({
                                                show_modal_pin_confirm_dialog: true,
                                                modal_pin_confirm_action: 'cancel order',
                                                modal_pin_confirm_value: ''
                                              })
                                            }}
                                          >
                                            Cancel Order
                                          </button>
                                        )}
                                        {"    "}
                                        {item['is_swp_allowed'] && item['mf_order_swp_enabled'] &&
                                          <button
                                            className="btn btn-warning"
                                            style={{ fontSize: 12 }}
                                            onClick={() => {
                                              this.setState({
                                                show_modal_pin_confirm_dialog: true,
                                                modal_pin_confirm_action: 'cancel order',
                                                modal_pin_confirm_value: ''
                                              })
                                            }}
                                          >
                                            Cancel SWP
                                          </button>
                                        }
                                        {"    "}
                                        <Button
                                          disabled={
                                            item["mf_order_is_invest_more"] ===
                                            false
                                          }
                                          style={{ fontSize: 12 }}
                                          variant="primary"
                                          size="sm"
                                          onClick={() => {
                                            this.gotoCheckOutPage(
                                              "investmore",
                                              item
                                            );
                                          }}
                                        >
                                          Invest More
                                        </Button>
                                        {"    "}
                                        <Button
                                          disabled={
                                            item["mf_order_is_redeem"] === false
                                          }
                                          className="btn btn-warning btn-sm"
                                          style={{ color: 'black' }}
                                          onClick={() => {
                                            this.gotoCheckOutPage("redeem", item);
                                          }}
                                        >
                                          Redeem
                                        </Button>
                                        {"    "}
                                        <Button
                                          disabled={
                                            item["mf_order_is_paynow"] === false
                                          }
                                          style={{ fontSize: 12 }}
                                          variant="primary"
                                          size="sm"
                                          onClick={() => {
                                            this.gotoCheckOutPage("paynow", item);
                                          }}
                                        >
                                          Pay Now
                                        </Button>

                                      </div>
                                    </CardContent>
                                  </Card>

                                </td>
                                <td>
                                  <Link to={'#'} className="dropdown-item"
                                    onClick={() => {
                                      this.showChildInformation(
                                        "single",
                                        item,
                                        "hide"
                                      );
                                    }}>
                                    <i className="la la-close"></i> Close
                                  </Link>
                                </td>
                              </tr>
                            )}

                          <tr>
                            {item["show_child_history"] && (
                              <td colSpan={5}>
                                <Collapse in={item["show_child_history"]}>
                                  <div>
                                    <Box
                                      sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                      }}
                                    >
                                      <Tabs
                                        value={
                                          this.state
                                            .tab_single_order_child
                                        }
                                        onChange={(event, newValue) => {
                                          this.setState({
                                            tab_single_order_child:
                                              newValue,
                                          });
                                        }}
                                        aria-label="basic tabs example"
                                      >
                                        <Tab
                                          label="TRANSACTIONS"
                                          {...a11yProps(0)}
                                        />
                                        <Tab
                                          label="PAYMENT HISTORY"
                                          {...a11yProps(1)}
                                        />
                                      </Tabs>

                                      <TabPanel
                                        value={
                                          this.state
                                            .tab_single_order_child
                                        }
                                        index={0}
                                      >
                                        <div class="buy-sell-widget">
                                          <div id="chart-timeline">
                                            <Table>
                                              {(
                                                item["order_list"] || []
                                              ).map((item, index) => (
                                                <tbody key={index}>
                                                  <tr>
                                                    <td>
                                                      <h4 class="card-title">
                                                        <h6
                                                          style={{
                                                            fontSize: 12,
                                                          }}
                                                        >
                                                          {
                                                            item[
                                                            "mf_order_message"
                                                            ]
                                                          }
                                                        </h6>
                                                        <h6
                                                          style={{
                                                            fontSize: 12,
                                                          }}
                                                        >
                                                          Initiated on{" "}
                                                          {
                                                            item[
                                                            "created_date"
                                                            ]
                                                          }
                                                        </h6>
                                                      </h4>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              ))}
                                            </Table>
                                          </div>
                                        </div>
                                      </TabPanel>
                                    </Box>
                                  </div>
                                </Collapse>
                              </td>
                            )}
                          </tr>

                          {/* {item["show_child"] && (
                              <tr>
                                {item["mf_order_notification_text"] &&
                                  item["mf_order_notification_text"]
                                    .length > 7 && (
                                    <td colSpan={5}>
                                      (
                                      <tr>
                                        <td colSpan={5}>
                                          <div
                                            className="text-dark"
                                            style={{ fontSize: 13 }}
                                            dangerouslySetInnerHTML={this.createDefaultMarkup(
                                              item[
                                              "mf_order_notification_text"
                                              ]
                                            )}
                                          />
                                        </td>
                                      </tr>
                                      )
                                    </td>
                                  )}
                                <td colSpan={5}>
                                  {item["mf_order_notification_message"] &&
                                    item["mf_order_notification_message"]
                                      .length > 7 && (
                                      <tr>
                                        <td colSpan={5}>
                                          <div
                                            className="text-dark"
                                            style={{ fontSize: 13 }}
                                            dangerouslySetInnerHTML={this.createDefaultMarkup(
                                              item[
                                              "mf_order_notification_message"
                                              ]
                                            )}
                                          />
                                        </td>
                                      </tr>
                                    )}
                                </td>
                              </tr>
                            )} */}
                        </tbody>
                      )
                    )}

                    {this.state
                      .show_popup && (
                        <Modal
                          show={
                            this.state
                              .show_popup
                          }
                          onHide={() =>
                            this.closePopup()
                          }
                          size="lg"
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                        >
                          <Modal.Header>{this.state.popup_headerText}</Modal.Header>
                          <Modal.Body>
                            <div style={{ margin: 0, padding: 0, fontSize: 12 }}
                              dangerouslySetInnerHTML={this.createDefaultMarkup(
                                this.state.popup_messageText
                              )}
                            />

                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="primary"
                              onClick={() =>
                                this.closePopup()
                              }
                            >
                              Close
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      )}

                    {this.state
                      .show_swp_popup && (
                        <Modal
                          show={
                            this.state
                              .show_swp_popup
                          }
                          onHide={() =>
                            this.setState({
                              show_swp_popup: false,
                              popup_swp: {},
                              swp_order: {
                                ...this.state.swp_order,
                                display_text: '',
                                swp_no_of_installments: '',
                                swp_amount: '',
                                swp_date: new Date(new Date().setDate(new Date().getDate() + 8)),
                                swp_frequency: '',
                                is_otp_enabled: false,
                                two_factor_authentication: {
                                  mobile_otp: '',
                                  email_otp: '',
                                  twofactor_token_id: ''
                                }
                              }
                            })
                          }
                          size="lg"
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                          backdrop="static"
                          keyboard={false}
                        >
                          <Modal.Header closeButton>Systematic Withdrawal Plan</Modal.Header>
                          <Modal.Body>
                            <div className="swp">
                              <Card sx={{ backgroundColor: '#edf3f0', p: 2, borderRadius: 2 }}>
                                {/* <CardHeader title={
                                    <Typography flex={2} whiteSpace="pre-line" >
                                      <u>
                                        Systematic Withdrawal Plan
                                      </u>
                                    </Typography>
                                  } >
                                  </CardHeader> */}
                                <CardContent>

                                  <div class="row">
                                    <div class="col">
                                      <div class="form-group">
                                        <p>Choose no of withdrawls</p>
                                        <Select
                                          value={this.state.popup_swp['swp_installment_numbers_list'].find(obj => obj.value === this.state.swp_order['swp_no_of_installments'] || '')}
                                          onChange={(e) => this.handleChangeSelect(e, this.state.popup_swp, 'swp_no_of_installments', 'swp')}
                                          options={this.state.popup_swp['swp_installment_numbers_list']}
                                          isSearchable={false}
                                          isDisabled={this.state.swp_order['is_otp_enabled']}
                                        />
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <p>Choose Frequency</p>
                                        {this.state.popup_swp['swp_frequency_list'] &&
                                          <Select
                                            value={this.state.popup_swp['swp_frequency_list'].find(obj => obj.value === this.state.swp_order['swp_frequency'] || '')}
                                            onChange={(e) => this.handleChangeSelect(e, this.state.popup_swp, 'swp_frequency', 'swp')}
                                            options={this.state.popup_swp['swp_frequency_list']}
                                            isSearchable={false}
                                            isDisabled={this.state.swp_order['is_otp_enabled']}
                                          />
                                        }
                                      </div>
                                    </div>
                                    <div class="col-4">
                                      <div class="form-group">
                                        <p class="mr-sm-2 text-gray-dark">Enter Amount</p>
                                        <input
                                          style={{ width: 200 }}
                                          type="text"
                                          name="swp_amount"
                                          class="form-control"
                                          placeholder=""
                                          maxLength="11"
                                          value={this.state.swp_order['swp_amount']}
                                          onKeyPress={event => {
                                            if (event.target.key === 'Enter') {
                                              event.preventDefault();
                                            }
                                          }}
                                          disabled={this.state.swp_order['is_otp_enabled']}
                                          onChange={event => {
                                            const targetValue = event.target.value.replace(/\s/g, '');

                                            if (targetValue.length === 0)
                                              this.setState({ swp_order: { ...this.state.swp_order, swp_amount: targetValue } });
                                            else {

                                              if (targetValue.match(/^[0-9]+$/))
                                                if (parseFloat(targetValue) <= parseFloat(this.state.popup_swp['maximum_swp_amount']))
                                                  this.setState({ swp_order: { ...this.state.swp_order, swp_amount: targetValue } });

                                            }

                                          }

                                          }
                                        />
                                        <ul className="list-horizontal">
                                          <li>
                                            <span style={{ color: `#733dd1` }}>
                                              Min. &#8377;{this.state.popup_swp['minimum_swp_amount']} {`  `}</span>
                                          </li>
                                          <li>
                                            <span style={{ color: `#0f967b` }}>Max. &#8377;{this.state.popup_swp['maximum_swp_amount']}
                                            </span>
                                          </li>
                                        </ul>

                                      </div>
                                    </div>
                                  </div>
                                  {/* <div class="row">
                                      <br/>
                                    </div> */}
                                  <div class="row">

                                    <div class="col-6">
                                      <div class="form-group">
                                        <p class="mr-sm-2 text-gray-dark">SWP Date</p>
                                        <div class="row">
                                          <div class="col">
                                            <DatePicker className="form-control"
                                              style={{ width: 60 }}
                                              selected={this.state.swp_order.swp_date}
                                              onChangeRaw={(e) => e.preventDefault()}
                                              onChange={(date) => {
                                                this.setState({
                                                  swp_order: { ...this.state.swp_order, swp_date: date }
                                                })
                                              }
                                              }
                                              minDate={this.state.swp_minimum_date}
                                              maxDate={this.state.swp_maximum_date}
                                              shouldCloseOnSelect={true}
                                              excludeDates={this.state.popup_swp['swp_exclude_date_list']}
                                              disabled={this.state.swp_order['is_otp_enabled']}
                                            />
                                          </div>
                                          <div class="col">

                                            <button
                                              className="btn btn-warning"
                                              style={{ fontSize: 12 }}
                                              onClick={() => {

                                                //this.OnCreateSwpOrder("single", item);
                                                this.sendOTP()

                                              }}
                                              disabled={
                                                isNaN(this.state.swp_order['swp_no_of_installments']) ||
                                                isNaN(this.state.swp_order['swp_amount']) ||
                                                this.state.swp_order['swp_no_of_installments'].length < 1 ||
                                                this.state.swp_order['swp_amount'].length < 1 ||
                                                this.state.swp_order['swp_frequency'].length < 5 ||
                                                parseInt(this.state.swp_order['swp_no_of_installments']) < 1 ||
                                                parseInt(this.state.swp_order['swp_amount']) < parseInt(this.state.popup_swp['minimum_swp_amount']) ||
                                                this.state.swp_order['is_otp_enabled']
                                              }
                                            >
                                              Register
                                            </button>
                                          </div>
                                        </div>

                                        <p style={{ color: `#733dd1` }}>
                                          Your 1st order will be placed on {new Date(this.state.swp_order['swp_date']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="col-2">
                                      <br />
                                      <br />

                                    </div>
                                  </div>
                                  {/* <div class="row">
                                      <div class="col">
                                        <div class="form-group">
                                          <br />
                                          <br />
                                          
                                        </div>
                                      </div>
                                    </div> */}

                                  {this.state.swp_order['is_otp_enabled'] &&
                                    <div>
                                      <hr />
                                      <div class="row">
                                        <div class="col">
                                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Enter OTP received on your registered mobile</Form.Label>
                                            <OTPInput
                                              value={this.state.swp_order['mobile_otp_input']}
                                              // onChange={handle2FactorChange('mobile_otp', 'two_factor_authentication')}
                                              autoFocus
                                              OTPLength={6}
                                              otpType="number"
                                              secure
                                              isInputNum={true}
                                            />
                                          </Form.Group>
                                        </div>
                                        <div class="col">
                                          <br />
                                          <button
                                            className="btn btn-warning"
                                            style={{ fontSize: 12 }}
                                            onClick={() => {
                                              this.OnCreateSwpOrder("single", this.state.popup_swp);
                                            }}
                                            disabled={
                                              isNaN(this.state.swp_order['swp_no_of_installments']) ||
                                              isNaN(this.state.swp_order['swp_amount']) ||
                                              this.state.swp_order['swp_no_of_installments'].length < 1 ||
                                              this.state.swp_order['swp_amount'].length < 1 ||
                                              this.state.swp_order['swp_frequency'].length < 5 ||
                                              parseInt(this.state.swp_order['swp_no_of_installments']) < 1 ||
                                              parseInt(this.state.swp_order['swp_amount']) < parseInt(this.state.popup_swp['minimum_swp_amount'])
                                            }
                                          >
                                            Confirm SWP
                                          </button>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <ResendOTP maxTime={120} renderButton={renderButton} style={{ color: 'black', marginTop: 20 }} renderTime={renderTime}
                                          onResendClick={(e) => { this.sendOTP() }}
                                        />
                                      </div>
                                    </div>
                                  }

                                </CardContent>
                              </Card>
                            </div>
                          </Modal.Body>
                          {/* <Modal.Footer>
                              <Button
                                variant="primary"
                                onClick={() =>

                                  this.setState({ 
                                    show_swp_popup: false, 
                                    popup_swp: {}
                                  })
                                }
                              >
                                Close
                              </Button>
                            </Modal.Footer> */}
                        </Modal>
                      )
                    }

                    {this.state.show_modal_pin_confirm_dialog && (
                      <Modal show={this.state.show_modal_pin_confirm_dialog}
                        onHide={() => this.setState({
                          show_modal_pin_confirm_dialog: false,
                          modal_pin_confirm_action: '',
                          modal_pin_confirm_value: ''
                        })}
                        centered
                        keyboard={false}
                        fullscreen={false}
                        backdrop="static"
                      >
                        <Modal.Header>

                        </Modal.Header>
                        <Modal.Body>

                          <p>{this.state["modal_pin_confirm_dialog_messagetext"]}</p>
                          <OTPInput
                            value={this.state.modal_pin_confirm_value}
                            onChange={event => this.setState({ modal_pin_confirm_value: event })}
                            autoFocus
                            OTPLength={4}
                            otpType="number"
                            disabled={false}
                            secure
                            isInputNum={true}
                          />
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="primary"
                            disabled={this.state.modal_pin_confirm_value.length !== 4}
                            onClick={() => {

                              if (this.state.modal_pin_confirm_action === 'cancel order') {
                                this.onCancelOrder(
                                  "single",
                                  Object.assign(this.state.selected_order,
                                    { pin_verification_value: this.state.modal_pin_confirm_value })
                                );
                              }
                              else if (this.state.modal_pin_confirm_action === 'cancel swp') {
                                this.OnCancelSwpOrder(
                                  "single",
                                  Object.assign(this.state.selected_order, { pin_verification_value: this.state.modal_pin_confirm_value })
                                );
                              }

                            }}>
                            Confirm
                          </Button>
                          <Button variant="primary"
                            onClick={() => this.setState({
                              show_modal_pin_confirm_dialog: false,
                              modal_pin_confirm_action: '',
                              modal_pin_confirm_value: ''
                            })}>
                            Cancel
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    )}

                  </table>

                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

}

// OrderList.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

/*
const mapStateToProps = state => ({
    loading: state.order.loading,
    order_result: state.order.order_result
});
*/

const mapStateToProps = ({ order }) => {
  const { order_result } = order;

  return {
    order_result,
  };
};

export default connect(mapStateToProps, {
  loadOrderList,
  loadPaymentResponse,
  cancelOrder,
  createSwpOrder,
  twoFactorAuthentication,
  cancelSwpOrder
})(OrderList);
