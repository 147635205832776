import React, { } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { connect } from "react-redux";
import { Button, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { createClientCode, loadStageInfo } from '../../../jsx/actions/verify';
import { Redirect } from 'react-router-dom';
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";

class ClientDocuments extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            is_kyc_verified: true,
            is_esign_verified: true,
            is_client_code_approved: false,
            is_stage_verified: false,
            is_verify_enabled: false,
            is_confirm_chk_enabled: false,
            is_verified_editable: false,
            esignkyc_information: {},
            esignkyc_attachment_list: {},
            is_esign_verified: false,
            is_esign_button_disabled: false,            
            isloading: false,
        };

    }

    componentDidMount() {

        window.scrollTo(0, 0)

        let isMobile = (window.matchMedia && window.matchMedia("(max-width: 480px)").matches);

        let received_data = (isMobile && this.props.location.state && this.props.location.state['customer_id']) ?
            this.props.location.state['customer_id'] : this.props.match.params;

        if (received_data) {
            sessionStorage.setItem('client_documents_data', JSON.stringify(received_data));
        }
        else
            received_data = JSON.parse(sessionStorage.getItem('client_documents_data') || JSON.stringify({}));

        //    console.log(received_data);
        this.onLoadStageInfo(received_data);

    }

    handleConfirmCheck = (event) => {
        this.setState({ is_confirm_chk_checked: !this.state.is_confirm_chk_checked })
    }

    onLoadStageInfo = (received_data) => {
        
        this.setState({ isloading: true });
        this.props.loadStageInfo({
            customer_id: received_data['customer_id'] || '',
            is_backoffice: true,
            stage_name: 'esignkyc'
        }).then(response => {
            
            this.setState({ isloading: false });
            if (this.props.personal_result['code'] !== '000')
                alert(this.props.personal_result['messageText']);
            else {

                if (this.props.personal_result['state_parameters'])
                    this.setState(this.props.personal_result['state_parameters']);
                else
                    this.setState({
                        is_stage_verified: this.props.personal_result['is_verified'],
                        is_verify_enabled: this.props.personal_result['is_verified'],
                        is_confirm_chk_enabled: true,
                        esignkyc_information: this.props.personal_result['id_information']
                    });
            }

        })
        .catch(err => {
            alert(err)
        });

    };

    onCreateClientCode = () => {

        let _customer_id = this.props.location.state ? 
            this.props.location.state.customer_id : null;
        
        if(!_customer_id || _customer_id.length < 7)
            _customer_id = (
                sessionStorage.getItem('client_documents_data') 
                    ? JSON.parse(sessionStorage.getItem('client_documents_data'))['customer_id'] : ''
            );
        // console.log(_customer_id);

        if(_customer_id && _customer_id.length > 7) {
            
            this.setState({ isloading: true });

            this.props.createClientCode({
                customer_id: _customer_id,
                is_backoffice: true,
                stage_name: 'create client code'
            }).then(response => {

                this.setState({ isloading: true });

                if (this.props.personal_result['code'] !== '000')
                    alert(this.props.personal_result['messageText']);
                else {

                    this.props.history.push({
                        pathname: '/clientList/',
                        state: {
                            // customer_id: item['_id']
                        }
                    });

                }

            })
            .catch(err => {
                alert(err)
            });

        }


    };

    render() {

        if (this.props.personal_result && this.props.personal_result['esignkyc_stage_verified'])
            return <Redirect to={this.props.aadhaar_result['navigateScreen']} />;

        return (
            <>
                <Header1 />
                <Sidebar />
                <div className="content-body">
                    <div className="container" style={{ marginTop: 100 }}>
                        <div className="row ">
                            <div className="col-xl-12">
                                <div className="mini-logo text-center my-5">
                                    {/* <Link to={'./'}><img src={require('./../../../images/logo.png')} alt="" /></Link> */}
                                </div>
                                <div className="auth-form card">
                                    <div className="card-body">
                                        {/* <Link className="page-back text-muted" to={'./panVerification'}><span><i
                                        className="fa fa-angle-left"></i></span> Back</Link> */}
                                        <h3>KYC INFORMATION (CREATE CLIENT CODE)</h3>
                                        {/* <p className="text-center mb-5">PAN card number is compulsory for investing on mutual funds</p> */}
                                        {this.state.isloading &&
                                            <Spinner animation="border" variant="dark"
                                                style={{ position: "fixed", top: "50%", left: "50%" }} />
                                        }
                                    </div>

                                    {this.state.esignkyc_information && this.state.esignkyc_information['pan'] &&
                                        this.state.login_stage !== 'pan' &&
                                        <div class="row">
                                            <div class="col-4">

                                                <form action="#">
                                                    <b>PAN INFORMATION</b>
                                                    <p></p>
                                                    {this.state.esignkyc_information['pan'].map((el, i) =>
                                                        <div key={i} >
                                                            <p style={{ height: 8 }}>
                                                                <span style={{ color: '#13855d' }}>
                                                                    {el['attribute']}
                                                                </span>
                                                                {' : '}
                                                                <label>
                                                                    {el['text']}
                                                                </label>
                                                            </p>

                                                        </div>
                                                    )
                                                    }

                                                </form>

                                            </div>
                                            <div class="col">
                                                {this.state.esignkyc_information['pan_attachment_list'] &&
                                                    <div >
                                                        {this.state.esignkyc_information['pan_attachment_list'].map((el, i) =>
                                                            <div key={i} className="form-group">
                                                                <img src={el} style={{ width: 300, height: 220 }} />
                                                            </div>
                                                        )
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            <hr />
                                        </div>
                                    }

                                    {this.state.esignkyc_information && this.state.esignkyc_information['aadhaar'] &&
                                        this.state.login_stage !== 'pan' && this.state.login_stage !== 'aadhaar' &&
                                        <div class="row">
                                            <div class="col-4">
                                                <form action="#">
                                                    <b>AADHAAR INFORMATION</b>
                                                    <p></p>
                                                    {this.state.esignkyc_information['aadhaar'].map((el, i) =>
                                                        <div key={i} >
                                                            {
                                                                el['attribute'] !== 'address_information' &&
                                                                <p style={{ height: 8 }}>
                                                                    <span style={{ color: '#13855d' }}>
                                                                        {el['attribute']}
                                                                    </span>
                                                                    {' : '}
                                                                    <label>
                                                                        {el['text']}
                                                                    </label>
                                                                </p>
                                                            }
                                                        </div>
                                                    )
                                                    }

                                                </form>
                                            </div>
                                            <div class="col">
                                                {this.state.esignkyc_information['aadhaar_attachment_list'] &&
                                                    <div class="row">
                                                        {this.state.esignkyc_information['aadhaar_attachment_list'].map((el, i) =>
                                                            <div class="col" key={i} >
                                                                <img src={el} style={{ width: 300, height: 220 }} />
                                                            </div>
                                                        )
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            <hr />
                                        </div>
                                    }
                                    {this.state.esignkyc_information && this.state.esignkyc_information['bank'] &&
                                        this.state.login_stage !== 'pan' && this.state.login_stage !== 'aadhaar' &&
                                        this.state.login_stage !== 'bank' &&
                                        <div class="row">
                                            <div class="col-4">
                                                <form action="#">
                                                    <b>BANK ACCOUNT INFORMATION</b>
                                                    <p></p>
                                                    {this.state.esignkyc_information['bank'].map((el, i) =>
                                                        <div key={i} >
                                                            <p style={{ height: 8 }}>
                                                                <span style={{ color: '#13855d' }}>
                                                                    {el['attribute']}
                                                                </span>
                                                                {' : '}
                                                                <label>
                                                                    {el['text']}
                                                                </label>
                                                            </p>
                                                        </div>
                                                    )
                                                    }

                                                </form>

                                            </div>
                                            <div class="col">
                                                {this.state.esignkyc_information['bank_attachment_list'] &&
                                                    <div >
                                                        {this.state.esignkyc_information['bank_attachment_list'].map((el, i) =>
                                                            <div key={i} className="form-group">
                                                                <img src={el} style={{ width: 300, height: 220 }} />
                                                            </div>
                                                        )
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            <hr />
                                        </div>
                                    }

                                    {this.state.esignkyc_information && this.state.esignkyc_information['address'] &&
                                        // this.state.login_stage === 'personal' &&
                                        <div class="row">

                                            <div class="col-4">
                                                <form action="#">
                                                    <b>COMMUNICATION ADDRESS</b>
                                                    <p></p>
                                                    {this.state.esignkyc_information['address'].map((el, i) =>
                                                        <div key={i} >
                                                            {/* <p style={{ height: 8 }}>
                                                                        <span style={{ color: '#13855d' }}>
                                                                            {el['attribute']}
                                                                        </span>
                                                                        {' : '}
                                                                        <label>
                                                                            {el['text']}
                                                                        </label>
                                                                    </p> */}
                                                            <label style={{ height: 22 }}>
                                                                <span style={{ color: '#13855d' }}>
                                                                    {el['attribute']}
                                                                </span>
                                                                {' : '}
                                                                <label>
                                                                    {el['text']}
                                                                </label>
                                                            </label>
                                                        </div>
                                                    )
                                                    }
                                                </form>

                                            </div>
                                            <div class="col-4">
                                                <b>GENERAL INFORMATION</b>
                                                <p></p>
                                                {this.state.esignkyc_information['general'].map((el, i) =>
                                                    <div key={i} >
                                                        <p style={{ height: 8 }}>
                                                            <span style={{ color: '#13855d' }}>
                                                                {el['attribute']}
                                                            </span>
                                                            {' : '}
                                                            <label>
                                                                {el['text']}
                                                            </label>
                                                        </p>
                                                    </div>
                                                )
                                                }
                                            </div>
                                            <div class="col-4">
                                                {this.state.esignkyc_information['general_attachment_list'] &&
                                                    <div >
                                                        {this.state.esignkyc_information['general_attachment_list'].map((el, i) =>
                                                            <div key={i} className="form-group">
                                                                <img src={el} style={{ width: 300, height: 220 }} />
                                                            </div>
                                                        )
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            <hr />

                                            {this.state.esignkyc_information['nominee'] &&
                                                <div class="row">
                                                    <div class="col-4">
                                                        <form action="#">
                                                            <b>NOMINEE INFORMATION</b>
                                                            <p></p>
                                                            {this.state.esignkyc_information['nominee'].map((el, i) =>
                                                                <div key={i} >
                                                                    <p style={{ height: 8 }}>
                                                                        <span style={{ color: '#13855d' }}>
                                                                            {el['attribute']}
                                                                        </span>
                                                                        {' : '}
                                                                        <label>
                                                                            {el['text']}
                                                                        </label>
                                                                    </p>
                                                                </div>
                                                            )
                                                            }

                                                        </form>

                                                    </div>
                                                    <div class="col">
                                                        {this.state.esignkyc_information['nominee_attachment_list'] &&
                                                            <div >
                                                                {this.state.esignkyc_information['nominee_attachment_list'].map((el, i) =>
                                                                    <div key={i} className="form-group">
                                                                        <img src={el} style={{ width: 300, height: 220 }} />
                                                                    </div>
                                                                )
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            <hr />

                                        </div>
                                    }

                                    {
                                        this.state.is_kyc_verified && this.state.is_esign_verified && 
                                            !this.state.is_client_code_approved && 
                                        <div className="row">
                                            <div class="col">
                                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                    <Form.Check type="checkbox" label="I confirm to create client code based on the above information."
                                                        value={this.state.is_confirm_chk_checked}
                                                        onChange={this.handleConfirmCheck} defaultChecked={this.state.is_confirm_chk_checked}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div class="col-3">
                                            {
                                                    this.state.is_confirm_chk_checked &&
                                                    <div className="text-center">
                                                        <Button
                                                            className="btn btn-success btn-block"
                                                            variant="success"
                                                            onClick={() => {
                                                                this.onCreateClientCode()
                                                            }}
                                                            disabled={this.state.isloading}
                                                        >
                                                            Create Client Code
                                                        </Button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )

    }

}
// ClientDocuments.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

const mapStateToProps = state => ({
    personal_result: state.verify.personal_result
});

export default connect(mapStateToProps, { createClientCode, loadStageInfo })
    (ClientDocuments);