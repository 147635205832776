import React, { } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { connect } from "react-redux";
import { ListGroup, Button, Row, Col, Container, Spinner, Alert, Table, Collapse, } from "react-bootstrap";
import PropTypes from 'prop-types';
import { loadClientList, saveBankAccount, addBankAccount, elogPhotoUpload } from '../../actions/verify';
import { Redirect } from 'react-router-dom';
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import Box from "@mui/material/Box";

import { Card, CardContent, Stack, Typography } from "@mui/material";
import PageTitle from "../../element/page-title";

class ClientList extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            loading: false,
            is_refresh: false,
            message_text: '',
            list_client: [],
            activity_mode: 'list',
            bank_account_number: '',
            bank_ifsc_code: '',
            bank_account_type: '',
            bank_name: {},
            is_stage_verified: false,
            is_verify_enabled: false,
            is_confirm_chk_enabled: false,
            is_verified_editable: false,
            selectedFile: null,
            src_selectedFile: null,
            bank_information: [],
            bank_attachment_list: [],
            file_bank_tiff: null,
            show_popup: false,
            pop_messagetext: '',
            list_bank: [],
            list_account_type: [],
            selectedAccountType: [],
            isloading: false
        };

    }

    componentDidMount() {

        window.addEventListener("scroll", () => {

            if (document.getElementById("navbar-clientlist-container") &&
                document.getElementById("navbar") &&
                document.getElementById("navbar").classList) {
                const topBorder = document
                    .getElementById("navbar-clientlist-container")
                    .getBoundingClientRect().top;

                topBorder >= 0
                    ? document.getElementById("navbar").classList.remove("fixed-schemelist")
                    : document.getElementById("navbar").classList.add("fixed-schemelist");
            }

        });


        this.onLoadClientList();
    }


    handleConfirmCheck = (e, item) => {

        const _list = this.state.list_client;
        _list.forEach(el => {

            if (el['beneficiary_account_no'] === item['beneficiary_account_no'])
                item['is_primary'] = !item['is_primary'];
            else
                item['is_primary'] = false;
        });

        this.setState({ list_client: _list });


    }

    onLoadClientList = () => {

        this.setState({ isloading: true });

        this.props.loadClientList({})
            .then(response => {

                this.setState({ isloading: false });

                if (this.props.client_result['code'] !== '000') {

                    if (this.props.client_result['is_redirect'])
                        this.setState({
                            message_text: this.props.client_result['messageText'],
                            is_refresh: true
                        });

                }
                else {
                    this.setState({
                        list_client: this.props.client_result['result'] || [],
                        is_refresh: false
                    });
                }

            })
            .catch(err => {
                alert(err)
            });


    };

    onElogPhotoUpload = (item) => {

        this.setState({ isloading: true });

        this.props.elogPhotoUpload({
            customer_id: item['_id'],
            is_backoffice: true,
            stage_name: 'elog photo upload'
        }).then(response => {

            this.setState({ isloading: false });

            if (this.props.client_result['code'] !== '000')
                alert(this.props.client_result['messageText']);
            else {

                /*
                let _list_client = this.state.list_client;
                _list_client.map(function (el) {
                    if (el['mf_order_registration_no'] === this.props) {
                        el = _result;
                        el['show_child'] = true;
                    }
                });
                */
                if (this.props.client_result['result'])
                    this.setState({
                        list_client: this.props.client_result['result']
                    });
                // this.props.history.push({ pathname: '/clientList/', 
                //     state: { 
                //         // customer_id: item['_id']
                //     } 
                // });

            }

        })
            .catch(err => {
                alert(err)
            });


    };

    gotoClientInformationPage(item) {

        // this.props.history.push({ pathname: '/clientDocuments/', 
        //     state: { 
        //         customer_id: item['_id']
        //     } 
        // });
        const win = window.open("/clientDocuments/" + item['_id'], "_blank");
        win.focus();

    }

    dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        this.setState({
            file_bank_tiff: new File([u8arr], (filename + "_bank.tiff"), { type: mime.split('/')[0] + '/tiff' })
        });

    }

    render() {

        // if(this.props.loading)
        //   return <Spinner />;

        // if(this.props.bank_list && this.props.bank_list['esignkyc_stage_verified'])
        //    return <Redirect to={this.props.aadhaar_result['navigateScreen']} />; 

        return (
            <>
                <Header1 />
                <Sidebar />
                {this.state.isloading && (
                    <Spinner
                        animation="border"
                        variant="dark"
                        style={{
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            zIndex: 899,
                        }}
                    />
                )}
                <div id="navbar-clientlist-container">
                    <div id="navbar" >
                        <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
                            <Container>
                                <div>
                                    Client List
                                </div>
                                {/* {!this.state.isloading &&
                                    <Card sx={{ marginTop: 10, backgroundColor: '#edf3f0', p: 2, borderRadius: 2 }}>
                                        <CardContent  >
                                            <Stack direction="row">
                                                <Typography flex={1} whiteSpace="pre-line" >
                                                    {this.state.message_text.trim().length < 4 ?
                                                        `Sorry the information you are looking for could not be found`
                                                        :
                                                        this.state.message_text
                                                    }
                                                    <p></p>
                                                    <Link className="page-back text-muted" to={'/schemeList'}><span><i
                                                        className="fa fa-angle-left"></i></span> Back</Link>
                                                    {'  '}Go to Schemes
                                                </Typography>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                } */}
                            </Container>
                        </div>
                    </div>
                </div>
                {/* {this.state.is_refresh &&

                    <div className="content-body">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10" style={{ margin: 40 }}>



                                </div>
                            </div>
                        </div>
                    </div>
                } */}
                {
                    !this.state.is_refresh &&
                    this.state.activity_mode === 'list' &&
                    <div className="content-body">
                        <div className="container">
                            <div class="row">
                                {this.state.list_client.length < 1 &&
                                    <Alert variant="dark text-center" style={{ marginBottom: 150 }}>
                                        <Alert.Heading>{this.state.loading ? 'We are fetching the client list' : 'No results found'}</Alert.Heading>
                                    </Alert>
                                }
                                <div class="col-xl-12" style={{ marginTop: 80 }}>

                                    <div class="row" >
                                        <div class="col">

                                            <div class="transaction-widget">

                                                {this.state.list_client.map((item) => (
                                                    <ListGroup key={item["_id"]}>
                                                        <ListGroup.Item
                                                            style={{ marginBottom: 5 }}
                                                            action
                                                            variant="light"
                                                            className="wallet-address text-dark"
                                                        >
                                                            <Row >

                                                                <Col xs={8} md={5}>

                                                                    <p class="text-gray-dark">
                                                                        {item["login_mobile_number"]}
                                                                        {`  `}
                                                                        <span style={{ fontSize: 13, color: '#03b45e' }}>
                                                                            {item["login_email"]}
                                                                        </span>
                                                                    </p>
                                                                    <div className="ms-2 me-auto">
                                                                        <div className="font-size-list-f1 text-secondary">
                                                                            <span style={{ fontSize: 12, color: 'InfoText', backgroundColor: '#e3e0de', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }} >
                                                                                {item["login_stage"]}
                                                                            </span>
                                                                            <span style={{
                                                                                fontSize: 12,
                                                                                color: 'InfoText',
                                                                                backgroundColor: 'lightgreen', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                                                            }} >
                                                                                {item["login_status"]}
                                                                            </span>



                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                    </div>
                                                                </Col>

                                                                <Col xs={8} md={7}>
                                                                    <div class="row">
                                                                        <div class="col-8">
                                                                            <div class="row">
                                                                                <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                                                                    <div style={{ fontSize: 12, color: '#7d7777' }}>KYC</div>
                                                                                    <span style={{ fontSize: 13, color: item['login_kyc_verified'] ? '#f57362' : '#03b45e' }}>
                                                                                        {item['login_kyc_verified'] ? 'YES' : 'NO'}
                                                                                    </span>
                                                                                </div>
                                                                                <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                                                                    <div style={{ fontSize: 12, color: '#7d7777' }}>e-SIGN</div>
                                                                                    <span style={{ fontSize: 13, color: item['login_esign_verified'] ? '#f57362' : '#03b45e' }}>
                                                                                        {item['login_esign_verified'] ? 'YES' : 'NO'}
                                                                                    </span>
                                                                                </div>
                                                                                <div style={{ width: '60px', textAlign: 'center' }} class="col">
                                                                                    <div style={{ fontSize: 12, color: '#7d7777' }}>Client Code</div>
                                                                                    <span style={{ fontSize: 13, color: item['login_bse_clientcode_verified'] ? '#f57362' : '#03b45e' }}>
                                                                                        {item['login_bse_clientcode_verified'] ? 'YES' : 'NO'}
                                                                                    </span>
                                                                                </div>
                                                                                <div style={{ width: '60px', textAlign: 'center' }} class="col">
                                                                                    <div style={{ fontSize: 12, color: '#7d7777' }}>Face Match</div>
                                                                                    <span style={{ fontSize: 13, color: item['login_bse_photo_verified'] ? '#f57362' : '#03b45e' }}>
                                                                                        {item['login_bse_photo_verified'] ? 'YES' : 'NO'}
                                                                                    </span>
                                                                                </div>
                                                                                <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                                                                    <div style={{ fontSize: 12, color: '#7d7777' }}>FATCA</div>
                                                                                    <span style={{ fontSize: 13, color: item['login_bse_fatca_verified'] ? '#f57362' : '#03b45e' }}>
                                                                                        {item['login_bse_fatca_verified'] ? 'YES' : 'NO'}
                                                                                    </span>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div class="col">
                                                                            {
                                                                                item['login_bse_clientcode_verified'] &&
                                                                                item['login_bse_fatca_verified'] === false &&

                                                                                <button type="button"
                                                                                    className="btn-sm btn-success"
                                                                                    style={{ color: 'white', width: '100px' }}
                                                                                    onClick={() => {
                                                                                        this.onElogPhotoUpload(item)
                                                                                    }}
                                                                                    disabled={this.state.isloading}
                                                                                >
                                                                                    Upload Elog
                                                                                </button>
                                                                            }
                                                                            {` | `}
                                                                            <button type="button"
                                                                                className="btn-sm btn-success"
                                                                                style={{ color: 'white', width: '60px' }}
                                                                                onClick={() => {
                                                                                    this.gotoClientInformationPage(item)
                                                                                }}
                                                                                disabled={this.state.isloading}
                                                                            >
                                                                                View
                                                                            </button>
                                                                        </div>

                                                                    </div>
                                                                </Col>

                                                            </Row>
                                                        </ListGroup.Item>
                                                    </ListGroup>
                                                ))}

                                            </div>{" "}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                }
                
            </>
        )

    }

}
// OrderList.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };



const mapStateToProps = state => ({
    loading: state.verify.loading,
    client_result: state.verify.client_result
});

// const mapStateToProps = ({ bank }) => {

//     const { loading, client_result } = bank;
//     return {
//         loading,
//         client_result
//     }

// };

export default connect(mapStateToProps, { loadClientList, saveBankAccount, addBankAccount, elogPhotoUpload })
    (ClientList);