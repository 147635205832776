import api from '../utils/api';
import { setAlert } from './alert';
import {
  GENERAL_RESULT_LOAD,
  GENERAL_RESULT_SUCCESS,
  GENERAL_RESULT_FAIL
} from './types';
import { API_END_POINTS } from '../constants/constants';

export const loadSchemeAdviseList = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    dispatch({
      type: GENERAL_RESULT_LOAD
    });

    let res = await api.post(API_END_POINTS.LIST_WATCH_SCHEMES, body);

    
    dispatch({
      type: GENERAL_RESULT_SUCCESS,
      payload: res.data
    });
    
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: GENERAL_RESULT_FAIL
    });

  }
};