import React, { } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { connect } from "react-redux";
import { Button, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { verifyAadhaar, verifyStage, loadStageInfo } from '../../../jsx/actions/verify';
import { Redirect } from 'react-router-dom';
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";

var is_esign_disabled = false;
class EsignkycVerification extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            config_digio: {
                bullbox_logo: '',
                environment: 'sandbox'
            },
            is_stage_verified: false,
            is_verify_enabled: false,
            is_confirm_chk_enabled: false,
            is_kyc_submission_chk_enabled: false,
            is_verified_editable: false,
            esignkyc_information: {},
            esignkyc_attachment_list: {},
            is_esign_verified: false,
            is_esign_button_disabled: false,
            isloading: false,
            is_kyc_verified: false,
            is_client_code_approved: false
        };

    }

    componentDidMount() {
        this.onLoadStageInfo();
    }

    handleConfirmCheck = (event) => {
        this.setState({ is_confirm_chk_checked: !this.state.is_confirm_chk_checked })
    }

    onLoadStageInfo = () => {

        this.setState({ isloading: true });
        this.props.loadStageInfo({ stage_name: 'esignkyc' }).then(response => {
            
            this.setState({ isloading: false });

            if (this.props.personal_result['code'] !== '000')
                alert(this.props.personal_result['messageText']);

            else {
                if (this.props.personal_result['state_parameters'])
                    this.setState(this.props.personal_result['state_parameters']);
                else
                    this.setState({
                        config_digio: this.props.personal_result['config_digio'],
                        is_stage_verified: this.props.personal_result['is_verified'],
                        is_verify_enabled: this.props.personal_result['is_verified'],
                        is_confirm_chk_enabled: true,
                        is_kyc_submission_chk_enabled: true,
                        esignkyc_information: this.props.personal_result['id_information']
                    });
            }

        })
            .catch(err => {
                alert(err)
            });


    };

    onVerifyStage = (props, state) => {

        let _esignkyc_information = this.state.esignkyc_information['digio_esign_parameters'];
        let _digioInfo = _esignkyc_information['digio_configuration'];

        var options = {

            environment: _digioInfo['environment'], // || (this.state.config_digio['environment'] || 'sandbox'),
            logo: _digioInfo['logo'] || 'http://pharmacyvials.com/assets/images/yourlogohere.jpg',
            method: 'otp',
            is_iframe: false,

            callback: function (result) {

                console.log(result);

                if (result && result['error_code'] === 'CANCELLED') {
                    state.setState({ is_esign_button_disabled: false });
                }
                else {

                    if (result['txn_id'])
                        props.verifyStage({
                            verify_stage: 'esignkyc', esign_response: result
                        },
                            'esignkyc',
                            response => {
                                // this.onLoadStageInfo();
                                if (props.personal_result && props.personal_result['esignkyc_stage_verified'])
                                {
                                    state.setState({ 
                                        is_esign_verified: true,
                                        is_kyc_verified: true 
                                    });
                                }
                            });

                }

                /*
                if (result.hasOwnProperty('error_code')) 
                { } 
                else { }
                */

            }
        };

        var digio = new window.Digio(options);
        digio.init();
        digio.submit(_esignkyc_information['digio_id'], _esignkyc_information['digio_email']);

        this.setState({ is_esign_button_disabled: true });

    };

    render() {

        // if (this.props.personal_result && this.props.personal_result['esignkyc_stage_verified'])
        //     return <Redirect to={this.props.personal_result['navigateScreen']} />;

        return (
            <>
                <Header1 />
                <Sidebar />
                <div className="authincation section-padding">
                    <div className="content-body" style={{ marginTop: 100 }}>
                        <div className="container">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div className="auth-form card">
                                        <div className="card-body">
                                            {this.state.isloading && <Spinner animation="border" variant="dark" style={{ position: "fixed", top: "50%", left: "50%" }} />}
                                            {/* <Link className="page-back text-muted" to={'./panVerification'}><span><i
                                        className="fa fa-angle-left"></i></span> Back</Link> */}
                                            <h3 className="text-center">ESign KYC AADHAAR</h3>
                                            {/* <p className="text-center mb-5">PAN card number is compulsory for investing on mutual funds</p> */}


                                            {
                                                <div style={{ marginTop: 25, marginBottom: 25 }}>
                                                    <span style={{ color: 'InfoText', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }} >
                                                        {this.state.login_email}
                                                    </span>
                                                    <span style={{ color: 'InfoText', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }} >
                                                        {this.state.login_mobile_number}
                                                    </span>

                                                    {!this.state.isloading && 
                                                        (this.state.is_esign_verified && this.state.is_kyc_verified && !this.state.is_client_code_approved) ?
                                                            <span style={{ color: 'InfoText', backgroundColor: '#f5857d', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }} >
                                                                Your signed KYC is awaiting approval from BSE Star MF platform. It will take 1-3 working days.
                                                            </span>
                                                            :
                                                            <span>
                                                                {(!this.state.is_esign_verified || !this.state.is_kyc_verified) &&

                                                                    <span style={{ color: 'InfoText', backgroundColor: '#f5857d', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }} >
                                                                        KYC is not submitted/esigned and current verification stage is {this.state.login_stage}
                                                                    </span>

                                                                }
                                                            </span>
                                                    }
                                                    <hr />
                                                </div>
                                            }

                                            {this.state.esignkyc_information && this.state.esignkyc_information['pan'] &&
                                                this.state.login_stage !== 'pan' &&
                                                <div class="row">
                                                    <div class="col-4">

                                                        <form action="#">
                                                            <b>PAN INFORMATION</b>
                                                            <p></p>
                                                            {this.state.esignkyc_information['pan'].map((el, i) =>
                                                                <div key={i} >
                                                                    <p style={{ height: 8 }}>
                                                                        <span style={{ color: '#13855d' }}>
                                                                            {el['attribute']}
                                                                        </span>
                                                                        {' : '}
                                                                        <label>
                                                                            {el['text']}
                                                                        </label>
                                                                    </p>

                                                                </div>
                                                            )
                                                            }

                                                        </form>

                                                    </div>
                                                    <div class="col">
                                                        {this.state.esignkyc_information['pan_attachment_list'] &&
                                                            <div >
                                                                {this.state.esignkyc_information['pan_attachment_list'].map((el, i) =>
                                                                    <div key={i} className="form-group">
                                                                        <img src={el} style={{ width: 300, height: 220 }} />
                                                                    </div>
                                                                )
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    <hr />
                                                </div>
                                            }

                                            {this.state.esignkyc_information && this.state.esignkyc_information['aadhaar'] &&
                                                this.state.login_stage !== 'pan' && this.state.login_stage !== 'aadhaar' &&
                                                <div class="row">
                                                    <div class="col-4">
                                                        <form action="#">
                                                            <b>AADHAAR INFORMATION</b>
                                                            <p></p>
                                                            {this.state.esignkyc_information['aadhaar'].map((el, i) =>
                                                                <div key={i} >
                                                                    {
                                                                        el['attribute'] !== 'address_information' &&
                                                                        <p style={{ height: 8 }}>
                                                                            <span style={{ color: '#13855d' }}>
                                                                                {el['attribute']}
                                                                            </span>
                                                                            {' : '}
                                                                            <label>
                                                                                {el['text']}
                                                                            </label>
                                                                        </p>
                                                                    }
                                                                </div>
                                                            )
                                                            }

                                                        </form>
                                                    </div>
                                                    <div class="col">
                                                        {this.state.esignkyc_information['aadhaar_attachment_list'] &&
                                                            <div class="row">
                                                                {this.state.esignkyc_information['aadhaar_attachment_list'].map((el, i) =>
                                                                    <div class="col" key={i} >
                                                                        <img src={el} style={{ width: 300, height: 220 }} />
                                                                    </div>
                                                                )
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    <hr />
                                                </div>
                                            }
                                            {this.state.esignkyc_information && this.state.esignkyc_information['bank'] &&
                                                this.state.login_stage !== 'pan' && this.state.login_stage !== 'aadhaar' &&
                                                this.state.login_stage !== 'bank' &&
                                                <div class="row">
                                                    <div class="col-4">
                                                        <form action="#">
                                                            <b>BANK ACCOUNT INFORMATION</b>
                                                            <p></p>
                                                            {this.state.esignkyc_information['bank'].map((el, i) =>
                                                                <div key={i} >
                                                                    <p style={{ height: 8 }}>
                                                                        <span style={{ color: '#13855d' }}>
                                                                            {el['attribute']}
                                                                        </span>
                                                                        {' : '}
                                                                        <label>
                                                                            {el['text']}
                                                                        </label>
                                                                    </p>
                                                                </div>
                                                            )
                                                            }

                                                        </form>

                                                    </div>
                                                    <div class="col">
                                                        {this.state.esignkyc_information['bank_attachment_list'] &&
                                                            <div >
                                                                {this.state.esignkyc_information['bank_attachment_list'].map((el, i) =>
                                                                    <div key={i} className="form-group">
                                                                        <img src={el} style={{ width: 300, height: 220 }} />
                                                                    </div>
                                                                )
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    <hr />
                                                </div>
                                            }

                                            {this.state.esignkyc_information && this.state.esignkyc_information['address'] &&
                                                // this.state.login_stage === 'personal' &&
                                                <div class="row">

                                                    <div class="col-4">
                                                        <form action="#">
                                                            <b>COMMUNICATION ADDRESS</b>
                                                            <p></p>
                                                            {this.state.esignkyc_information['address'].map((el, i) =>
                                                                <div key={i} >
                                                                    {/* <p style={{ height: 8 }}>
                                                                        <span style={{ color: '#13855d' }}>
                                                                            {el['attribute']}
                                                                        </span>
                                                                        {' : '}
                                                                        <label>
                                                                            {el['text']}
                                                                        </label>
                                                                    </p> */}
                                                                    <label style={{ height: 22 }}>
                                                                        <span style={{ color: '#13855d' }}>
                                                                            {el['attribute']}
                                                                        </span>
                                                                        {' : '}
                                                                        <label>
                                                                            {el['text']}
                                                                        </label>
                                                                    </label>
                                                                </div>
                                                            )
                                                            }
                                                        </form>

                                                    </div>
                                                    <div class="col-4">
                                                        <b>GENERAL INFORMATION</b>
                                                        <p></p>
                                                        {this.state.esignkyc_information['general'].map((el, i) =>
                                                            <div key={i} >
                                                                <p style={{ height: 8 }}>
                                                                    <span style={{ color: '#13855d' }}>
                                                                        {el['attribute']}
                                                                    </span>
                                                                    {' : '}
                                                                    <label>
                                                                        {el['text']}
                                                                    </label>
                                                                </p>
                                                            </div>
                                                        )
                                                        }
                                                    </div>
                                                    <div class="col-4">
                                                        {this.state.esignkyc_information['general_attachment_list'] &&
                                                            <div >
                                                                {this.state.esignkyc_information['general_attachment_list'].map((el, i) =>
                                                                    <div key={i} className="form-group">
                                                                        <img src={el} style={{ width: 300, height: 220 }} />
                                                                    </div>
                                                                )
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    <hr />

                                                    {this.state.esignkyc_information['nominee'] &&
                                                        <div class="row">
                                                            <div class="col-4">
                                                                <form action="#">
                                                                    <b>NOMINEE INFORMATION</b>
                                                                    <p></p>
                                                                    {this.state.esignkyc_information['nominee'].map((el, i) =>
                                                                        <div key={i} >
                                                                            <p style={{ height: 8 }}>
                                                                                <span style={{ color: '#13855d' }}>
                                                                                    {el['attribute']}
                                                                                </span>
                                                                                {' : '}
                                                                                <label>
                                                                                    {el['text']}
                                                                                </label>
                                                                            </p>
                                                                        </div>
                                                                    )
                                                                    }

                                                                </form>

                                                            </div>
                                                            <div class="col">
                                                                {this.state.esignkyc_information['nominee_attachment_list'] &&
                                                                    <div >
                                                                        {this.state.esignkyc_information['nominee_attachment_list'].map((el, i) =>
                                                                            <div key={i} className="form-group">
                                                                                <img src={el} style={{ width: 300, height: 220 }} />
                                                                            </div>
                                                                        )
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                    <hr />

                                                </div>
                                            }

                                            {
                                                this.state.is_esign_verified && this.state.is_kyc_verified &&
                                                 !this.state.is_client_code_approved &&
                                                <p>
                                                    Your signed KYC is awaiting approval from BSE Star MF platform. It will take 1-3 working days.
                                                </p>
                                            }
                                            {

                                /*this.state.is_esign_verified &&*/ this.state.is_kyc_submission_chk_enabled &&
                                                <div>
                                                    <div className="card-body">
                                                        <div>
                                                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                                <Form.Check type="checkbox" label="I here by agree to e-Sign my KYC with aadhaar otp authentication supported by digio."
                                                                    value={this.state.is_confirm_chk_checked}
                                                                    onChange={this.handleConfirmCheck} defaultChecked={this.state.is_confirm_chk_checked}
                                                                />
                                                            </Form.Group>

                                                            {
                                                                this.state.is_confirm_chk_checked &&
                                                                <div className="text-center">
                                                                    <Button
                                                                        className="btn btn-success btn-block"
                                                                        variant="success"
                                                                        disabled={this.state.is_esign_button_disabled || 
                                                                            this.state.isloading
                                                                        }
                                                                        onClick={() => this.onVerifyStage(this.props, this)}>eSIGN</Button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div></div></div>
            </>
        )

    }

}
// EsignkycVerification.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

const mapStateToProps = state => ({
    personal_result: state.verify.personal_result
});

export default connect(mapStateToProps, { verifyStage, loadStageInfo })
    (EsignkycVerification);