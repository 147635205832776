import React, { } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { connect } from "react-redux";
import { Button, Spinner,Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { verifyAadhaar, verifyStage, loadStageInfo } from '../../../jsx/actions/verify';
import { Redirect } from 'react-router-dom';
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import Popup from '../popup';
//import Spinner from "../../layout/spinner";

class AadhaarVerification extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            is_stage_verified: false,
            is_verify_enabled: false,
            is_confirm_chk_enabled: false,
            is_confirm_chk_checked: false,
            is_verified_editable: false,
            selectedFrontFile: null,
            selectedBackFile: null,
            src_selectedFrontFile: null,
            src_selectedBackFile: null,
            aadhaar_information: [],
            aadhaar_attachment_list: [],
            show_popup: false,
            pop_messagetext: '',
            isloading: this.props.loading,
            is_aadhaar_front: true,
            is_aadhaar_back: true,
            message_text: ''
        };

    }

    componentDidMount() {
        this.onLoadStageInfo();
    }

    closePopup = () => {
        this.setState({
            show_popup: false,
            pop_messagetext: ''
        })
    }

    handleConfirmCheck = (event) => {
        this.setState({ is_confirm_chk_checked: !this.state.is_confirm_chk_checked })
    }

    changeFileHandler = (selectedFile) => (event) => {

        if (event.target.files[0] && (event.target.files[0].type === 'image/png' ||
            event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg')) {
            this.setState({
                [selectedFile]: event.target.files[0],
                ['src_' + selectedFile]: URL.createObjectURL(event.target.files[0])
            });
        }
        else {

            if (event.target.files.length > 0)
                this.setState({
                    show_popup: true,
                    pop_messagetext: 'Upload file format should be of PNG, JPEG, JPG only'
                });
        }
    }

    onLoadStageInfo = () => {

        this.setState({ isloading: true });

        this.props.loadStageInfo({ stage_name: 'aadhaar' }).then(response => {

            this.setState({ isloading: false });

            if (this.props.aadhaar_result['code'] !== '000')
                alert(this.props.aadhaar_result['messageText']);
            else {

                if (this.props.aadhaar_result['state_parameters'])
                    this.setState(this.props.aadhaar_result['state_parameters']);
                else
                    this.setState({
                        is_stage_verified: this.props.aadhaar_result['is_stage_verified'],
                        is_verify_enabled: this.props.aadhaar_result['is_verify_enabled'],
                        is_confirm_chk_enabled: this.props.aadhaar_result['is_confirm_chk_enabled'],
                        is_verified_editable: this.props.aadhaar_result['is_verified_editable'],
                        aadhaar_information: this.props.aadhaar_result['id_information'],
                        aadhaar_attachment_list: this.props.aadhaar_result['attachment_list'],
                    });
            }

        })
            .catch(err => {
                alert(err)
            });


    };

    onVerify = () => {

        this.setState({ isloading: true });

        const { otp } = this.state;

        let formData = new FormData();

        formData.append("upload_aadhaar", this.state.selectedFrontFile);
        formData.append("upload_aadhaar", this.state.selectedBackFile);
        formData.append("id_type", 'aadhaar');
        formData.append("no_of_attachment", 2);
        formData.append("folder_name", 'aadhaar');
        formData.append("attachment_category", 'aadhaar');

        this.props.verifyAadhaar(formData).then(response => {
            
            if (this.props.aadhaar_result['code'] !== '000') {
                // this.setState(this.props.aadhaar_result['state_parameters']);
                this.setState({
                    message_text: this.props.aadhaar_result['messageText'],
                    state_parameters: this.props.aadhaar_result['state_parameters'],
                    isloading: false
                });
                alert(this.props.aadhaar_result['messageText']);
            }
            else {

                this.setState({ isloading: false });
                if (this.props.aadhaar_result['state_parameters'])
                    this.setState(this.props.aadhaar_result['state_parameters']);
                else
                    this.setState({
                        is_stage_verified: this.props.aadhaar_result['is_stage_verified'],
                        is_verify_enabled: this.props.aadhaar_result['is_verify_enabled'],
                        is_confirm_chk_enabled: this.props.aadhaar_result['is_confirm_chk_enabled'],
                        is_verified_editable: this.props.aadhaar_result['is_verified_editable'],
                        aadhaar_information: this.props.aadhaar_result['id_information'],
                        aadhaar_attachment_list: this.props.aadhaar_result['attachment_list'],
                    });
            }

        })
            .catch(err => {
                alert(err)
            });


    };

    onVerifyStage = () => {

        this.setState({ isloading: true });

        this.props.verifyStage({ verify_stage: 'aadhaar' }, 'aadhaar', response => {

            this.setState({ isloading: false })
            this.props.history.push({
                pathname: "/" + this.props.aadhaar_result["navigateScreen"] + "/",
            });
            // this.setState({ 
            //     is_verify_enabled: false, 
            //     is_confirm_chk_enabled: true,
            //     pan_information: this.props.aadhaar_result['id_information'] 
            // });

        });

    };
    
    render() {

        // if(this.props.aadhaar_result && this.props.aadhaar_result['aadhaar_stage_verified'])
        //    return <Redirect to={this.props.aadhaar_result['navigateScreen']} />; 

        return (
            <>
                <Header1 />
                <Sidebar />
                <div className="authincation section-padding" style={{ marginTop: 100 }}>
                    <div className="content-body">
                        <div className="container">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div className="auth-form card">
                                        <div className="card-body">
                                            {this.props.loading && <Spinner />}
                                            <h3 className="text-center">Verify AADHAAR</h3>
                                            {this.state.isloading &&
                                                <Spinner animation="border" variant="dark"

                                                    style={{ position: "fixed", top: "50%", left: "50%" }} />
                                            }
                                            {/* <p className="text-center mb-5">PAN card number is compulsory for investing on mutual funds</p> */}
                                            <form action="#">
                                                {
                                                    (this.state.is_verify_enabled || this.state.is_verified_editable) &&
                                                    <div>
                                                        <Form>
                                                            <Form.Group controlId="formFile" className="mb-3">
                                                                
                                                                <span style={{color:'green',fontSize:14, fontWeight:'bold'}}>Note : </span> File format should be png or jpeg
                                                                <hr />

                                                                <div class="row">
                                                                    <div class="col">
                                                                        <span style={{ color: 'InfoText', backgroundColor: '#e3e0de', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }} >
                                                                            Upload front side of your aadhaar card
                                                                        </span>
                                                                        <Form.Label>.</Form.Label>
                                                                        <Form.Control type="file" onChange={this.changeFileHandler('selectedFrontFile')} />
                                                                        {this.state['src_selectedFrontFile'] &&
                                                                            <div className="text-center">
                                                                                <img src={this.state['src_selectedFrontFile']} style={{ marginLeft: -100 }} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div class="col">

                                                                    <span style={{ color: 'InfoText', backgroundColor: '#e3e0de', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }} >
                                                                            Upload back side of your aadhaar card
                                                                        </span>
                                                                        <Form.Label>.</Form.Label>
                                                                        <Form.Control type="file" onChange={this.changeFileHandler('selectedBackFile')} />
                                                                        {this.state['src_selectedBackFile'] &&
                                                                            <div className="text-center">
                                                                                <img src={this.state['src_selectedBackFile']} style={{ marginLeft: -100 }} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <br />

                                                            </Form.Group>

                                                        </Form>

                                                    </div>
                                                }

                                                {
                                                    (this.state.is_verify_enabled || this.state.is_verified_editable) &&
                                                    this.state['src_selectedFrontFile'] && this.state['src_selectedBackFile'] &&
                                                    <div className="text-center">
                                                        <Button
                                                            className="btn btn-success btn-block"
                                                            variant="success"
                                                            onClick={this.onVerify}
                                                            disabled={this.state.isloading}

                                                        >Verify</Button>
                                                        {/* <Link to={'./otp-1'} type="submit" className="btn btn-success btn-block">Verify</Link> */}
                                                    </div>
                                                }

                                                {
                                                    this.state.message_text.length > 5 &&
                                                    <p className="text-center mb-5" style={{color:'#8c4bd1'}}>{
                                                        this.state.message_text
                                                    }</p>
                                                }

                                                {
                                                    this.state.aadhaar_information.length > 0 &&
                                                    <p className="text-center mb-5">Recently uploaded information</p>
                                                }

                                                <div class="row">
                                                    <div class="col-4">
                                                        {this.state.aadhaar_information.map((el, i) =>
                                                            <div key={i}>
                                                            <label className="text-primary">
                                                              {el["attribute"]
                                                                .replace(/_/gm, " ")
                                                                .toUpperCase()}{" "}
                                                              :{" "}<label className="text-dark">{el["text"]}</label>
                                                            </label>
                                                          </div>
                                                        )
                                                        }
                                                        <hr/>                                               
                                                
                                                        {
                                                            !this.state.is_aadhaar_back && 
                                                            <p style={{color:'#e07251'}}>
                                                                You have not uploaded back side of your aadhaar card
                                                            </p>
                                                        }
                                                        {
                                                            !this.state.is_aadhaar_front && 
                                                            <p style={{color:'#e07251'}}>
                                                                You have not uploaded front side of your aadhaar card
                                                            </p>
                                                        }
                                                        {
                                                            (!this.state.is_aadhaar_front || !this.state.is_aadhaar_back) && 
                                                            <p style={{color:'#8c4bd1'}}>
                                                                Kindly upload front & back side of your aadhaar card together again
                                                            </p>
                                                        }

                                                    </div>
                                                    <div class="col">
                                                        {
                                                            <div class="row"/*className="text-center"*/>
                                                                {this.state.aadhaar_attachment_list.map((el, i) =>
                                                                    <div key={i} class="col">
                                                                        <img className="photo" src={el} />
                                                                    </div>
                                                                )
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                <br />
                                                {
                                                    !this.state.is_stage_verified &&
                                                    this.state.is_aadhaar_front && this.state.is_aadhaar_back && 
                                                    <div>
                                                        {

                                                            this.state.is_confirm_chk_enabled &&
                                                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                                <Form.Check type="checkbox"
                                                                    label="I agree and confirm to verify my aadhaar information."
                                                                    value={this.state.is_confirm_chk_checked}
                                                                    onChange={this.handleConfirmCheck} defaultChecked={this.state.is_confirm_chk_checked}
                                                                />
                                                            </Form.Group>
                                                        }

                                                        {
                                                            this.state.is_confirm_chk_checked &&
                                                            <div className="text-center">
                                                                <Button
                                                                    className="btn btn-success btn-block"
                                                                    variant="success"
                                                                    onClick={this.onVerifyStage}
                                                                    disabled={this.state.isloading}
                                                                    >
                                                                    Confirm
                                                                </Button>
                                                            </div>
                                                        }
                                                    </div>

                                                }

                                                {
                                                    this.state.is_stage_verified &&
                                                    <div className="text-center">
                                                        <Link to={'/bankVerification'} type="submit"
                                                            className="btn btn-success pl-5 pr-5">Next</Link>
                                                    </div>
                                                }


{this.state.show_popup && (
                  <Modal show={this.state.show_popup} onHide={() => this.closePopup()}>
                   <Modal.Header>
                      
                   </Modal.Header>
                   <Modal.Body>
                   <p>
                                                            {this.state["pop_messagetext"]}
                                                        </p>
                   </Modal.Body>
                   <Modal.Footer>
                       <Button variant="primary" onClick={() => this.closePopup()}>
                           Close
                       </Button>
                   </Modal.Footer>
               </Modal> 
               )}

                                                {/* {this.state.show_popup && <Popup
                                                    content={<>
                                                       
                                                    </>}
                                                    handleClose={() => this.closePopup()}
                                                />} */}

                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}
// AadhaarVerification.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

const mapStateToProps = state => ({
    aadhaar_result: state.verify.aadhaar_result
});

export default connect(mapStateToProps, { verifyAadhaar, verifyStage, loadStageInfo })
    (AadhaarVerification);