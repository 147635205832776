import React from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { Button,Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  uploadMandate,
  loadBankAccountList,
  loadNachMandateForm,
} from "../../actions/orders";
import { Redirect } from "react-router-dom";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
//import Spinner from "../../layout/spinner";
import { PDFReader } from "reactjs-pdf-reader";

class SipEditCheckout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      request_mode: "",
      selected_order: {},
      bank_information: {},
      loading: false,
      list_mandate: [],
      render_pdf: null,
      enachmandateFile: null,
      src_enachmandateFile: null,
      byte_enachmandateFile: null,
      invest_more_amount: 0.0,
      redeemable_amount: 0.0,
      is_redeem_full_checked: false,
      is_stage_verified: false,
      is_verify_enabled: false,
      is_verified_editable: false,
      esignkyc_information: {},
      esignkyc_attachment_list: {},
      is_esign_verified: false,
      is_esign_button_disabled: false,
      isloading:false
    };
  }

  componentDidMount() {

    this.setState({
      request_mode: this.props.location.state.selected_order["request_mode"],
      selected_order: this.props.location.state.selected_order,
      bank_information:
        this.props.location.state.selected_order["bank_information"],
    });
    // this.loadBankAccountList();
  }

  onLoadBankAccountList = () => {

    this.setState({isloading:true});
    this.props
      .loadBankAccountList({})
      .then((response) => {
        this.setState({isloading:false})
        this.setState({isloading:false})
        if (this.props.bank_result["code"] !== "000")
          alert(this.props.bank_list["messageText"]);
        else {
          if (this.props.bank_result["result"])
            this.setState({
              list_mandate:
                this.props.bank_result["result"]["customer_mandate_list"],
            });
          else this.setState({ list_mandate: [] });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  onloadNachMandateForm = () => {

    this.setState({isloading:true});
    this.props
      .loadNachMandateForm(this.state.selected_order)
      .then((response) => {
        this.setState({isloading:false})
        console.log(this.props.order_result["pdf_base64"]);
        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);
        else {
          if (this.props.order_result["render_pdf"])
            this.setState({
              render_pdf: atob(this.props.order_result["pdf_base64"]),
            });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  gotoPaymentPage() {

    let selected_order = this.state.selected_order;
    this.props.history.push({
      pathname: "/paymentPage/",
      state: { selected_order: selected_order },
    });
  }

  onUploadMandate = () => {
    this.setState({isloading:true})
    let formData = new FormData();
    let body_parameters = { verify_stage: "bank" };

    if (this.state.enachmandateFile) {
      formData.append("upload_file", this.state.enachmandateFile);
      formData.append("id_type", "scanmandate");
      formData.append("mandateType", "XSP");
      formData.append("imageName", this.state.enachmandateFile.name);
      formData.append("imageType", this.state.enachmandateFile.type);
      formData.append("pFileBytes", this.state.byte_enachmandateFile);

      this.props
        .uploadMandate(formData)
        .then((response) => {
            this.setState({isloading:false})
          if (this.props.bank_result["code"] !== "000")
            alert(this.props.bank_result["messageText"]);
          else {
            //   if(this.props.bank_result['state_parameters'])
            //       this.setState(this.props.bank_result['state_parameters']);
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  handleConfirmCheck = (event) => {
    let is_checked = !this.state.is_redeem_full_checked;
    this.setState({
      is_redeem_full_checked: is_checked,
      redeemable_amount: is_checked
        ? this.state.selected_order["mf_order_redeemable_amount"]
        : 0.0,
    });
  };

  handleInvestAmount(event, attribute) {
    const re = /^\d+(\.\d{0,3})?$/; ///^\d*\.?\d*$/ ///^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      this.setState({
        /*invest_more_amount*/ [attribute]: event.target.value.replace(
          /\b0+/g,
          ""
        ),
      });
    }
  }

  changeHandler = async (event) => {

    if (
      event.target.files[0] &&
      (event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/jpg")
    ) {
      {
        const file = event.target.files[0];
        const buffer = await file.arrayBuffer();
        console.log(buffer);
        let byteArray = new Int8Array(buffer);
        this.setState({
          enachmandateFile: file,
          byte_enachmandateFile: byteArray,
          src_enachmandateFile: URL.createObjectURL(file),
        });
      }
    } else {
      this.setState({
        show_popup: true,
        pop_messagetext: "Upload file format should be of PNG, JPEG, JPG only",
      });
    }
  };

  render() {
    // if(this.props.loading)
    //   return <Spinner />;

    // if(this.props.order_result && this.props.order_result['esignkyc_stage_verified'])
    //    return <Redirect to={this.props.aadhaar_result['navigateScreen']} />;

    return (
      <>
        <Header1 />
        <Sidebar />
        {this.state.isloading && <Spinner animation="border" variant="dark" 
        style={{ position: "fixed", top: "40%", left: "50%" }}/>
    }

        <div className="content-body">
          <div className="container">
            <div className="row " style={{marginTop:100}}>
              <div className="col-xl-12">
                {this.state.selected_order["mf_order_registration_no"] && (
                  <div className="auth-form card" style={{marginBottom: 30}}>
                    <div className="card-header">
                      <h4 className="card-title"> Order List</h4>
                    </div>
                    <div className="card-body">
                      <form action="#">
                        <div class="row">
                          <div class="col">
                            <h6 style={{ fontSize: 12 }}>
                              {
                                this.state.selected_order[
                                  "mf_order_scheme_name"
                                ]
                              }
                            </h6>
                          </div>
                          <div class="col-3">
                            <h6 style={{ fontSize: 12 }}>
                              Order No :{" "}
                              {
                                this.state.selected_order[
                                  "mf_order_registration_no"
                                ]
                              }
                            </h6>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <h6 style={{ fontSize: 12 }}>
                              {this.state.selected_order["mf_order_message"]}
                            </h6>
                          </div>
                        </div>

                        {this.state.request_mode === "paynow" && (
                          <div class="row">
                            <div class="col">
                              <h6 style={{ fontSize: 12 }}>
                                Investment Amount: Rs.{" "}
                                {
                                  this.state.selected_order[
                                    "mf_order_investment_amount"
                                  ]
                                }
                              </h6>
                            </div>
                            <div class="col"></div>
                          </div>
                        )}

                        {
                          <div class="row">
                            <div class="col">
                              <h6 style={{ fontSize: 12 }}>
                                Available Investment : Rs.{" "}
                                {
                                  this.state.selected_order[
                                    "mf_order_investment_amount"
                                  ]
                                }
                              </h6>
                            </div>
                            <div class="col">
                              <h6 style={{ fontSize: 12 }}>
                                Total Returns : Rs.{" "}
                                {
                                  this.state.selected_order[
                                    "mf_order_accumulated_returns_amount"
                                  ]
                                }
                              </h6>
                            </div>
                          </div>
                        }
                      </form>
                    </div>

                    {this.state.bank_information["beneficiary_bank_name"] && (
                      <div>
                        <div className="auth-form card" style={{marginBottom:0}}>
                          <div className="card-body">
                            <form action="#">
                              <div class="row">
                                <div class="col">
                                  <h6 style={{ fontSize: 12 }}>
                                    {
                                      this.state.bank_information[
                                        "beneficiary_bank_name"
                                      ]
                                    }
                                  </h6>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col">
                                  <h6 style={{ fontSize: 12 }}>
                                    NAME :{" "}
                                    {
                                      this.state.bank_information[
                                        "beneficiary_account_holder_name"
                                      ]
                                    }
                                  </h6>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col">
                                  <h6 style={{ fontSize: 12 }}>
                                    ACCOUNT NO:{" "}
                                    {
                                      this.state.bank_information[
                                        "beneficiary_account_no"
                                      ]
                                    }
                                  </h6>
                                </div>
                                <div class="col">
                                  <h6 style={{ fontSize: 12 }}>
                                    IFSC :{" "}
                                    {
                                      this.state.bank_information[
                                        "beneficiary_ifsc"
                                      ]
                                    }
                                  </h6>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col">
                                  <h6 style={{ fontSize: 12 }}>
                                    ACCOUNT TYPE :{" "}
                                    {
                                      this.state.bank_information[
                                        "beneficiary_account_type"
                                      ]
                                    }
                                  </h6>
                                </div>
                              </div>

                              {this.state.request_mode === "paynow" && (
                                <div class="row" style={{justifyContent:'center',margin:10}}>
                                  <div class="col-3">
                                    <Button style={{height: '50px', width : '50px' ,fontSize:14}}
                                      className="btn btn-success"
                                      variant="success"
                                      onClick={() => {
                                        this.gotoPaymentPage();
                                      }}
                                    >
                                      Pay Rs.{" "}
                                      {
                                        this.state.selected_order[
                                          "mf_order_pay_amount"
                                        ]
                                      }
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
// OrderCheckout.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

/*
const mapStateToProps = state => ({
    loading: state.order.loading,
    order_result: state.order.order_result
});
*/

const mapStateToProps = ({ order }) => {
  const { loading, order_result } = order;
  return {
    loading,
    order_result
  };
};

export default connect(mapStateToProps, {
  uploadMandate,
  loadBankAccountList,
  loadNachMandateForm,
})(SipEditCheckout);
