import React from "react";
import { Link, Redirect } from "react-router-dom";
import { login } from "../../jsx/actions/auth";
import { Button, Spinner, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { TabPanel, a11yProps } from "../shared/Tabs";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
//import Spinner from "../layout/spinner";
import { propTypes } from "react-bootstrap/esm/Image";
//import Background from '../../images/background/bg.png';
import Background from '../../images/background/bg-14.jpg';
import Backgroundlogin from '../../images/background/bg-23.jpg';
import Popup from './popup';

class Signin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      value: 0,
      login_mobile_number: "",
      signup_mobile_number: "",
      otp_button: false,
      show_popup: false,
      show_signup_popup: false,
      isloading: false,
      otp_validation: ""
    };

  }

  componentDidMount() {

    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
      localStorage.removeItem('profileData');
      localStorage.removeItem('masterList');
    }

    /*
    sessionStorage.removeItem('personal_info_step');
    sessionStorage.removeItem('consolidated_statement_data');
    sessionStorage.removeItem('client_documents_data');
    */
    sessionStorage.clear();

    const reset_info = this.props.location.state;
    if (reset_info && reset_info['reset_info']) {
      this.setState({ value: 1 });
      this.props.history.replace('', null);
    }



  }

  closePopup = () => {
    this.setState({
      show_popup: false
    })
  }

  onSubmit = (login_request_from_value, event) => {
    // event.preventDefault();
    // console.log(event)
    const { email, password, login_mobile_number, signup_mobile_number } = this.state;

    this.setState({ isloading: true })
    let body_parameters = {
      login_mobile_number: this.state.show_signup_popup ? signup_mobile_number : login_mobile_number,
      login_email: email,
      login_role: "customer",
      login_hint: password,
      login_request_from: login_request_from_value,
      screen_name: 'signin'
    };

    this.props
      .login(body_parameters)
      .then((response) => {

        this.setState({ isloading: false });
        if (this.props.login_result["code"] !== "000")
          this.setState({
            show_popup: true
          });
        else
          this.props.history.push({
            pathname: '/' + this.props.login_result['navigateScreen'] + '/',
            state: {
              route_parameters: {
                login_mobile_number: body_parameters['login_mobile_number'],
                login_email: email,
                login_role: "customer",
                login_request_from: login_request_from_value
              }
            }

          });

      })
      .catch((err) => {
        alert(err);
      });
  };


  otpvalidation = (event) => {

    this.setState({ otp_validation: "" })
    const mobile = /^[6-9]\d{9}$/gm;

    const targetValue = event.target.value.replace(/\s/g, '');

    if (this.state.show_signup_popup)
      this.setState({ signup_mobile_number: targetValue });
    else
      this.setState({ login_mobile_number: targetValue });

    if (mobile.test(targetValue)) {
      if (targetValue.length >= 10)
        this.setState({ otp_button: true });
      this.setState({ otp_validation: "" })
    }
    else {
      this.setState({ otp_button: false });
      this.setState({ otp_validation: "Please enter a valid mobile number" });
    }
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };
  handleClick() {
    console.log('this is:', this);
  }
  Cancelregistration = () => {
    this.setState({ show_signup_popup: false })
  }
  render() {

    // if (this.props.login_result && this.props.login_result["code"] === '000' && 
    //     this.props.login_result['navigateScreen']) {
    //   return <Redirect to={this.props.login_result["navigateScreen"]} />;
    // }

    return (
      <>

        <div style={{
          background: `url(${Backgroundlogin})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          zIndex: 0
        }}>


          <div className="authincation section-padding" >
            <div className="container h-100">
              <div className="row justify-content-center h-100">
                <div className="col-xl-5 col-md-6">
                  <div className="mini-logo text-center my-1">
                    <Link to={"./"}>
                      <img src={require("./../../images/logo.png")} alt="" width="200px" />
                    </Link>
                  </div>
                  <div className="auth-form card">
                    <div className="card-header justify-content-center">
                      <div style={{fontSize:16}}>
                        Mutual Fund Investment App 
                        {/* <br />
                        <span style={{ textAlign: 'center', fontSize: 14, paddingLeft: 45 }}>Sign In</span> */}
                      </div>

                    </div>
                    <div className="card-body">
                      <Box sx={{ width: "90%" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            aria-label="basic tabs example"
                            textColor="primary"
                            indicatorColor="primary"

                          >
                            <Tab label="Mobile Number" classname="btn btn-primary"  {...a11yProps(0)} />
                            <Tab label="Email Id" classname="btn btn-primary" {...a11yProps(1)} />
                          </Tabs>
                        </Box>
                        <TabPanel value={this.state.value} index={0} classname="btn btn-primary">

                          <div>

                            <div className="form-group">
                              {/* {this.state.login_mobile_number.length > 0 &&
                                <label>Mobile Number</label>
                              } */}
                              <input
                                className="form-control"
                                pattern="[0-9]*"
                                maxLength="10"
                                name="login_mobile_number"
                                value={this.state.login_mobile_number}
                                placeholder="Enter Mobile Number"
                                onChange={(event) => {

                                  if (event.target.value.trim().length === 0 ||
                                    event.target.value.match(/^[0-9]+$/)) {

                                    this.otpvalidation(event)
                                  }

                                }
                                }
                                onKeyPress={(event) => {
                                  if (this.state.otp_button && event.key === 'Enter') {
                                    this.onSubmit("mobile");
                                  }
                                }}
                              />
                            </div>
                            {this.state.isloading &&
                              <Spinner animation="border" variant="dark"
                                style={{ position: "fixed", top: "50%", left: "50%" }} />
                            }
                            {(this.state.login_mobile_number.length > 1 || '') &&
                              <div className="form-group">
                                <label className="text-danger">{this.state.otp_validation}</label></div>}
                            {(
                              <div className="text-center">
                                <p></p>

                                <Button
                                  disabled={!this.state.otp_button}
                                  className="btn btn-success btn-block"
                                  variant="success"
                                  onKeyPress={(event) => this.onSubmit("mobile", event)}
                                  onClick={(event) => this.onSubmit("mobile", event)}
                                >
                                  Sign in with OTP
                                </Button>

                              </div>
                            )}
                          </div>

                        </TabPanel>
                        <TabPanel value={this.state.value} index={1} classname="btn btn-primary">
                          <div className="form-group">
                            {this.state.email.length > 0 &&
                              <label>Email</label>
                            }
                            <input onFocus={this.onFocus}
                              type="email"
                              className="form-control"
                              placeholder="Enter Email Address"
                              name="email"
                              value={this.state.email}
                              maxLength="35"
                              onChange={(event) => {
                                this.setState({ email: event.target.value.toLowerCase() })
                              }}
                            />
                          </div>
                          <p></p>
                          <div className="form-group">
                            {this.state.password.length > 0 &&
                              <label>Password</label>
                            }
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Enter Password"
                              pattern="[0-9]*"
                              maxLength="22"
                              name="password"
                              value={this.state.password}
                              onChange={(event) =>
                                this.setState({ password: event.target.value })
                              }
                            />
                          </div>
                          <div className="form-row d-flex justify-content-between mt-4 mb-2">
                            <div className="form-group mb-0">

                            </div>
                            <div className="form-group mb-0">
                              <Link to="./reset" className="text-success">Forgot Password?</Link>
                            </div>

                          </div>
                          {
                            <div className="text-center" style={{ marginTop: 20 }}>
                              <Button
                                className="btn btn-success btn-block"
                                variant="success"
                                onClick={() => this.onSubmit("email")}
                                disabled={
                                  !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email) ||
                                  this.state.email.trim().length < 7 ||
                                  this.state.password.length < 7
                                }
                              >
                                Next
                              </Button>
                            </div>
                          }

                        </TabPanel>
                      </Box>
                    </div>

                    <div className="new-account mt-3" style={{ marginLeft: 25, marginBottom: 20, marginTop: -20 }}>
                      <span>
                        For New Account Registration,
                      </span>
                      <Button
                        className="text-success"
                        variant="link"
                        onClick={() => {
                          this.setState({ show_signup_popup: true })
                        }}
                      >
                        Sign Up Here
                      </Button>
                      {/* <Link className="text-primary" to={"./signup"}>
                          Sign up
                        </Link>  */}
                    </div>

                    {this.state.show_popup && this.props.login_result &&
                      this.props.login_result["messageText"] &&
                      <Modal show={this.state.show_popup} onHide={() => this.closePopup()}>
                        <Modal.Header>

                        </Modal.Header>
                        <Modal.Body>
                          <p>  {this.props.login_result["messageText"]}</p>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="primary" onClick={() => this.closePopup()}>
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>

                    }

                    <Modal show={this.state.show_signup_popup} onHide={() => this.Cancelregistration()}>
                      <Modal.Header closeButton>
                        <Modal.Title>Sign Up</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="auth-form card">
                          <div className="card-body">
                            {this.state.isloading &&
                              <Spinner animation="border" variant="dark"
                                style={{ position: "fixed", top: "50%", left: "50%" }} />
                            }
                            <div className="form-group">
                              <label>Enter your mobile number</label>
                              <input
                                className="form-control"
                                placeholder=""
                                pattern="[0-9]*"
                                maxLength="10"
                                name="signup_mobile_number"
                                value={this.state.signup_mobile_number}
                                onChange={(event) => {

                                  if (event.target.value.trim().length === 0 ||
                                    event.target.value.match(/^[0-9]+$/)) {

                                    this.otpvalidation(event)
                                  }

                                }
                                }
                              />
                            </div>
                            {this.state.otp_button && (
                              <div className="text-center" style={{ marginTop: 20 }}>
                                <Button
                                  className="btn btn-success btn-block"
                                  variant="success"
                                  onClick={() => this.onSubmit("mobile")}
                                >
                                  Submit
                                </Button>
                                {/* <Link to={'./otp-1'} type="submit" className="btn btn-success btn-block">Verify</Link> */}
                              </div>
                            )}
                          </div>

                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="primary" onClick={() => this.Cancelregistration()}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    {/*
                    {this.state.show_signup_popup && <Popup
                      content={<>

                          <div className="auth-form card">
                            <div className="card-body">
                              <div className="form-group">
                                <label>Enter your mobile number</label>
                                <input
                                  className="form-control"
                                  placeholder=""
                                  pattern="[0-9]*"
                                  maxLength="10"
                                  name="signup_mobile_number"
                                  value={this.state.signup_mobile_number}
                                  onChange={(event) => 
                                    
                                    {
                                                    
                                      if (event.target.value.trim().length === 0 ||
                                         event.target.value.match(/^[0-9]+$/)) {
                                        
                                        this.otpvalidation(event)
                                      }
    
                                    }  
                                  }
                                />
                              </div>
                              {this.state.otp_button && (
                                  <div className="text-center">
                                    <Button
                                      className="btn btn-success btn-block"
                                      variant="success"
                                      onClick={() => this.onSubmit("mobile")}
                                    >
                                      Submit
                                    </Button>
                                     <Link to={'./otp-1'} type="submit" className="btn btn-success btn-block">Verify</Link> 
                                  </div>
                              )}
                            </div>
                          
                          </div>
                      </>}
                      handleClose={() => {
                        this.setState({ show_signup_popup: false })
                      }}
                    />}*/}
                    <div className="form-group text-center">
                      <label className="text-dark">V.0.0.2</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
Signin.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,

};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  login_result: state.auth.login_result,
  loading: state.master.loading,
});

export default connect(mapStateToProps, { login })(Signin);
