import api from '../utils/api';
import { setAlert } from './alert';
import {
  VERIFY_PAN_SUCCESS,
  VERIFY_AADHAAR_SUCCESS,
  VERIFY_BANK_SUCCESS,
  VERIFY_PERSONAL_SUCCESS,
  VERIFY_CLIENT_LIST_SUCCESS,
  VERIFY_FAIL
} from './types';
import { API_END_POINTS } from '../constants/constants';

export const verifyIdProof = (body_parameters, callbackFunction) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    const res = await api.put(API_END_POINTS.VALIDATE_IDPROOF, body, {
      headers: {
        'content-type': `multipart/form-data`,
      }
    });

    callbackFunction(res['data'])

    // dispatch({
    //   type: VERIFY_PAN_SUCCESS,
    //   payload: res.data
    // });
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: VERIFY_FAIL
    });

  }
};

export const verifyPan = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {
    console.log(body)
    const res = await api.put(API_END_POINTS.VALIDATE_PAN, body, {
      headers: {
        'content-type': `multipart/form-data`,
      }
    });

    dispatch({
      type: VERIFY_PAN_SUCCESS,
      payload: res.data
    });
    
   
  //  dispatch(loadUser());
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: VERIFY_FAIL
    });

  }
};

export const verifyAadhaar = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {

    const res = await api.put(API_END_POINTS.VALIDATE_AADHAAR, body);

    dispatch({
      type: VERIFY_AADHAAR_SUCCESS,
      payload: res.data
    });
    
   
  //  dispatch(loadUser());
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: VERIFY_FAIL
    });

  }
};

export const verifyBank = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {

    const res = await api.post(API_END_POINTS.VALIDATE_BANK_ACCOUNT, body);

    dispatch({
      type: VERIFY_BANK_SUCCESS,
      payload: res.data
    });
    
   
  //  dispatch(loadUser());
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: VERIFY_FAIL
    });

  }
};

export const verifyAccount = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {
    console.log(body_parameters);
    const res = await api.put(API_END_POINTS.VALIDATE_PERSONAL, body, {
        headers: {
        'content-type': `multipart/form-data`,
      }
    });
    
    console.log(res.data);

    dispatch({
      type: VERIFY_PERSONAL_SUCCESS,
      payload: res.data
    });
    
   
  //  dispatch(loadUser());
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: VERIFY_FAIL
    });

  }
};

export const verifyStage = (body_parameters, verify_stage, callbackFunction) => async dispatch => {
   
  const body = body_parameters;
  console.log(body);

  try {
    
    let res ;
    if(verify_stage === 'esignkyc')
       res = await api.post(API_END_POINTS.VERIFY_STAGE, body);
    else
      res = await api.put(API_END_POINTS.VERIFY_STAGE, body);
    
    dispatch({
      type: verify_stage === 'pan' ? 
        VERIFY_PAN_SUCCESS : (
          verify_stage === 'aadhaar' ? VERIFY_AADHAAR_SUCCESS : 
          (verify_stage === 'bank' ? VERIFY_BANK_SUCCESS : 
          (verify_stage === 'personal' ? VERIFY_PERSONAL_SUCCESS : VERIFY_PERSONAL_SUCCESS))
        ),
      payload: res.data
    });
    
    callbackFunction(res['data'])
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: VERIFY_FAIL
    });

  }
};

export const updatePersonalInfo = (body_parameters, callbackFunction) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    let res = await api.post(API_END_POINTS.UPDATE_PERSONAL, body);
    
    dispatch({
      type: VERIFY_PERSONAL_SUCCESS,
      payload: res.data
    });
    
    callbackFunction(res['data'])
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: VERIFY_FAIL
    });

  }
};

export const createClientCode = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {

    const res = await api.put(API_END_POINTS.CLIENT_CODE_CREATE, body);

    dispatch({
      type: VERIFY_PERSONAL_SUCCESS,
      payload: res.data
    });
    
   
  //  dispatch(loadUser());
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: VERIFY_FAIL
    });

  }
};

export const elogPhotoUpload = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {

    const res = await api.put(API_END_POINTS.ELOG_PHOTO_UPLOAD, body);

    dispatch({
      type: VERIFY_CLIENT_LIST_SUCCESS,
      payload: res.data
    });
    
   
  //  dispatch(loadUser());
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: VERIFY_FAIL
    });

  }
};

export const loadStageInfo = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    let res = await api.post(API_END_POINTS.FETCH_STAGE, body);
    
    dispatch({
      type: body['stage_name'] === 'pan' ? 
        VERIFY_PAN_SUCCESS : (
          body['stage_name'] === 'aadhaar' ? VERIFY_AADHAAR_SUCCESS : 
          (body['stage_name'] === 'bank' ? VERIFY_BANK_SUCCESS : VERIFY_PERSONAL_SUCCESS)
        ),
      payload: res.data
    });
    
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: VERIFY_FAIL
    });

  }
};

export const loadClientList = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    let res = await api.post(API_END_POINTS.LIST_CLIENT, body);
    
    dispatch({
      type: VERIFY_CLIENT_LIST_SUCCESS,
      payload: res.data
    });
    
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: VERIFY_FAIL
    });

  }
};

export const loadBankAccountList = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    let res = await api.post(API_END_POINTS.LIST_BANKACCOUNT, body);
    
    dispatch({
      type: VERIFY_BANK_SUCCESS,
      payload: res.data
    });
    
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: VERIFY_FAIL
    });

  }
};

export const validateBankAccount = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {

    const res = await api.post(API_END_POINTS.VALIDATE_BANK_ACCOUNT, body);

    dispatch({
      type: VERIFY_BANK_SUCCESS,
      payload: res.data
    });
    
   
  //  dispatch(loadUser());
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: VERIFY_FAIL
    });

  }
};

export const addBankAccount = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {

    const res = await api.post(API_END_POINTS.ADD_BANK_ACCOUNT, body);

    dispatch({
      type: VERIFY_BANK_SUCCESS,
      payload: res.data
    });
    
   
  //  dispatch(loadUser());
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: VERIFY_FAIL
    });

  }
};

export const saveBankAccount = (body_parameters, request_mode, callbackFunction) => async dispatch => {
   
  const body = body_parameters;
  console.log(body);

  try {
    
    let res ;
    res = (request_mode === 'add' ? await api.put(API_END_POINTS.SAVE_BANK_ACCOUNT, body) : 
        await api.post(API_END_POINTS.SAVE_BANK_ACCOUNT, body) );
    
    dispatch({
      type: VERIFY_BANK_SUCCESS,
      payload: res.data
    });
    
    callbackFunction(res['data'])
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: VERIFY_FAIL
    });

  }
};