


import React from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { Button, Spinner, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  verifyPan,
  verifyStage,
  loadStageInfo,
} from "../../../jsx/actions/verify";
import { Redirect } from "react-router-dom";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import Select from 'react-select';

class PanVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_edit: false,
      is_stage_verified: false,
      is_verify_enabled: false,
      is_confirm_chk_enabled: false,
      is_confirm_chk_checked: false,
      is_verified_editable: false,
      selectedFile: null,
      src_selectedFile: null,
      pan_information: [],
      pan_attachment_list: [],
      valid_pan_invalid_dob: false,
      popup_dob_messageText: '',
      show_dob_popup: false,
      show_popup: false,
      pop_messagetext: "",
      isloading: this.props.loading,
      pan_dob_month: '',
      pan_dob_date: '',
      pan_dob_year: new Date().getFullYear() - 18,
      pan_dob_date_list: [],
      pan_dob_month_list: [
        { label: 'January', value: '01'}, { label: 'February', value: '02'},
        { label: 'March', value: '03'}, { label: 'April', value: '04'},
        { label: 'May', value: '05'}, { label: 'June', value: '06'},
        { label: 'July', value: '07'}, { label: 'August', value: '08'},
        { label: 'September', value: '09'}, { label: 'October', value: '10'},
        { label: 'November', value: '11'}, { label: 'December', value: '12'},
      ],
      pan_dob_year_list: [],
      pan_dob: '',
      is_pan_dob_valid: true
    };

  }

  componentDidMount() {
    this.onLoadStageInfo();
  }

  closePopup = () => {
    this.setState({
      show_popup: false,
      pop_messagetext: "",
    });
  };

  changeHandler = (event) => {

    if (
      event.target.files[0] &&
      (event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/jpg")
    ) {
      this.setState({
        selectedFile: event.target.files[0],
        src_selectedFile: URL.createObjectURL(event.target.files[0]),
      });
    } else {

      if (event.target.files.length > 0)
        this.setState({
          show_popup: true,
          pop_messagetext: "Upload file format should be of PNG, JPEG, JPG only",
        });

    }
  };

  handleConfirmCheck = (event) => {
    if(this.state.valid_pan_invalid_dob) {
      this.setState({
        is_confirm_chk_checked: !this.state.is_confirm_chk_checked,
        show_dob_popup: true, 
        popup_dob_messageText: 'Please confirm your date of birth as per the pan card'
      });
    }
    else {
      this.setState({
        is_confirm_chk_checked: !this.state.is_confirm_chk_checked,
      });
    }
  };

  isEdit = () => {
    this.setState({
      is_edit: true,
      is_verify_enabled: true,
      // is_confirm_chk_enabled: false
    });
  };

  isCancelEdit = () => {
    this.setState({
      is_edit: false,
      is_verify_enabled: false,
    });
  };

  onLoadStageInfo = () => {

    this.setState({ isloading: true });

    this.props
      .loadStageInfo({ stage_name: "pan" })
      .then((response) => {

        let pan_result = this.props.pan_result;

        let pan_dob_date_list = [];
          for(var index = 1; index <= 31; index++) {
            pan_dob_date_list.push({ 
              label: (index < 10 ? '0' : '') + index,
              value: (index < 10 ? '0' : '') + index
            })
          }

          let dob_minimum_year = new Date().getFullYear() - 18;
          let pan_dob_year_list = [];
          for(var index = dob_minimum_year; index >= dob_minimum_year - 72; index--) {
            pan_dob_year_list.push({ 
              label: index,
              value: index.toString()
            })
          }

        if (pan_result["code"] !== "000") {
          this.setState({ isloading: false });
          alert(pan_result["messageText"]);
        }
        else {
          
            let _pan_information = pan_result['id_information'];
            let _pan_dob_year = '';
            _pan_information.forEach(el => {
              if(el['attribute'] === 'dob') {
                if(el['text'].trim().length >= 4)
                  _pan_dob_year = el['text'].substring(el['text'].length - 4);
              }
            });
          if (pan_result["state_parameters"]) {
            this.setState( Object.assign(
                pan_result["state_parameters"],
                {
                  isloading: false,
                  pan_dob_date_list: pan_dob_date_list,
                  pan_dob_year_list: pan_dob_year_list,
                  valid_pan_invalid_dob: !pan_result['id_information']['is_dob_valid'],
                  pan_dob_year: _pan_dob_year
                }
              )
            );
              
          }
          else
            this.setState({
              is_stage_verified: pan_result["is_stage_verified"],
              is_verify_enabled: pan_result["is_verify_enabled"],
              is_confirm_chk_enabled:
                pan_result["is_confirm_chk_enabled"],
              is_verified_editable:
                pan_result["is_verified_editable"],
              pan_information: pan_result["id_information"],
              pan_attachment_list: pan_result["attachment_list"],
              isloading: false,
              pan_dob_date_list: pan_dob_date_list,
              pan_dob_year_list: pan_dob_year_list,
              valid_pan_invalid_dob: !pan_result['id_information']['is_dob_valid'],
              pan_dob_year: _pan_dob_year
            });
        }

      })
      .catch((err) => {
        this.setState({ isloading: false });
        alert(err);
      });
  };

  onVerify = () => {

    this.setState({ isloading: true })
    let formData = new FormData();

    formData.append("upload_pan", this.state.selectedFile);
    formData.append("id_type", "pan");
    formData.append("folder_name", "pan");
    formData.append("attachment_category", "pan");

    this.props
      .verifyPan(formData)
      .then((response) => {

        let pan_result = this.props.pan_result;

        this.setState({ isloading: false })
        if (pan_result["code"] !== "000")
          alert(pan_result["messageText"]);
        else {
          if (pan_result["state_parameters"]) {
            if(pan_result['state_parameters']['valid_pan_invalid_dob']) 
            {
              let _pan_information = pan_result['id_information'];
              _pan_information.forEach(el => {
                if(el['attribute'] === 'dob') {
                  if(el['text'].trim().length >= 4)
                     pan_result["state_parameters"]['pan_dob_year'] = el['text'].substring(el['text'].length - 4);
                }
              });
              
              this.setState(
                Object.assign(pan_result["state_parameters"],
                {
                  show_dob_popup: true,
                  popup_dob_messageText: pan_result["messageText"]
                })
              );              
              //

            }
          }
          else
            this.setState({
              is_stage_verified: pan_result["is_stage_verified"],
              is_verify_enabled: pan_result["is_verify_enabled"],
              is_confirm_chk_enabled:
                pan_result["is_confirm_chk_enabled"],
              is_verified_editable:
                pan_result["is_verified_editable"],
              pan_information: pan_result["id_information"],
              pan_attachment_list: pan_result["attachment_list"],
            });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  onVerifyStage = () => {

    this.setState({ isloading: true })
    this.props.verifyStage({ 
        verify_stage: "pan", 
        valid_pan_invalid_dob: this.state.valid_pan_invalid_dob,
        pan_dob: this.state.pan_dob
      }, "pan", (response) => {
        this.setState({ isloading: false })
        this.props.history.push({
          pathname: "/" + this.props.pan_result["navigateScreen"] + "/",
        });
        // this.setState({
        //     is_verify_enabled: false,
        //     is_confirm_chk_enabled: true,
        //     pan_information: this.props.pan_result['id_information']
        // });
    });
  };

  handleChangeSelect = (selectedOption, attribute) => {
      
    let _pan_dob = '';
    if(attribute === 'pan_dob_date')
        _pan_dob = selectedOption.value + '/' + this.state.pan_dob_month + '/' + this.state.pan_dob_year;
    else if(attribute === 'pan_dob_month')
       _pan_dob = this.state.pan_dob_date + '/' + selectedOption.value + '/' + this.state.pan_dob_year;
    else if(attribute === 'pan_dob_year')
       _pan_dob = this.state.pan_dob_date + '/' + this.state.pan_dob_month + '/' + selectedOption.value;

    let _pan_information = this.props.pan_result['id_information'];
    _pan_information.forEach(el => {
      if(el['attribute'] === 'dob')
         el['text'] = _pan_dob;
    });

    let _format_pan_dob = _pan_dob.split('/');
    
    if(this.isValidDate(_format_pan_dob[2], _format_pan_dob[1], _format_pan_dob[0])) 
    {
      this.setState({
        [attribute]: selectedOption.value,
        pan_dob: _pan_dob,
        pan_information: _pan_information,
        // is_pan_dob_valid: true
      });
    }
    else {
      this.setState({
        [attribute]: selectedOption.value,
        pan_dob: _pan_dob,
        // is_pan_dob_valid: false
      });
    }

  }

  isValidDate = (year, month, day) => {

    var d = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    return month == d.getMonth() + 1;
  }

  render() {
    //    if(this.props.pan_result && this.props.pan_result['pan_stage_verified'])
    //        return <Redirect to={this.props.pan_result['navigateScreen']} />;

    return (
      <>
        <Header1 />
        <Sidebar />
        <div className="authincation section-padding">
          <div className="content-body" style={{ marginTop: 100 }}>
            <div className="container">
              <div class="row">
                <div class="col-xl-12">
                  <div className="auth-form card">
                  <h4 className="text-center">Verify PAN </h4>
                    <div className="card-body">

                      {this.state.isloading &&
                        <Spinner animation="border" variant="secondary"
                          style={{ position: "fixed", top: "50%", left: "50%" }} />
                      }

                      <form action="#">
                        {
                          <div className="row">
                            <div className="col-6">
                              <Form>
                                <Form.Group controlId="formFile" className="mb-3">
                                  {(this.state.is_verify_enabled ||
                                    this.state.is_verified_editable) && (
                                      <div>

                                        <span style={{ color: 'green', fontSize: 14, fontWeight: 'bold' }}>Note : </span> Upload only front side of your pan card.
                                        Format should be png or jpeg
                                        <hr></hr>
                                        <Form.Control
                                          type="file"
                                          onChange={this.changeHandler}
                                        />

                                      </div>
                                    )}

                                  {(this.state.is_verify_enabled ||
                                    this.state.is_verified_editable) &&
                                    this.state["src_selectedFile"] && (
                                      <div className="text-center">
                                        <img
                                          src={this.state["src_selectedFile"]}
                                          className="photo"
                                        />
                                      </div>
                                    )}
                                </Form.Group>
                              </Form>
                              <div className="text-center">
                                {(this.state.is_verify_enabled ||
                                  this.state.is_verified_editable) &&
                                  this.state["src_selectedFile"] && (
                                    <Button
                                      className="btn btn-success btn-block"
                                      variant="success"
                                      onClick={this.onVerify}
                                      disabled={this.state.isloading}
                                    >
                                      Verify
                                    </Button>
                                  )}
                              </div>
                            </div>
                            <div className="col-6">
                              {this.state.pan_information.length > 0 && (
                                <p className="text-center mb-5">
                                  Recently uploaded information
                                </p>
                              )}
                              {this.state.isloading &&
                                <Spinner animation="border" variant="dark"
                                  style={{ position: "fixed", top: "50%", left: "50%" }} />
                              }
                              <div class="row">
                                <div class="col-5">
                                  {this.state.pan_information.map((el, i) => (
                                    <div key={i}>
                                      <label className="text-primary">
                                        {el["attribute"]
                                          .replace(/_/gm, " ")
                                          .toUpperCase()}{" "}
                                        :{" "}
                                          <label className="text-dark">
                                            {el["text"] === true ? `YES` : 
                                            (el["text"] === false ? 'NO' : el["text"])}
                                          </label>
                                      </label>
                                    </div>
                                  ))}

                                </div>
                                <div class="col">
                                  {
                                    <div className="text-center">
                                      {(this.state.pan_attachment_list || []).map(
                                        (el, i) => (
                                          <div key={i} className="form-group">
                                            <img src={el} className="photo" />
                                          </div>
                                        )
                                      )}
                                    </div>
                                  }

                                </div>
                              </div>

                              {!this.state.is_stage_verified && (
                                <div>
                                  <hr/>
                                  {this.state.is_confirm_chk_enabled && (
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicCheckbox"
                                    >
                                      <Form.Check
                                        type="checkbox"
                                        label="I agree and confirm to save my pan information."
                                        value={this.state.is_confirm_chk_checked}
                                        onChange={this.handleConfirmCheck}
                                        defaultChecked={
                                          this.state.is_confirm_chk_checked
                                        }
                                      />
                                    </Form.Group>
                                  )}
                                  {this.state.is_confirm_chk_checked && (
                                    <div className="text-center">
                                      <Button
                                        className="btn btn-success btn-block"
                                        variant="success"
                                        onClick={this.onVerifyStage}
                                        disabled={this.state.isloading}
                                      >
                                        Confirm
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        }

                        {/* <div className="form-group">
                            <label>Enter your Date of Birth </label>
                            <Form.Control type="date" name='date_of_birth'  />
                        </div> */}

                        {this.state.is_stage_verified && (
                          <div className="text-center">
                            <Link
                              to={"./aadhaarVerification"}
                              type="submit"
                              className="btn btn-success pl-5 pr-5"
                            >
                              Next
                            </Link>
                          </div>
                        )}
                        {this.state.show_popup && (
                          <Modal show={this.state.show_popup} onHide={() => this.closePopup()}>
                            <Modal.Header>

                            </Modal.Header>
                            <Modal.Body>

                              <p>{this.state["pop_messagetext"]}</p>

                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="primary" onClick={() => this.closePopup()}>
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        )}
                        
                        {this.state.show_dob_popup && (
                          <Modal show={this.state.show_dob_popup}>
                            <Modal.Header>
                              <p>{this.state.popup_dob_messageText}</p>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row">
                                  <div className="col-4">
                                    Month
                                  </div>
                                  <div className="col-4">
                                    Date
                                  </div>
                                  <div className="col-4">
                                    Year
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-4">
                                    <Select placeholder={`-Select-`}
                                      value={this.state.pan_dob_month_list.find(object => object.value === this.state.pan_dob_month || '')}
                                      onChange={(e) => { this.handleChangeSelect(e, 'pan_dob_month') }}
                                      options={this.state.pan_dob_month_list}
                                      isSearchable={false} //
                                    />
                                  </div>   
                                  <div className="col-4">
                                    <Select placeholder={`-Select-`}
                                      value={this.state.pan_dob_date_list.find(object => object.value === this.state.pan_dob_date || '')}
                                      onChange={(e) => { this.handleChangeSelect(e, 'pan_dob_date') }}
                                      options={this.state.pan_dob_date_list}
                                      isSearchable={false}
                                    />
                                  </div>   
                                  <div className="col-4">
                                    <Select placeholder={`-Select-`}
                                      value={this.state.pan_dob_year_list.find(object => object.value === this.state.pan_dob_year || '')}
                                      onChange={(e) => { this.handleChangeSelect(e, 'pan_dob_year') }}
                                      options={this.state.pan_dob_year_list}
                                      isSearchable={false}
                                    />
                                  </div>
                              </div>
                              {!this.state.is_pan_dob_valid &&
                                <div className="row">
                                  <span style={{color:'red'}}>
                                      Entered Date Of Birth is not valid
                                  </span>
                                </div>
                              }
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="primary" onClick={() => {

                                  let _format_pan_dob = this.state.pan_dob.split('/');
                                  let _is_valid = this.isValidDate(_format_pan_dob[2],  _format_pan_dob[1], _format_pan_dob[0]);

                                  {
                                    this.setState({
                                      is_pan_dob_valid: _is_valid,
                                      show_dob_popup: !_is_valid
                                    });
                                  }

                              }}>
                                Submit
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
// PanVerification.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

const mapStateToProps = (state) => ({
  pan_result: state.verify.pan_result,
});

export default connect(mapStateToProps, {
  verifyPan,
  verifyStage,
  loadStageInfo,
})(PanVerification);
