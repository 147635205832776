import React, { } from 'react';
import { Link } from 'react-router-dom';
import { Form, Spinner } from 'react-bootstrap';
import { connect } from "react-redux";
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { loadNomineeList, createOrder, modifyOrder, uploadNominee, twoFactorAuthentication } from '../../actions/orders';
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import { saveAs } from "file-saver";
import { PDFReader } from 'reactjs-pdf-reader';
import OTPInput, { ResendOTP } from "otp-input-react";

class OrderCheckout extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            selected_order: {},
            general_order: {},
            list_bank_account: [],

            /****** NOMINEE OPTIONS *******/
            list_nominee: [],
            nominee_option: 'existing',
            existing_nominee_id: '',
            existing_nominee_selected: false,
            is_no_nominee_registered: false,
            no_nominee_form: {},
            nonomineeformFile: null,
            src_nonomineeformFile: null,
            byte_nonomineeformFile: null,
            client_code: '',
            /****** NOMINEE OPTIONS *******/

            is_otp_enabled: false,
            two_factor_authentication: {
                mobile_otp: '',
                email_otp: '',
                twofactor_token_id: ''
            },

            loading: false,
            nofitication_html: ``,
            render_pdf: null,

            invest_more_amount: 0.00,
            redeemable_amount: 0.00,
            is_redeem_full_checked: false,
            is_stage_verified: false,
            is_verify_enabled: false,
            is_verified_editable: false,
            esignkyc_information: {},
            esignkyc_attachment_list: {},
            is_esign_verified: false,
            is_esign_button_disabled: false,
            show_popup: false,
            pop_messagetext: '',
            isloading: false,
            error: ''
        };

    }

    componentDidMount() {

        this.setState(this.props.location.state);
        this.props
            .loadNomineeList({
                scheme_name: this.props.location.state.selected_order['param_scheme_name'],
                scheme_code: this.props.location.state.selected_order['param_scheme_code']
            })
            .then((response) => {

                if (this.props.order_result["code"] !== "000") {
                    // alert(this.props.order_result["messageText"]);
                }
                else {
                    if (this.props.order_result["nominee_list"]) {

                        this.setState({
                            isloading: false,
                            nominee_list: this.props.order_result["nominee_list"],
                            is_no_nominee_registered: this.props.order_result["is_no_nominee_registered"],
                            no_nominee_form: this.props.order_result["no_nominee_form"] ||
                                this.props.location.state['no_nominee_form'],
                        });
                    }
                }
            })
            .catch((err) => {
                alert(err);
            });
        // window.history.replaceState(null, '');
    }

    gotoPaymentPage() {

        this.props.history.push({
            pathname: '/paymentPage/',
            state: { selected_order: this.state.selected_order }
        });

    }

    onCreateOrder = () => {

        this.setState({ isloading: true });

        let _bank_account = (this.state.list_bank_account || []).filter(el => {
            return el['is_primary'] === true;
        })[0];

        this.props.createOrder({
            client_info: {
                'scheme_code': this.state.general_order['scheme_code'],
                'scheme_name': this.state.general_order['scheme_name'],
                'scheme_isin': this.state.general_order['scheme_isin'],
                'buy_sell': 'P',
                'buy_sell_type': 'FRESH',
                'order_amount': this.state.general_order['order_amount'],
                'bank_account_information': _bank_account,
                'selected_order': this.state.selected_order,
                'nominee_option': this.state.nominee_option,
                'nominee_id': this.state.existing_nominee_id
            },
            two_factor_authentication: this.state.two_factor_authentication

        }).then(response => {

            this.setState({ isloading: false });
            if (this.props.order_result['code'] !== '000') {
                /*
                this.setState({
                    show_popup: true,
                    pop_messagetext: this.props.order_result['messageText']
                });
                */
                alert(this.props.order_result['messageText']);
            }
            else {
                if (this.props.order_result['result']) {

                    this.props.history.push({
                        pathname: '/orderEditCheckout/',
                        state: { selected_order: this.props.order_result['result'] }
                    });

                }

            }

        })
            .catch(err => {
                this.setState({ error: err })
            });


    };

    handleInvestAmount(event, attribute) {

        const re = /^\d+(\.\d{0,3})?$/ ///^\d*\.?\d*$/ ///^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({
                /*invest_more_amount*/[attribute]: event.target.value.replace(/\b0+/g, '')
            });
        }

    }

    handleNomineeCheck = (e, item) => {

        let _existing_nominee_selected = false, _existing_nominee_id = '';
        const _list = this.state.list_nominee;
        _list.forEach(el => {

            if (el['id'] === item['id']) {
                item['is_selected'] = !item['is_selected'];
                _existing_nominee_selected = item['is_selected'];
                if (item['is_selected'])
                    _existing_nominee_id = item['id'];
                else
                    _existing_nominee_id = '';
            }
            else
                item['is_selected'] = false;

        });

        this.setState({
            list_nominee: _list,
            existing_nominee_selected: _existing_nominee_selected,
            existing_nominee_id: _existing_nominee_id
        });

    }

    changeNomineeHandler = async (event) => {

        if (event.target.files[0] && (event.target.files[0].type === 'image/png' ||
            event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg')) {

            if (event.target.files[0].name.toUpperCase() === `NOMINEE_OPTING_OUT_DECLARATION_` + this.state.client_code + `.PNG` ||
                event.target.files[0].name.toUpperCase() === `NOMINEE_OPTING_OUT_DECLARATION_` + this.state.client_code + `.JPEG` ||
                event.target.files[0].name.toUpperCase() === `NOMINEE_OPTING_OUT_DECLARATION_` + this.state.client_code + `.JPG`) {

                const file = event.target.files[0];
                const buffer = await file.arrayBuffer();

                let byteArray = new Int8Array(buffer);
                this.setState({
                    nonomineeformFile: file,
                    byte_nonomineeformFile: byteArray,
                    src_nonomineeformFile: URL.createObjectURL(file)
                });

            }
            else {
                this.setState({
                    show_popup: true,
                    pop_messagetext: `Upload file name should be ` +
                        `NOMINEE_OPTING_OUT_DECLARATION_` + this.state.client_code + `.` +
                        event.target.files[0].type.split('/')[1] + ` only`
                });
            }
        }
        else {
            this.setState({
                show_popup: true,
                pop_messagetext: 'Upload file format should be of PNG, JPEG, JPG only'
            });
        }

    }

    async downloadNomineeForm(src_nominee_form, download_file_name) {
        if (src_nominee_form && src_nominee_form.length > 255) {

            const byteCharacters = atob(src_nominee_form);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            // const { data } = await getTicketsPdf()
            const blob = new Blob([byteArray], { type: "application/pdf" });
            saveAs(blob, download_file_name + ".pdf");

        }
    }

    onUploadNomineeDeclaration = (request_from) => {

        this.setState({ isloading: true });
        let formData = new FormData();

        let _nonomineeformFile = this.state.nonomineeformFile;

        if (_nonomineeformFile) {

            formData.append("file", _nonomineeformFile);
            formData.append("id_type", "no-nominee");
            formData.append("imageName", _nonomineeformFile.name);
            formData.append("imageType", _nonomineeformFile.type);
            // formData.append("pFileBytes", this.state.byte__nonomineeformFile);
            formData.append('nominee_information', JSON.stringify({
                id: 'none',
                name: '',
                relationship: '',
                poi: '',
                attachment_id: '',
                attachment_list: [],
                id_information: {
                    name: '', fathers_name: '', id_no: '', dob: '', id_type: '',
                    part: '', 'encoded_signature': '', encoded_image: '', nominee_is_minor: false
                }
            }));

            this.props
                .uploadNominee(formData)
                .then((response) => {

                    this.setState({ isloading: false });
                    if (this.props.order_result["code"] !== "000")
                        alert(this.props.order_result["messageText"]);
                    else {
                        if (this.props.order_result["state_parameters"]) {
                            this.setState(this.props.order_result["state_parameters"]);
                        }
                    }
                })
                .catch((err) => {
                    console.log("line 340 :");
                    console.log(err);
                    alert(err);
                });
        }
    };

    createDefaultMarkup(text) {
        this.setState({ isloading: true })
        return {
            __html: this.state.nofitication_html,
        }
        this.setState({ isloading: false })
    };

    closePopup = () => {

        this.setState({
            show_popup: false,
            pop_messagetext: ''
        })

    }

    sendOTP = () => {

        this.setState({ isloading: true });

        this.props
            .twoFactorAuthentication({
                client_info: {
                    two_factor_authentication: this.state.two_factor_authentication,
                    request_action: 'sip order registration',
                    is_otp_email_required: false
                },
            })
            .then((response) => {

                if (this.props.order_result["code"] !== "000") {
                    this.setState({ isloading: false });
                    alert(this.props.order_result["messageText"]);
                }
                else {

                    let _result = this.props.order_result; //["result"];
                    console.log(_result);

                    if (_result && _result['twofactor_id']) {

                        this.setState({
                            isloading: false,
                            is_otp_enabled: true,
                            two_factor_authentication: {
                                mobile_otp: '',
                                email_otp: '',
                                twofactor_token_id: _result['twofactor_id']
                            }
                        });

                    }

                }
            })
            .catch((err) => {
                alert(err);
            });

    }

    handle2FactorChange = (input, attribute) => e => {

        if (e)
            this.setState({
                [attribute]: { ...this.state[attribute], [input]: e }
            });
        else
            this.setState({ [input]: e });

    }

    render() {

        // if(this.props.loading)
        //   return <Spinner />;

        // if(this.props.order_result && this.props.order_result['esignkyc_stage_verified'])
        //    return <Redirect to={this.props.aadhaar_result['navigateScreen']} />; 

        const renderButton = buttonProps => {
            return (
                <div >
                    {/* <div className="col-6"> */}
                    <button style={{ width: '200px' }} onClick={this.sendOTP} {...buttonProps}
                        class={buttonProps.remainingTime !== 0 ? `btn btn-outline-secondary` : `btn btn-outline-success`}>
                        {`Resend OTP`}
                    </button>
                    {/* </div>
                <div className="col-6"> */}
                    {`   `}
                    {buttonProps.remainingTime !== 0
                        ? <span style={{ fontSize: 14 }}>Didn't receive the otp ?
                            You can resend otp in
                            <span style={{ fontWeight: 'bold', color: 'green' }}>{' '}{buttonProps.remainingTime}</span> secs.
                        </span>
                        : ``}
                    {/* </div> */}
                </div>
            );
        };
        const renderTime = () => React.Fragment;

        return (
            <>
                <Header1 />
                <Sidebar />

                {this.state.isloading && <div className="loading-overlay"></div>}
                {this.state.show_popup && (
                    <Modal show={this.state.show_popup} onHide={() => this.closePopup()}>
                        <Modal.Header>

                        </Modal.Header>
                        <Modal.Body>

                            <p>{this.state["pop_messagetext"]}</p>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => this.closePopup()}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
                {this.state.isloading &&
                    <Spinner animation="border" variant="dark"
                        style={{ position: "fixed", top: "50%", left: "50%" }} />
                }

                {!this.props.location.state &&
                    <div className="container" style={{ marginTop: 120 }}>
                        <div className="row">
                            <div className="col-lg-10" style={{ margin: 40 }}>
                                <Card sx={{ backgroundColor: '#edf3f0', p: 2, borderRadius: 2 }}>
                                    <CardContent  >
                                        <Stack direction="row">
                                            <Typography flex={1} whiteSpace="pre-line" >
                                                Sorry the information you are looking for could not be found
                                                <p></p>
                                                <Link className="page-back text-muted" to={'/sipList'}><span><i
                                                    className="fa fa-angle-left"></i></span> Go to Order List</Link>
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    </div>
                }
                {!this.props.isloading && this.props.location.state &&
                    <div className="container" style={{ marginTop: 120 }}>

                        <div class="row">
                            <h5 style={{ marginLeft: 55 }} >New Order - One Time Investment</h5>
                        </div>

                        <div className="card" style={{ marginLeft: 50 }}>

                            <div className="card-body" >
                                <div class="row" >
                                    <div class="col-9">
                                        <h5 >
                                            {this.state.selected_order['param_scheme_name']}
                                        </h5>
                                    </div>
                                    <div class="col-3">
                                        {`Scheme Code  : `}<span style={{ fontSize: 13 }}>
                                            {this.state.selected_order['param_scheme_code']}
                                        </span>
                                    </div>
                                </div>
                                <div class="row" >

                                    <div class="col-2">
                                        <p style={{ fontSize: 13 }}>
                                            Redeem Allowed : {this.state.selected_order['param_redemption_allowed'] === 'Y' ? 'YES' : 'NO'}
                                        </p>

                                    </div>
                                    <div class="col-2">
                                        <p style={{ fontSize: 13 }}>
                                            Exit Load : {this.state.selected_order['param_exit_load_flag'] === 'Y' ? 'YES' : 'NO'}
                                        </p>
                                    </div>
                                    <div class="col-2">
                                        <p style={{ fontSize: 13 }}>
                                            SWP Available : {this.state.selected_order['param_swp_flag'] === 'Y' ? 'YES' : 'NO'}
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <h6 >
                                        Primary Bank Account
                                    </h6>
                                    <table style={{ marginLeft: 15 }}>
                                        {(this.state.list_bank_account || []).map((item, index) => (

                                            <div class="row" key={index}>
                                                <div class="col-2">
                                                    <span style={{ fontSize: 13 }}>
                                                        {item["beneficiary_account_no"]} ({item["beneficiary_account_type"]})
                                                    </span>
                                                </div>
                                                <div class="col-3">
                                                    <p style={{ fontSize: 13 }}>
                                                        {item['beneficiary_account_holder_name'] || ''}
                                                    </p>

                                                </div>
                                                <div class="col-2">
                                                    <p style={{ fontSize: 13 }}>
                                                        IFSC : {item["beneficiary_ifsc"]}
                                                    </p>
                                                </div>
                                                <div class="col-2">
                                                    <p style={{ fontSize: 13 }}>
                                                        {item["beneficiary_bank_branch_name"]}
                                                    </p>
                                                </div>
                                                <div class="col-2">
                                                    <label>
                                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                            <Form.Check type="checkbox"
                                                                label="Primary Account"
                                                                // onChange={e => { this.handleNomineeCheck(e, item) }}
                                                                defaultChecked={item["is_primary"]}
                                                                disabled={true}
                                                            />
                                                        </Form.Group>
                                                    </label>
                                                </div>
                                            </div>

                                        ))}

                                    </table>
                                </div>

                            </div>

                        </div>

                        <div className="card" style={{ marginLeft: 50 }}>
                            <div className="card-body" >
                                <p>
                                    Do you want to add nominee ?
                                </p>
                                <div class="row">

                                    <div class="col-4">
                                        <Form.Check
                                            type="radio"
                                            label="Yes"
                                            name="nominee_option"
                                            id="existing"
                                            value="existing"
                                            checked={
                                                this.state.nominee_option === "existing"
                                            }
                                            onChange={(e) =>
                                                this.setState({ ['nominee_option']: 'existing' })
                                            }
                                        />
                                    </div>
                                    <div class="col-3">
                                        <Form.Check
                                            type="radio"
                                            label="No"
                                            name="nominee_option"
                                            id="none"
                                            value="none"
                                            checked={
                                                this.state.nominee_option ===
                                                "none"
                                            }
                                            onChange={(e) =>
                                                this.setState({ ['nominee_option']: 'none' })
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label></label>
                                    </div>
                                </div>
                                {
                                    this.state.nominee_option === 'none' &&
                                    <div class="row">
                                        <h6>
                                            As per SEBI guidelines as an investor you are required to authorize by
                                            signing the declaration form. You are required to do this only for first time in our app.
                                            Your signed declaration form will be used automatically for subsequent fresh orders and corresponding amc details,
                                            folio information will be filled accordingly and will be sent to the exchange.
                                        </h6>

                                        <div class="col-6">
                                            <div className="text-center">
                                                {
                                                    this.state
                                                        .no_nominee_form && this.state
                                                            .no_nominee_form
                                                            .length > 255 && (
                                                        <PDFReader
                                                            data={atob(
                                                                this.state
                                                                    .no_nominee_form
                                                            )}
                                                            width="800"
                                                        />
                                                    )
                                                }

                                            </div>
                                        </div>
                                        {
                                            !this.state.is_no_nominee_registered &&
                                            <div class="col-6">

                                                <div class="row">

                                                    <p
                                                        style={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        <b>Step 1</b>. <Button
                                                            variant="success"
                                                            onClick={() => {
                                                                this.downloadNomineeForm(
                                                                    this.state.no_nominee_form,
                                                                    `NOMINEE_OPTING_OUT_DECLARATION_`
                                                                    + this.state.client_code
                                                                );
                                                            }
                                                            }
                                                        >
                                                            Download
                                                        </Button>

                                                    </p>
                                                    <p
                                                        style={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        <b>Step 2</b>. Put your signature at Unit Holder (1) Signature in the form

                                                    </p>
                                                    <p
                                                        style={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        <b>Step 3</b>. Scan it (png/jpeg format)

                                                    </p>
                                                    <p
                                                        style={{
                                                            textAlign: "left",
                                                        }}>
                                                        Upload without changing the file name to authorize opting out nominee option for one time.
                                                        For eg. file name should be strictly as below
                                                        <span style={{ fontWeight: 'bold', color: 'darkgreen' }}>
                                                            {
                                                                ` NOMINEE_OPTING_OUT_DECLARATION_`
                                                                + this.state.client_code + `.png`}</span>
                                                        {` `}
                                                        or
                                                        <span style={{ fontWeight: 'bold', color: 'darkgreen' }}>
                                                            {
                                                                ` NOMINEE_OPTING_OUT_DECLARATION_`
                                                                + this.state.client_code + ".jpeg"}{` `}
                                                        </span>

                                                    </p>
                                                    <Form.Control
                                                        type="file"
                                                        onChange={
                                                            this.changeNomineeHandler
                                                        }
                                                    />

                                                </div>

                                                <div class="row">
                                                    {this.state.src_nonomineeformFile &&
                                                        <div class="col-4">
                                                            <img
                                                                width="150"
                                                                src={
                                                                    this.state[
                                                                    "src_nonomineeformFile"
                                                                    ]
                                                                }
                                                            />
                                                        </div>
                                                    }
                                                    <div class="col-6">
                                                        <br />
                                                        <Button
                                                            className="btn btn-success btn-sm"
                                                            variant="success"
                                                            onClick={() => {
                                                                this.onUploadNomineeDeclaration('main');
                                                            }
                                                            }
                                                            disabled={this.state.isloading ||
                                                                !this.state.src_nonomineeformFile}
                                                        >
                                                            Upload Declaration
                                                        </Button>
                                                    </div>

                                                </div>

                                            </div>
                                        }


                                    </div>
                                }
                                {
                                    this.state.nominee_option === 'existing' &&

                                    <div class="row">
                                        <table style={{ marginLeft: 15 }}>
                                            <thead>
                                                <tr>
                                                    <th colSpan="1" style={{ fontSize: 12 }}>#</th>
                                                    <th colSpan="4" style={{ fontSize: 12 }}>Name</th>
                                                    <th colSpan="2" style={{ fontSize: 12 }}>Relationship</th>
                                                    <th colSpan="2" style={{ fontSize: 12 }}>Proof Of Identity</th>
                                                    <th colSpan="2" style={{ fontSize: 12 }}>ID No</th>
                                                    <th colSpan="2" style={{ fontSize: 12 }}>Select</th>
                                                </tr>
                                            </thead>
                                            {(this.state.list_nominee || []).map((item, index) => (

                                                <tbody key={index}>
                                                    <tr >
                                                        <td colSpan={1}>
                                                            <p style={{ fontSize: 13 }}>
                                                                {index + 1}
                                                            </p>
                                                        </td>
                                                        <td colSpan={4}>
                                                            <p style={{ fontSize: 13 }}>
                                                                {item["name"]}
                                                            </p>
                                                        </td>
                                                        <td colSpan={2}>
                                                            <p style={{ fontSize: 13 }}>
                                                                {item['relationship'] || ''}
                                                            </p>
                                                        </td>
                                                        <td colSpan={2}>

                                                            <p style={{ fontSize: 13 }}>
                                                                {item["poi"]}
                                                            </p>

                                                        </td>
                                                        <td colSpan={2}>
                                                            <p style={{ fontSize: 13 }}>
                                                                {item["id_information"]['id_no']}
                                                            </p>
                                                        </td>


                                                        <td colSpan={2}>
                                                            <label>
                                                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                                    <Form.Check type="checkbox"
                                                                        // label="Choose"
                                                                        onChange={e => { this.handleNomineeCheck(e, item) }}
                                                                        defaultChecked={item['is_selected']}
                                                                    />
                                                                </Form.Group>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                    {item['is_selected'] && item['nominee_form'].length > 77 &&
                                                        <tr>
                                                            <td colSpan={10}>
                                                                <PDFReader
                                                                    data={atob(
                                                                        item['nominee_form']
                                                                    )}
                                                                    width="800"
                                                                />
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>

                                            ))}

                                        </table>
                                    </div>
                                }

                            </div>
                        </div>

                        {this.state.general_order['order_amount'] &&
                            <div className="card" style={{ marginLeft: 50 }}>
                                <div className="card-body" >

                                    <div class="row" >
                                        <div class="col-3">
                                            <h5 sstyle={{ fontFamily: 'sans-serif' }}>
                                                Purchase Amount: &#8377; {this.state.general_order['order_amount']}
                                            </h5>
                                            CUT-OFF TIME : {this.state.selected_order['param_purchase_cutoff_time']}

                                        </div>
                                        {this.state.list_bank_account.length > 0 &&
                                            (this.state.selected_order['request_mode'] || '') !== 'paynow' &&
                                            <div class="col-7">
                                                {!this.state.is_otp_enabled ?
                                                    <Button className="btn btn-success btn-block"
                                                        variant="success"
                                                        onClick={() => {
                                                            this.sendOTP()
                                                        }}
                                                        style={{width:'200px'}}
                                                        disabled={this.state.isloading ||
                                                            (this.state.nominee_option === 'existing' && !this.state.existing_nominee_selected) ||
                                                            (this.state.nominee_option === 'none' && !this.state.is_no_nominee_registered)}
                                                    >
                                                        Create Order
                                                    </Button>
                                                    :
                                                    <div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Enter OTP received on your registered mobile</Form.Label>
                                                                    <OTPInput
                                                                        value={this.state.two_factor_authentication['mobile_otp']}
                                                                        onChange={this.handle2FactorChange('mobile_otp', 'two_factor_authentication')}
                                                                        autoFocus
                                                                        OTPLength={6}
                                                                        otpType="number"
                                                                        secure
                                                                        isInputNum={true}
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                            <div class="col">
                                                                <br />
                                                                <button
                                                                    className="btn btn-success btn-sm"
                                                                    onClick={() => {
                                                                        this.onCreateOrder();
                                                                    }}
                                                                    disabled={this.state.isloading ||
                                                                        (this.state.nominee_option === 'existing' && !this.state.existing_nominee_selected) ||
                                                                        (this.state.nominee_option === 'none' && !this.state.is_no_nominee_registered)}
                                                                >
                                                                    Confirm Order
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <ResendOTP maxTime={120} renderButton={renderButton} style={{ color: 'black', marginTop: 20 }} renderTime={renderTime}
                                                            onResendClick={(e) => { this.sendOTP() }}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        }

                                    </div>

                                </div>
                            </div>
                        }

                        {(this.state.selected_order['request_mode'] || '') === 'paynow' &&
                            <div className="card">
                                <div>
                                    <form action="#">
                                        <div class="row">
                                            <div dangerouslySetInnerHTML={this.createDefaultMarkup()} />
                                        </div>
                                        <div class="row">
                                            <div class="col-3">

                                                <Button className="btn btn-success btn-block"
                                                    variant="success"
                                                    onClick={() => {
                                                        this.gotoPaymentPage()
                                                    }}
                                                >
                                                    Pay Rs. {this.state.selected_order['mf_order_pay_amount']}
                                                </Button>
                                            </div>

                                        </div>


                                    </form>
                                </div>
                            </div>
                        }

                    </div>
                }
            </>
        )

    }

}

/*
const mapStateToProps = state => ({
    loading: state.order.loading,
    order_result: state.order.order_result
});
*/

const mapStateToProps = ({ order }) => {

    const { loading, order_result } = order;
    return {
        loading,
        order_result,

    }

};

export default connect(mapStateToProps, { 
    loadNomineeList, createOrder, modifyOrder, uploadNominee, twoFactorAuthentication
 })
(OrderCheckout);