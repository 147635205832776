import React, { } from 'react';
import { Link } from 'react-router-dom';



class Sidebar extends React.Component {
    constructor() {
        super();
        this.state = {
            activeFilter: []
        }
    }


    onFilterChange(filter) {
        const { filterList, activeFilter } = this.state;
        if (filter === "ALL") {
            if (activeFilter.length === filterList.length) {
                this.setState({ activeFilter: [] });
            } else {
                this.setState({ activeFilter: filterList.map(filter => filter.value) });
            }
        } else {
            if (activeFilter.includes(filter)) {
                const filterIndex = activeFilter.indexOf(filter);
                const newFilter = [...activeFilter];
                newFilter.splice(filterIndex, 1);
                this.setState({ activeFilter: newFilter });
            } else {
                this.setState({ activeFilter: [...activeFilter, filter] });
            }
        }
    }


    render() {
        return (
            <>
                <div className="sidebar">
                    <Link className="brand-logo" to={'#'} style={{ marginTop: 30 }}>


                    </Link>
                    <div className="menu">
                        <ul>
                            <li>
                                <Link to={"/portfolio"} title="Portfolio">
                                    <span><i className="mdi mdi-briefcase"></i></span>
                                </Link>
                            </li>
                            <li>
                                <Link to={"/schemeList"} title="Home">
                                    <span><i className="fa fa-bar-chart"></i></span>
                                </Link>
                            </li>
                            <li>
                                <Link to={"/schemeWatchList"} title="WatchList">
                                    <span><i className="fa fa-newspaper-o"></i></span>
                                </Link>
                            </li>
                            <li><Link to={"/bankaccountList"} title="Bank & Auto Pay">
                                <span><i className="mdi mdi-bank"></i></span>

                            </Link>
                            </li>
                            {/* <li><Link to={"./buy-sell"}>
                            <span><i class="mdi mdi-receipt"></i></span>
                            <span class="nav-text">Import Funds</span>
                        </Link>
                        </li> */}
                            {/* <li><Link to={"./buy-sell"}>
                            <span><i class="mdi mdi-repeat"></i></span>
                            <span class="nav-text">Watchlist</span>
                        </Link>
                        </li> */}
                            <li>
                                <Link to={"/orderList"} title="Single Orders">
                                    <span><i className="fa fa-line-chart" ></i></span>
                                </Link>
                            </li>
                            <li><Link to={"/sipList"} title="SIP">
                                <span><i className="mdi mdi-calendar-today"></i></span>
                            </Link>
                            </li>
                            <li>
                                <Link to={{ pathname: "https://bullbox.zohodesk.in/portal/en/newticket" }} target="_blank" title="Help and Support">
                                    <span><i className="mdi mdi-phone"></i></span>

                                </Link>


                            </li>
                            {/* <li><Link to={"./settings"}>
                            <span><i class="mdi mdi-settings"></i></span>
                            <span class="nav-text">Setting</span>
                        </Link>
                        </li> */}
                        </ul>
                    </div>
                    {/* <div class="sidebar-footer">
                    <div class="social">
                        <Link to={"#"}><i class="fa fa-youtube-play"></i></Link>
                        <Link to={"#"}><i class="fa fa-instagram"></i></Link>
                        <Link to={"#"}><i class="fa fa-twitter"></i></Link>
                        <Link to={"#"}><i class="fa fa-facebook"></i></Link>
                    </div>
                    <div class="copy_right">
                        © 2021 Mango Leef Tech Labs Pvt Ltd
                </div>
                </div> */}
                </div>
            </>
        )
    }
}

export default Sidebar;