import React, { } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';




export default ({ handleChange, handleSubmit, DocumentsUpload }) => {
    return (
        <>

            <div className="authincation section-padding">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-xl-12 col-md-12">
                            <div className="mini-logo text-center my-5">
                                <Link to={'./'}><img src={require('./../../../images/logo.png')} alt="" /></Link>
                            </div>
                            <div className="auth-form card">
                                <div className="card-body">
                                    <Link className="page-back text-muted" to={'./otp-1'}><span><i
                                        className="fa fa-angle-left"></i></span> Back</Link>
                                    <h3 className="text-center">Please Provide Your Details</h3>
                                    <p className="text-center mb-5">Investor Information</p>
                                    <p className="text-center mb-5">Required by law for all investors</p>

                                    <Form>
                                        <Form.Group controlId="formFile" className="mb-3">
                                            <Form.Label>Please Upload Photo or Selfie</Form.Label>
                                            <Form.Control type="file" />
                                        </Form.Group>
                                        <Form.Group controlId="formFile" className="mb-3">
                                            <Form.Label>Please Upload address proof</Form.Label>
                                            <Form.Control type="file" />
                                        </Form.Group>
                                        <Form.Group controlId="formFile" className="mb-3">
                                            <Form.Label>Pan and live selfie video</Form.Label>
                                            <Form.Control type="file" />
                                        </Form.Group>
                                        <Form.Group controlId="formFile" className="mb-3">
                                            <Form.Label>Photo Signature</Form.Label>
                                            <Form.Control type="file" />
                                        </Form.Group>

                                        <Form.Label>Your confirming that your Information is true by clicking up the next button</Form.Label>
                                        <div className="text-center mb-4">
                                            <Link to={'./'} type="submit" className="btn btn-success pl-5 pr-5">Continue</Link>
                                        </div>
                                    </Form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}