import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Form, Row, Col, Button, Label, Spinner, Container } from "react-bootstrap";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";

const IncomeDetails = ({ prevStep, nextStep, handleChange, values, handleSelectChange, handleRadioChange, errors, loading }) => {
  console.log(errors)
  const Continue = e => {
    e.preventDefault();
    nextStep();
  }

  const Previous = e => {
    e.preventDefault();
    prevStep();
  }
  return (
    <>
      <Header1 />
      <Sidebar />
      <div id="navbar-general-container">
          <div id="navbar" >
            <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
              <Container >
                <Row >
                  <Col>
                    <div className="card-header">
                      <h5 >Your Income Information</h5>
                    </div>
                  </Col>
                </Row>
              </Container>
              <hr />
            </div>
          </div>
        </div>
        <div className="content-body" style={{ marginTop: 25 }}>
          <div className="container">
            <div class="row">
              <div class="col-xl-12">
                <div className="auth-form card">                  
                    
                    <Form>

                      <Form.Group as={Row} className="mb-3" controlId="formBasicsourcewealth">

                        <Col sm={4}>

                          <Form.Label className="text-dark">Source Of Wealth*</Form.Label>
                          <Select options={values['master_list']['source_wealth']}
                            onChange={handleSelectChange('income', 'source_of_wealth')}
                            value={values['master_list']['source_wealth'].find(obj => obj.value === values.source_of_wealth)}
                            isDisabled={values['is_bse_nominee_verified']}
                          /> <Form.Text className="text-danger">
                            {errors && errors["source_of_wealth"]}
                          </Form.Text>

                        </Col>
                        <Col sm={4}>
                          <Form.Label className="text-dark">Occupation*</Form.Label>
                          <Select options={values['master_list']['occupation_list']} onChange={handleSelectChange('income', 'occupation')}
                            value={values['master_list']['occupation_list'].find(obj => obj.value === values.occupation)} 
                            isDisabled={values['is_bse_nominee_verified']}
                          />

                          <Form.Text className="text-danger">
                            {errors && errors["occupation"]}
                          </Form.Text>
                        </Col>
                        <Col sm={4}>
                          <Form.Label className="text-dark">Annual Income Range*</Form.Label>
                          <Select options={values['master_list']['income_range']} onChange={handleSelectChange('income', 'annual_income')}
                            value={values['master_list']['income_range'].find(obj => obj.value === values.annual_income)} 
                            isDisabled={values['is_bse_nominee_verified']}
                            />

                          <Form.Text className="text-danger">
                            {errors && errors["annual_income"]}
                          </Form.Text>
                        </Col>

                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">

                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicIncomedetails">
                        <Form.Label className="text-dark">Do you have income outside india and paying tax in other countries ?*</Form.Label>
                        <Form.Check
                          type="radio"
                          label="No / Not applicable"
                          name="incomeOutsideIndia"
                          value="false"
                          checked={values.is_income_outside_india === false}
                          onChange={handleRadioChange('is_income_outside_india')}
                          defaultValue={values.is_income_outside_india}
                          disabled={values['is_bse_nominee_verified']}
                        />
                        <Form.Check
                          type="radio"
                          label="Yes"
                          name="incomeOutsideIndia"
                          value="true"
                          checked={values.is_income_outside_india === true}
                          onChange={handleRadioChange('is_income_outside_india')}
                          defaultValue={values.is_income_outside_india}
                          disabled={values['is_bse_nominee_verified']}
                        />
                      </Form.Group>
                      <Form.Text className="text-danger">
                        {errors && errors["incomeOutsideIndia"]}
                      </Form.Text>
                      <Form.Group className="mb-3" controlId="formBasicpoliticallyexposed">
                        <Form.Label className="text-dark">Are you related to politically exposed person in india ?*</Form.Label>
                        <Form.Check
                          type="radio"
                          label="No / Not applicable"
                          name="politicallyExposedIndia"
                          id="false"
                          value="false"
                          checked={values.is_political_india === false}
                          onChange={handleRadioChange('is_political_india')}
                          defaultValue={values.is_political_india}
                          disabled={values['is_bse_nominee_verified']}
                        />
                        <Form.Check
                          type="radio"
                          label="Yes"
                          name="politicallyExposedIndia"
                          id="true"
                          value="true"
                          checked={values.is_political_india === true}
                          onChange={handleRadioChange('is_political_india')}
                          disabled={values['is_bse_nominee_verified']}
                        />
                      </Form.Group>
                      <Form.Text className="text-danger">
                        {errors && errors["is_political_india"]}
                      </Form.Text>
                      <Form.Group className="mb-3" controlId="formBasicpoliticallyexposed">
                        <Form.Label className="text-dark">Are you related to politically exposed person in a foreign countries?*</Form.Label>
                        <Form.Check
                          type="radio"
                          label="No / Not applicable"
                          name="politicallyExposedForeign"
                          id="No"
                          value="false"
                          checked={values.is_political_foreign === false}
                          onChange={handleRadioChange('is_political_foreign')}
                          disabled={values['is_bse_nominee_verified']}                          
                        />
                        <Form.Check
                          type="radio"
                          label="Yes"
                          name="politicallyExposedForeign"
                          id="Yes"
                          value="true"
                          checked={values.is_political_foreign === true}
                          onChange={handleRadioChange('is_political_foreign')}
                          defaultValue={values.is_political_foreign}
                          disabled={values['is_bse_nominee_verified']}
                        />
                      </Form.Group>
                      <Form.Text className="text-danger">
                        {errors && errors["is_political_foreign"]}
                      </Form.Text>
                      

                    </Form>
                    {loading &&
                      <Spinner animation="border" variant="dark"
                        style={{ position: "fixed", top: "50%", left: "50%" }} />
                    }
                    
                </div>
                
                <div className="text-center mb-4" >
                        <Button
                          onClick={Previous}
                          type="submit"
                          variant="secondary"
                          color="primary"
                          style={{ 'width': '120px' }}
                        >
                          Previous
                        </Button>
                        <span style={{ 'margin-right': '70px' }}></span>
                        <Button
                          onClick={Continue}
                          type="submit"
                          variant="success"
                          color="primary"
                          style={{ 'width': '120px' }}
                        >
                          Next
                        </Button>
                      </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default IncomeDetails;