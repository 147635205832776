import axios from 'axios';
import store from '../../store';
import { LOGOUT } from '../actions/types';
import {AppConstants } from '../constants/constants';
// import { History } from 'react-router';

const api = axios.create({
  baseURL: AppConstants.API_BASE_URL,  //.STAGING_BASE_URL OR .PRODUCTION_BASE_URL
  headers: {
    'content-type': 'application/json',
    // 'content-type': 'application/x-www-form-urlencoded',
    // 'Access-Control-Allow-Origin': '*'
  }
});

/**
 intercept any error responses from the api
 and check if the token is no longer valid.
 ie. Token has expired or user is no longer
 authenticated.
 logout the user if the token has expired
**/

api.interceptors.request.use(function (config) {

  //api.defaults.headers.common['bb-access-token'] = localStorage.getItem('token');
  console.log(localStorage.getItem('token'));
  config.headers['bb-access-token'] = localStorage.getItem('token');
  // config.headers['bb-access-token'] = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MWY3ODE3NzY1YjdiYjA5YWM3Y2YxZGIiLCJsb2dpbl9pZCI6IjYxZjc4MTc3NjViN2JiMDlhYzdjZjFkYiIsImxvZ2luX21vYmlsZV9udW1iZXIiOiI5Njg4MzYzNTU2IiwibG9naW5fZW1haWwiOiJoYXJpc2hfYUBidWxsYm94LmluIiwidmVyaWZ5X3Bhc3N3b3JkIjpmYWxzZSwidmVyaWZ5X290cCI6ZmFsc2UsImxvZ2luX3JvbGUiOiJjdXN0b21lciIsImxvZ2luX2NhdGVnb3J5IjoiY3VzdG9tZXIiLCJ0b2tlbl9leHBpcnkiOjAsInZhbGlkYXRpb25fY2hlY2siOiJub25lIiwibG9naW5fYWN0aXZhdGlvbl9pZCI6IjNmYWY1Y2Y0ZGQ5ZDk3MzQ3MDE2NDU5ZiIsImlhdCI6MTY0NTAxMTg5NCwiZXhwIjoxNzMxNDExODk0fQ.JbqQ-LmnYmD-YXzrpGLNxUYYb6eT_s2xibv94US6Yc8';
  return config;

}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

api.interceptors.response.use(function (response) {
  
  console.log(response);
  // console.log(this.props);

  if(response['data']['erase_token_id'] || response['data']['navigateScreen'] === 'signin' || 
     response['data']['navigateScreen'] === './signin' || 
     response['data']['token_id'] === 'g6Eg1l0aId3yzoosDaSfxeblOy2lXoUgXgFeHdnoYuUtIDIeIQ') {
       
      localStorage.removeItem('token');  
      // router.push({ // here is the redirect component OR USE window.location.href='/login'
      //     path: '/signin',  
      //     name: 'signin'  
      // });
  }
  else {

    if(response['data'] && response['data']['code'] && 
      ['003', '004'].indexOf(response['data']['code']) > -1 )
    {

      // browserHistory.push({ // here is the redirect component OR USE window.location.href='/login'
      //     path: response['data']['navigateScreen'] || '/signin',  
      //     name: 'signin'  
      // });
      window.location.href = response['data']['navigateScreen'] || '/signin';
      
    }
    else {

      if(response['data'] && response['data']['token_id'])
         localStorage.setItem('token', response['data']['token_id']);

    }

  }

  return response || { data: { code: '011', messageText: 'inter error'} };

}, function (error) {
  
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  store.dispatch({ type: LOGOUT });
  
  return Promise.reject(error);
  
});

/*
api.interceptors.response.use(
  res => res,
  err => {
    console.log(123 + err)
    if (err || (err.response && err.response.status === 401)) {
      store.dispatch({ type: LOGOUT });
    }
    return Promise.reject(err);
  }
);
*/



export default api;
